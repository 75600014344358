@each $element, $sizes in $text-styles {
    @if $element == "h1" or $element == "h2" or $element == "h3" or $element == "h4" or $element == "h5" or $element == "h6" {
        #{$element}, .#{$element} {
            line-height: $headings-line-height; //Variable is located in 01-bootstrap-overrides/_variables.scss
            letter-spacing: $letter-spacing-heading;
            word-spacing: $word-spacing-heading;
        }
    } @else {
        #{$element}, .#{$element} {
            line-height: $line-height-base; //Variable is located in 01-bootstrap-overrides/_variables.scss
            letter-spacing: $letter-spacing-body;
            word-spacing: $word-spacing-body;
        }
    }
    
    @if $enable-responsive-text-size == true {
        @each $variant, $variant-value in $text-sizes {
            //@debug "responsive-text-size: #{$element}-#{$variant}, #{$variant-value}";
            @if $variant != "base" {
                .#{$element}-#{$variant} {
                    @include r-font-size(map-get(map-get($text-styles, $element), min) * $variant-value, map-get(map-get($text-styles, $element), max) * $variant-value);
                }
            } @else {
                #{$element}, .#{$element} {
                    @include r-font-size(map-get(map-get($text-styles, $element), min) * $variant-value, map-get(map-get($text-styles, $element), max) * $variant-value);
                }
            }
        }
    }
}



// Create new `text-XX` classes where `XX` can match any key from the $font-sizes map
// Usage: `<element class="text-24"></element>
@each $size, $value in $font-sizes {
    .text-#{$size} {
        @include r-font-size(map-get(map-get($font-sizes, $size), min), map-get(map-get($font-sizes, $size), max));
    }
}



.lh-0,
.line-height-0 {
    line-height: 0;
}

.lh-sm {
    line-height: .8;
}

.lh-1,
.line-height-1 {
    line-height: 1 !important;
}
.lh-1_2 {
    line-height: 1.2 !important;
}

.large {
    font-size: 140%;
    font-weight: 700;
}

.text-size-inherit {
    font-size: inherit !important;
}

.font-family-sans-serif {
    font-family: $font-family-sans-serif;
}

.font-family-system {
    font-family: $font-family-system;
}

//missing font weights
.font-weight-thin {
    font-weight: $font-weight-thin;//100
}
.font-weight-medium {
    font-weight: $font-weight-medium;//500
}
.font-weight-semibold {
    font-weight: $font-weight-semibold;//600
}
.font-weight-black {
    font-weight: $font-weight-black;//900
}


// .text-accent,
// .font-family-accent {
//     font-family: $font-family-accent;
// }

.lead-in {
    @include r-font-size(18, 26);
    font-weight: 300;
}

.text-shadow {
    text-shadow: 0 2px 4px rgba($black,.5);
}

.text-decoration-hover-underline {
    &:hover {
        text-decoration-line: underline !important;
    }
}

.text-decoration-hover-primary {
    &:hover {
        text-decoration-color: $primary !important;
    }
}

.text-decoration-hover-2 {
    &:hover {
        text-decoration-thickness: 2px !important;
    }
}

.text-quinary{
    color: $quinary !important;
}

.text-septenary{
    color: $septenary !important;
}

