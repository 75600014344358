.shadow-up { box-shadow: $box-shadow-up !important; }

.shadow-sm-up{
    @media screen and (max-width: $mq-tablet-portrait){
        box-shadow: $box-shadow-up !important;
    }
}
.shadow-md-reg{
    @media screen and (min-width: $mq-tablet-portrait){
        box-shadow: $box-shadow !important;
    }
}

.inset-shadow {
    box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
}