
.form-control{
	&[type='radio']{
		width: 30px;
	}
}


//meant to mimic the look of the other placeholder text labels
.label-placeholder{
	font-family: $headings-font-family;
	font-weight: 500;
	color: $gray-500!important;
}


.validation-summary-errors{
	color: red;
}


//main container added inline by developer
.select2{
	&.select2-container {
		el-placeholder{
			font-family: $headings-font-family;
			font-weight: 500;
		}
		min-width: 100px;
		@include media-breakpoint-down(md) {
			width: 100% !important;
		}
		@include media-breakpoint-up(md) {
			max-width: 200px;
			width: auto !important;
		}
	}
	.select2-selection{
		min-height: 41px;
		border: 0 !important;
		el-placeholder{
			font-family: $headings-font-family;
			font-weight: 500;
		}
	}
}

//added by the code to the bottom of the page
.select2-container{
	.select2-results__options{
		el-placeholder{
			font-family: $headings-font-family;
			font-weight: 500;
		}
	}
}


//custom checkboxes from accessibility-modal
.fa-checkboxes{
    .fa-checkbox{
        position: relative;
		margin: 0 0 .25rem 0;

        input[type=checkbox]{
            display: none;
        }

		.icon-checked{
			display: none;
		}
		.icon-unchecked{
			display: block;
			color: $gray-400;
		}
		
		&.active{
			.icon-checked{
				display: block;
			}
			.icon-unchecked{
				display: none;
			}
		}

        label{
			cursor: pointer;
            display: flex;
            align-items: center;
            // &:before{
            //     font-family: "Font Awesome 5 Pro";
            //     content: "\f0c8";
            //     display: inline-block;
            //     margin-right: .5rem;
            //     font-size: 1.5rem;
            //     color: $gray-600;
            // }
        }

        input[type=checkbox]:checked + label {
            font-weight: bold;
        }

        // input[type=checkbox]:checked + label:before {
        //     content: "\f14a";
        //     color: $primary;
        //     font-size: 1.5rem;
        // }

        &:hover{
            label{
                text-decoration: underline;
                //color: red!important;
            }
        }
        
    }

    &.active-septenary{
        .fa-checkbox{
            input[type=checkbox]:checked + label:before {
                color: $septenary;
            }
        }
    }
}