.popup{
    //border: 1px solid red;
    
    position: fixed;
    display: block;
    width: auto;
    height: auto;
    opacity: 0;
    transition: opacity 500ms ease-out;

    &.active{
        opacity: 1;
    }

    h1,h2,h3,h4,h5,h6{
        line-height: 1;
    }

    $padMobile: 12px;
    &--traveler{
        background-color: $secondary;
        bottom: $padMobile;
        left: $padMobile;
        right: $padMobile;
        width: calc(100% - (#{$padMobile} * 2));
        z-index: 999999;
        line-height: 1.2;

        @include media-breakpoint-up(md) {
            width: 350px;
            bottom: 4rem;
            right: 4rem;
            left: unset;
           // z-index: 1;//keep it under the main navigation - can't then its under other stuff
        }
    }

    &-img{
        width: auto;
        min-width: 140px;
        display: none;
        img{
            object-fit: cover;
        }

        @media screen and (min-width: 350px) {
            display: block;
        }
        @include media-breakpoint-up(sm) {
            width: 33%;
        }
        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }

    &-info{
        position: relative;
        box-sizing: border-box;

        // max-width: 270px;
        // @include media-breakpoint-up(md) {
        //     max-width: unset;
        // }
    }

    &-close{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: $primary;
        font-size: 20px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        top: -2.2rem;
        right: .5rem;
        cursor: pointer;
        z-index: 99;

        @include media-breakpoint-up(md) {
            top: .5rem;
        }
        &:hover{
            background-color: $gray-300;
        }
    }

    &-colorbars{
        $cbWid: .8rem;
        $cbPercBig: 70%;
        $cbPercSmall: 15%;

        width: $cbWid;
        height: .6rem;

        &::before{
            position: relative;
            left: 0;
            bottom: 0;
            width: $cbWid;
            height: 100%;
            content: " ";
            display: block;
            background: linear-gradient(to top, $tertiary 0, $tertiary 10%, $quaternary 10%, $quaternary 20%, $senary 20%, $senary 100%);
            z-index: 2;
        }

        @include media-breakpoint-up(md) {
            width: 100%;

            &::before{
                width: 100%;
                height: 11px;
                background: linear-gradient(to right, $tertiary 0, $tertiary 10%, $quaternary 10%, $quaternary 20%, $senary 20%, $senary 100%);
                z-index: 2;
            }
        }
    }

    &-cta{
        @extend.btn;
        background-color: $septenary;
        box-sizing: border-box;
        font-size: 1.25rem;
        cursor: pointer;
        svg,
        span{
            color: white;
        }
        &:hover{
            background-color: darken($septenary, 5%);
            svg,
            span{
                color: white;
            }
        }
    }
}