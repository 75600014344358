

$ibHei: 33px;
$tgOrange: $quaternary;//orange color


.travel-green__col-left{
    vertical-align: top;
    display: block;
    margin: 10px 20px 0;
    width: auto;
    @media screen and (min-width:$mq-tablet-portrait){
        display: inline-block;
		margin: 10px 10px 0 20px;
        max-width: 350px;
        width: 43%;
	}
}
.travel-green__col-right{
    vertical-align: top;
    display: block;
    margin: 10px 20px 0;
    width: auto;
    @media screen and (min-width:$mq-tablet-portrait){
		display: inline-block;
        margin: 10px 20px 0 10px;
        width: 43%;
        float: right;
	}
}


.travel-green__sec{
    position: relative;
    margin: 60px 0;

    h3{
        position: relative;
        margin: 0 0 10px 0;
        color: $travelGreen;
        font-size: 1.0em;
    }

}


.travel-green__title {
     position: relative;
    display: block;
    margin: 0 20px 0;

     h2 {
        position: relative;
        display: inline-block;
        margin: 5px 0 0;
        color: $travelGreen;
        font-size: 1.0em;
        vertical-align: top;

        @media screen and (min-width:$mq-mobile-landscape){
		    font-size: 1.2em;
	    }
    }
}


.travel-green__leaf{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 30px;
    margin: 0;
    background-image: url('../../Images/public/tg_leaf.png');
    background-image: url('../../Images/public/tg_leaf.svg');
}




.travel-green__innovations{
    ul{
        margin: 0;
        padding: 0;

        li{
            margin: 0 0 5px 15px;
            font-size: .9em;
            line-height: 1.35em;
        }
    }
}

.travel-green__badge{
    margin: 0 auto 10px;
    display: block;
    text-align: center;

     @media screen and (min-width:$mq-tablet-portrait){
		 margin: 0 5px 10px 0;
         text-align: left;
         display: inline-block;
	}
}



.travel-green__indicator-bar{
    border: 2px solid $travelGreen;
    height: $ibHei;
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 20px 0 0;
    //@include borderRadius(6px);
    border-radius: 6px;

    .travel-green__perc-mark{
        position: absolute;
        bottom: 0;
        width: 1px;
        height: $ibHei;
        background-color: rgba(0,160,79,.4);//$travelGreen;//00a04f//0,160,79*/

        .perc {
            position: absolute;
            top: -13px;
            transform: translateX(-50%);

            font-size: 8px;
            font-weight: 100;
        }
    }

    .travel-green__score{
        height:  ($ibHei - 3);
        padding-top: 3px;
        text-align: center; 
        color: #fff;
        background: #64de79;
        background: linear-gradient(#64de79 70%, #5bc86d); 
        //@include borderRadiusAll(5px, 0px, 0px, 5px);
        border-radius: 5px;
    }

    .travel-green__score-text{
        position: absolute;
        top: -24px;
        padding: 3px 6px 1px;
        background-color: $tgOrange;
        left: 0;
        transform: translateX(-50%);
        z-index: 1;
        //@include borderRadius(3px);
        border-radius: 3px;

        color: white;
        font-size: 10px;
        font-weight: bold;

        .pts {
            margin-left: 2px;
            font-style: italic;
            font-weight: 100;
            font-size: 9px;
        }
        .arrow-dn{
            position: absolute;
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 5px solid $tgOrange;
        }
    }


    @media screen and (min-width:$mq-tablet-portrait){
        margin: 10px 5px 0 0;
		width: 45%;
        float: right;
	}
}

.travel-green__iscertified{
    font-size: .7em;
    margin: 10px auto 0;
    font-weight: bold;
}
.travel-green__disclaimer{
    font-size: 0.6em;
    margin-top:0.5em;
    line-height: 1.4em;

    a:link, a:visited{
      color: $travelGreen;
    }
}


.travel-green-application{
    h1,
    h2,
    h3,
    h4,
    h5{
        color: $travelGreen;
    }
    // input[type="checkbox"],
    // input[type="radio"]{
    //     margin-right: .25rem;
    // }
    h1{
        font-size: 2.5rem!important;
    }
    .truncatedStory{
        padding: 0;
    }

    .invalid{
        font-weight: 700;
        color: $danger;
    }

    .submission__input {
        select,
        textarea,
        input{
            border: 1px solid $midNeutral !important;
            border-radius: 4px;

            &.invalid{
                border: 1px solid $danger !important;
            }

            &[disabled],
            &.disabled{
                opacity: .4 !important;
                cursor: not-allowed;
            }
        }
    }
}


.subscription-application{
    // h1,
    // h2,
    // h3,
    // h4,
    // h5{
    //     color: $travelGreen;
    // }
    // input[type="checkbox"],
    // input[type="radio"]{
    //     margin-right: .25rem;
    // }
    h1{
        font-size: 2.5rem!important;
    }
    .truncatedStory{
        padding: 0;
    }

    .invalid{
        font-weight: 700;
        color: $danger;
    }

    .submission__input {
        select,
        textarea,
        input{
            border: 1px solid $midNeutral !important;
            border-radius: 4px;

            &.invalid{
                border: 1px solid $danger !important;
            }

            &[disabled],
            &.disabled{
                opacity: .4 !important;
                cursor: not-allowed;
            }
        }
    }
}