
$ghostedSpacer: 15px;

.after-colorbar{
	margin-bottom: $ghostedSpacer;
	position: relative;
	&::after{
		content: '';
		width: 100%;
		height: $ghostedSpacer;
		display: block;
		position: absolute;
		bottom: $ghostedSpacer * -1;
		left: 0;
	}

	&--yellow{
		@extend .after-colorbar;
		&::after{
			background-color: $senary;
		}

		.card &{
			margin-bottom: $ghostedSpacer * .4;
			&::after{
				height: $ghostedSpacer * .4;
				bottom: $ghostedSpacer * .4 * -1;
			}
		}
	}

	&--ghosted{
		@extend .after-colorbar;
		&::after{
			background-color: inherit;
			opacity: .5;
		}
	}
}


.before-colorbar{
	margin-top: $ghostedSpacer;
	position: relative;
	&::before{
		content: '';
		width: 100%;
		height: $ghostedSpacer;
		display: block;
		position: absolute;
		top: $ghostedSpacer * -1;
		left: 0;
	}

	&--yellow{
		&::before{
			background-color: $theme-dark-accent;
		}

		.card &{
			margin-top: $ghostedSpacer * .4;
			&::before{
				height: $ghostedSpacer * .4;
				top: $ghostedSpacer * .4 * -1;
			}
		}
	}

	&--left{
		margin-top: unset;
		&::before{
			top:0;

			@include media-breakpoint-up(md) {
				width: $ghostedSpacer;
				height: 100%;
				top: 0;
			}
		}
	}

	&--ghosted{
		@extend .before-colorbar;
		&::before{
			background-color: inherit;
			opacity: .5;
		}
	}

	&--accent{
		@extend .before-colorbar;
		&::before{
			background-color: $theme-dark-colorbar-before-accent;
		}
	}
}
