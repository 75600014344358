.globalFooter{
  background-color: $secondary;
  color      :  #d9d9d9;
  font-size  :  .8em;
  position   :  relative;
//   a, a:link, a:visited{
//   }
  @media print {
	display: none;
  }

  .sticky-padding &{
	  padding-bottom: 100px;
  }
}

.globalFooter--action-container {
	position: relative;
	padding-top: 1rem;
	@media screen and (min-width:$mq-desktop-md) {
		padding-top: 0;
	}
}

.globalFooter--dark {
	background-color: $primary;
	color: #fff;
}

.globalFooter__inner {
	text-align: center;
	max-width: 1175px;
	margin: 0 auto;
	padding-top: 2.5rem;
	position: relative;

	@media screen and (min-width:$mq-desktop-md) {
		padding-top: 4rem;
	}
}

.globalFooter__info{
	position: relative;
	display: block;
	@media screen and (min-width:$mq-desktop-md) {
		display: flex;
		justify-content: space-between;
	}

	&--simplified{
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin: 0;

		.globalFooter__section {
			margin: 0 0 1rem 0;
		}

		.globalFooter__section--actions {
			padding-top: 1rem;
		}

		@media screen and (min-width:$mq-desktop-md) {
			flex-direction: row;
			margin: 1rem 0;
		}
	}
}


// Preventing Safari from putting an underline on the footer logo 
.globalFooter {
  .brandingSection {
    a:link {text-decoration: none;}
    a:visited {text-decoration: none;}
    a:hover {text-decoration: none;}
    a:active {text-decoration: none;}
  }
}

.globalFooter__social {
	box-sizing: border-box;
	display: block;
	text-align: center;
	padding: 0 1rem;
	font-size: 1.3em;

	@media screen and (min-width:$mq-desktop-md) {
		display: flex;
		align-items: center;
		padding: 0 2rem;
	}
	
}
.globalFooter__discover{
	text-align: center;
	line-height: .9;
	.line1{
		font-size: 2rem;
	}
	.line2{
		font-size: 3rem;
	}
	@media screen and (min-width:$mq-desktop-md) {
		text-align: left;
	}
}
.globalFooter__hashtag {
	font-weight: bold;
	font-size: 1.5rem;
}
.globalFooter__section {
	//border: 1px solid yellow;
	box-sizing: border-box;
	display: block;
	padding: 0 1rem 0;
	margin: 0 0 2.25rem 0;
	@media screen and (min-width:$mq-desktop-md) {
		width: 187px;
		padding: 1rem 1rem 0;
		display: block;
		text-align: left;
		margin: 0 1rem 0;
	}

	&.newsletter{
		display: inline-flex;
		font-size: 1.5rem;
		line-height: 1;
		text-transform: uppercase;
		margin: 0 1rem 2.25rem;
		img{
			max-width: 150px;
		}
		@media screen and (min-width:$mq-desktop-md) {
			width: 350px;
			margin: 0 1rem 0;
			img{
				max-width: 200px;
			}
		}
	}
}
.globalFooter__section--actions {
	//border: 1px solid pink;
	text-align: center;
	display: block;
	padding: 0;
	width: 100%;
	@media screen and (min-width:$mq-desktop-md) {
		position: relative;
		width: 500px;
		margin-top: 25px;
		padding: 0 15px;
	}
	
}
.globalFooter__section--branding {
	//border: 1px solid orange;
	box-sizing: border-box;
	text-align: center;
	padding: 1rem 1rem 0 1rem;
	display: block;
	@media screen and (min-width:$mq-desktop-md) {
		width: 200px;
		border-bottom: none;
		text-align: left;
	}
}

.globalFooter__phone {
	box-sizing: border-box;
	font-weight: bold;
	display: block;
	margin: 10px 0;
	text-decoration: none;
	&:link, &:visited {
		color: #fff;
	}
}
.globalFooter__link {
	display: block;
	margin: 10px 0 0.5rem;
	text-decoration: none;
	font-weight: bold;
	&:link, &:visited {
		color: #d9d9d9;
	}
	&:active, &:hover {
		color: #fff;
	}
	&.-extrapad{
		margin: 10px 0 1.5rem;
	}
}
.globalFooter__legal {
	box-sizing: border-box;
	display: block;
	text-align: center;
	padding: 0 1rem 1rem;
}

.globalFooter__legal--short {
	@media screen and (min-width:$mq-desktop-md) {
		padding: 0;
	}
}

.globalFooter__action {
	box-sizing: border-box;
	display: inline-block;
	vertical-align: baseline;
	padding: 0 10px;
	margin: 10px 0;
	text-align: center;
	font-weight: bold;
	.icon {
		margin: 5px auto 2px;
	}
	@media screen and (min-width:$mq-desktop-md) {
		width: 150px;
		vertical-align: middle;
		& + & {
			border-left: 1px solid #888;
		}
	}
	&:link, &:visited {
		color: #fff;
		text-decoration: none;
	}
}

.globalFooter__logo {
	display: block !important;
	margin: 0 auto 15px;
	width: 160px;
	//height: 43px;
	@media screen and (min-width:$mq-desktop-md) {
		margin: 0 auto 15px 0;
	}
}

.globalFooter__guide {
	display: none;
	background-image: url('/Images/public/footer-guide-image-backer.png');
	width: 121px;
	height: 156px;
	padding-top: 10px;
	@media screen and (min-width:$mq-desktop-md) {
		display: block;
	}
}

.footer-social {
	margin: 0 auto;
	padding: 0;
	list-style-type: none;
	width: 260px;
	@media screen and (min-width:$mq-desktop-md) {
		width: 310px;
		//margin-bottom: 50px;
	}
}

.footer-social__link {
   height: 32px;
   width: 32px;
   overflow: hidden;
   margin: 0;
   padding: 0;
   text-decoration: none;
   display: block;
   background-size: cover;
   opacity: .8;

	&:link, &:visited {
		color: #d9d9d9;
	}
	@media screen and (min-width:$mq-desktop-md) {
		width: 52px;
		height: 52px;
	}

	&:hover{
		opacity: 1;
	}
}

.footer-social__item {
	float: left;
	margin: 0 10px;

	@media screen and (min-width:$mq-desktop-md) {
		margin: 0 5px;
	}

}

.footer-social__link--facebook {
	background-image: url($path_social + '2022/facebook.png');
	background-image: url($path_social + '2022/facebook.svg');
}
// .footer-social__link--google {
// 	background-image: url($path_social + '2022/google.png');
// }
.footer-social__link--twitter {
	//background-image: url($path_social + '2022/twitter.png');
	background-image: url($path_social + '/x-twitter-white.svg');
}
.footer-social__link--pinterest {
	background-image: url($path_social + '2022/pinterest.png');
	background-image: url($path_social + '2022/pinterest.svg');
}
.footer-social__link--youtube {
	background-image: url($path_social + '2022/youtube.png');
	background-image: url($path_social + '2022/youtube.svg');
}
.footer-social__link--instagram {
	background-image: url($path_social + '2022/instagram.png');
	background-image: url($path_social + '2022/instagram.svg');
}


.globalFooter{
	.color-bars{
        position: absolute;
        z-index: 9;
        display: flex;
		flex-direction: column;
        top: 0;
        right: 0;
		width: 13px;
        height: 100%;
    
        .color-bar{
            position: relative;
			width: 100%;
            height: 100%;
    
            &.yellow{
                height: 25%;
                background-color: $senary;
            }
            &.blue{
                height: 75%;
                background-color: $tertiary;
            }

     
        }

		@media screen and (min-width: $mq-tablet-portrait){
			width: 25px;
		}
    }
}