// VARIABLES
$campaign-secondary: #004D71;
$tertiary-light: #85D1D4;
$shadow: 0 0.25rem 0.25rem 0 rgba(0,0,0,.35);
$extra-large-screen: 1450px;
.culinary-show {
    //custom classes for culinary-show
	h2 {
		text-transform: none;
	}

    .accent-border-top {
        position: relative;
        &:before {
            content: " ";
            position: absolute;
			background: linear-gradient(to right, $senary 10%, $campaign-secondary 10%);
            display: block;
			z-index: 3; 
            height: 12px;
            left: 0;
            top: 0;
            width: 100%;
        }

        &.border-thick {
			&:before {
				height: 22px;
            }
        }
		&--tertiary {
			&:before {
				background: $tertiary;
			}
			&.border-thick {
				&:before {
					height: calcRem(24);
				}
			}
		}
		&--light {
			&:before {
				background: linear-gradient(to right, $tertiary 20%, $senary 10%);
			}
			&.border-thick {
				&:before {
					height: calcRem(24);
				}
			}
		}
    }

	.accent-border-bottom {
		&:before {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 11px;
			content: " ";
			display: block;
			background: linear-gradient(270deg, $tertiary 90%,$senary 0);
			z-index: 2;
		}
	}

	.accent-border-left {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: 100%;
			left: 0;
			top: 0;
			width: 16px;
			background: linear-gradient($tertiary 0, $tertiary 60%, $senary 60%, $senary 80%, $quaternary 80%, $quaternary 100%);
		}
	}

	.accent-border-right {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: 100%;
			right: 0;
			top: 0;
			width: 22px;
			background: $quaternary;
		}
	}

	.js-map-categories {
		display: none;
		min-height: 140px;
		text-align: center;
		@include media-breakpoint-up(lg) {
			text-align: left;
		}			
	}

	.js-map-categories.active {
		display: block;
	}
	// Culinary Map Section
	.culinary-map-pane {
		width: 100%;
		overflow: auto;
		@include media-breakpoint-up(lg) {
			width: 44%;
			min-height: 715px;
		}	
		@include media-breakpoint-up(xxl) {
			width: 40%;
		}	
	}

	.culinary-map {
		width: 100%;
		height: 330px;
		@include media-breakpoint-up(lg) {
			width: 56%;
			height: auto;
		}		
		@include media-breakpoint-up(xxl) {
			width: 60%;
		}			
	}	

	.culinary-filter-categories {
		overflow-x: auto;
		overflow-y: hidden;
	}

	.culinary-categories-gradient {
		position: relative;	
		height: auto;
		&::after {
			position: absolute;
			display: block;
			z-index: 1;
			content: '';
			right: 0;
			top: 0;
			height: 40px;
			width: 30px;
			background: linear-gradient(270deg, rgba(0, 179, 197, 0.7) 0%, rgba(0, 179, 197, 0) 100%);				
		}
		&::before {
			position: absolute;
			display: block;
			z-index: 1;
			content: "";
			top: 0;
			left: 0;
			height: 40px;
			width: 30px;
			background: linear-gradient(90deg, rgba(0, 179, 197, .7), rgba(0, 179, 197, 0));
			@media only screen and (min-width: $extra-large-screen) {
				display: none;
			}		
		}			
	}	

	.culinary-filter-categories::-webkit-scrollbar { 
	    display: none;  /* Safari and Chrome */
	}
	.pill-nav-item {
		background-color: $white;
		border-radius: 100px;
		cursor: pointer;
		white-space: nowrap;
		padding: 0.5rem 1rem;
		box-shadow: $shadow;

		a {
			text-decoration: none;
		}

		&:hover {
			background-color: darken($white, 10%);
		}

		&.active {
			cursor: default;
			pointer-events: none;			
			background-color: $senary;
			.stretched-link {
				&:after {
					cursor: default;
					pointer-events: none;
				}
			}
		}
		@include media-breakpoint-up(lg) { 
			padding: 0.5rem .75rem;
		}		
		@include media-breakpoint-up(xl) { 
			padding: 0.5rem 1rem;
		}		
	}

	.culinary-episode-cards {
		overflow-y: auto;
		max-height: 440px;	
		position: relative;

		.no-reports {
			min-width: 100%;
		}		
		.more-reports {
			background-color: $tertiary-light;
			height: 115px;
			box-shadow: $shadow;
			@include media-breakpoint-up(lg) {
				height: auto;
				min-width: 100%;
			}			
		}
		.slick-track {
			display: flex;
		}		
	}
	.infoboxText {
		width: 350px;
		height: 75px;
		box-shadow: $shadow;
		@include media-breakpoint-up(lg) {
			width: 430px;
			height: 90px;
		}
	}
	.culinary-card-gradient {
		position: relative;	
		height: auto;
		&::after {
			position: absolute;
			display: block;
			z-index: 1;
			content: '';
			right: 0;
			top: 0;
			height: 115px;
			width: 30px;
			background: linear-gradient(270deg, rgba(0, 179, 197, 0.7) 0%, rgba(0, 179, 197, 0) 100%);		
			
			@include media-breakpoint-up(lg) {
				top: unset;
				bottom: 0;
				height: 25px;
				width: 100%;
				background: linear-gradient(0deg, rgba(0, 179, 197, 0.7) 0%, rgba(0, 179, 197, 0) 100%);					
			}			
		}
		&::before {
			position: absolute;
			display: block;
			z-index: 1;
			content: "";
			top: 0;
			left: 0;
			height: 115px;
			width: 30px;
			background: linear-gradient(90deg, rgba(0, 179, 197, .7), rgba(0, 179, 197, 0));

			@include media-breakpoint-up(lg) {
				top: -5px;
				height: 30px;
				width: 100%;
				background: linear-gradient(180deg, rgba(0, 179, 197, 0.7) 0%, rgba(0, 179, 197, 0) 100%);		
			}
		}	
		@include media-breakpoint-up(lg) {
			height: 440px;
		}				
	}

	.culinary-episode-card {
		height: 115px;
		box-shadow: $shadow;
		overflow: hidden;	
		@include media-breakpoint-up(lg) {
			min-width: 100%;
		}		
	}

	.culinary-episode-cards::-webkit-scrollbar { 
		display: none;  /* Safari and Chrome */
	}

	.map-controls {
		position: absolute;
		top: 40px;
		right: 0;
		flex-direction: column;
		display: flex;
		@include media-breakpoint-up(lg) {
			right: 25px;
		}
	}

	.map-controls__button {
		font-size: .9em;
		width: 45px;
		height: 45px;
		color: $quaternary;
		background-color: $white;
		transition: all .2s ease-in-out;
		box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.20);
		&:hover {
			background-color: $septenary;
			color: $white;
		}
	}

	.culinary-map-pane::-webkit-scrollbar { 
		display: none;  /* Safari and Chrome */
	}

	.subpane {
		max-height: 100vh;
		overflow: auto;
		top: 0;
		position: fixed;
		z-index: 100;
		box-shadow: 0.25rem 0 2.25rem 2.25rem rgba(0,0,0,.35);
		width: 100%;
		left: 0;
		border-radius: 15px 15px 0 0;
		animation-name: subpaneSlide;
		animation-duration: .75s;
		&::after {
			position: sticky;
			display: block;
			content: '';
			bottom: 0;
			height: 65px;
			width: 100%;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.27) 0%, rgba(0, 179, 197, 0) 100%);				
		}		
		&.is-hidden {
			display: none;
		}

		@include media-breakpoint-up(lg) {
			position: absolute;
			overflow: visible;
			top: 5%;
			max-height: none;
			z-index: 2;
		}
	}

	.subpane__close {
		border: 0;
		position: sticky;
		border-radius: 25px 0 0 25px;
		width: 45px;
		height: 40px;
		z-index: 4;
		bottom: 92%;
		left: 100%;	
		@include media-breakpoint-up(lg) {
			bottom: 90%;
		}		
	}

	.subpane-episode-card {
        position: relative;
		border-radius: 15px 15px 0 0;		
        &:before {
            content: " ";
            position: absolute;
			background: linear-gradient(to right, $tertiary 25%, $senary 25%);
            display: block;
			z-index: 3; 
            height: 12px;
            left: 0;
            bottom: 0;
            width: 100%;
        }
	}

	.subpane-episode-card__img {
		width: 35%;
		@include media-breakpoint-up(lg) {
			width: 30%;
		}		
	}

	.subpane-episode-card__content {
		width: 70%;
		p {
			width: 80%;
			@include media-breakpoint-up(lg) {
				width: 100%;
			}			
		}
	}

	.svg-5050 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);		
		width: auto;
		height: 185px;
		@include media-breakpoint-up(md) {
			height: 240px;
		}
	}

	.senary-overlay-border {
		background-color: $senary;
		height: calcRem(24);
		left: 0;
		opacity: .64;
		position: absolute;
		bottom: 0;
		width: 100%;
		@include media-breakpoint-up(lg) {
			height: 100%;
			left: auto;
			right: 0;
			top: 0;
			width: calcRem(24);
		}
	}

	.culinary-play-button {
		background-color: $white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		height: calcRem(56);
		justify-content: center;
		width: calcRem(56);
		color: $quaternary;
	}

	.culinary-partner-card-container {
		padding-right: 0.25rem;

		&:nth-of-type(even) {
		  padding-right: 0.75rem;
		  padding-left: 0.25rem;
		}

		@include media-breakpoint-up(lg) {
			padding-right: 0.75rem;
			padding-left: 0.75rem;

			&:nth-of-type(even) {
				padding-right: 0.75rem;
				padding-left: 0.75rem;
			}
		}
	}

	.culinary-deals-card {
		.card-body {
			height: calcRem(144);

			@include media-breakpoint-up(sm) {
				height: calcRem(160);
			}

			@include media-breakpoint-up(lg) {
				height: calcRem(144);
			}
		}		
	}

	.culinary-partner-card {
		height: calcRem(155);
		@include media-breakpoint-up(sm) {
			height: calcRem(256);
		}

		img {
			max-height: 85px;
			@include media-breakpoint-up(sm) {
				max-height: 150px;
			}			
		}
	}

	.hero-video {
		max-height: 375px;
		height: 100%;
		position: relative;
		z-index: 1;
		
		@include media-breakpoint-up(md) {
			min-height: unset;
		}
		@include media-breakpoint-up(lg) {
			max-height: 500px;
		}
		@include media-breakpoint-up(xl) {
			max-height: 650px;
		}
	}
	
	.bandwango-container {
		position: relative;

		@include media-breakpoint-up(lg) {
			text-align: left;
		}		

		&:before, &:after {
			content: "";
			width: 23px;
			height: 23px;
			background-color: $white;
			border-radius: 50%;
			position: absolute;
			@include media-breakpoint-up(lg) {
				width: 32px;
				height: 32px;
			}				
		}

		&:before {
			top: -13px;
			left: 0;
			transform: translateX(-50%);			
			@include media-breakpoint-up(lg) {
				top: -21px;
			}				
		}

		&:after  {
			top: -13px;
			right: 0;
			transform: translateX(50%);

			@include media-breakpoint-up(lg) {
				bottom: -21px;
				top: unset;				
				left: 0;
				transform: translateX(-50%);		
			}						
		}

		.bandwango-content {
			text-align: center;
			@include media-breakpoint-up(lg) {
				text-align: left;
			}
		}
	}

	.bandwango-border {
		border-bottom: 2px dashed #3092A8;
		border-right: none;

		@include media-breakpoint-up(lg) {
			border-right: 2px dashed #3092A8;
			border-bottom: none;
		}
	}

	.bandwango-image-container {
		position: relative;
		display: inline-block;
		
		.bandwango-image  {
			display: block;
			border: 5px solid #A4D2DD;
		}

		.corner-img {
			position: absolute;
			width: 40px;

			@include media-breakpoint-up(lg) {
				width: auto;
			}
		}

		.top-right {
			top: -10px;
			right: -13px;
			@include media-breakpoint-up(lg) {
				top: -15px;
				right: -20px;
			}			
		}

		.top-left {
			top: -13px;
			left: -10px;
			transform: rotate(270deg);
			@include media-breakpoint-up(lg) {
				top: -20px;
				left: -15px;
			}			
		}

		.bottom-left {
			bottom: -10px;
			left: -13px;
			transform: rotate(180deg);
			@include media-breakpoint-up(lg) {
				bottom: -15px;
				left: -20px;
			}
		}

		.bottom-right {
			bottom: -13px;
			right: -10px;
			transform: rotate(90deg);
			@include media-breakpoint-up(lg) {
				bottom: -20px;
				right: -15px;
			}			
		}
		
		.foodie-passport {
			width: 35%;
			bottom: -25px;
			right: 35px;
			transform: rotate(13.95deg);
			@include media-breakpoint-up(lg) {
				bottom: -45px;
				right: 60px;
			}				
		}
	}
	//------------------------------------------------------------------------//
	// UTILITIES
	//------------------------------------------------------------------------//
	.mw-700 {
		// max-width: 43.75rem;
		max-width: calcRem(700);
	}

	.circle-nav-button {
		align-items: center;
		border-radius: 50%;
		box-shadow: 0 5px 14px rgba(0,0,0,.2);
		display: flex;
		height: calcRem(60);
		justify-content: center;
		width: calcRem(60);
		&.slick-disabled {
			pointer-events: none;
			opacity: .5;
		}
	}

	.toast {
		border: 0;
		border-radius: map-get($spacers, 2);
		bottom: .5rem;
		left: 2.5%;
		max-width: calcRem(650);
		opacity: 1;
		overflow: hidden;
		position: fixed;
		animation-name: fade-in;
		animation-duration: .4s;
		width: 95%;
		z-index: 9999999999;//above audio-eye icon on production
		// .toast-header {
		// 	align-items: center;
		// 	border: 0;
		// 	display: flex;
		// 	justify-content: between;
		// }
		// .toast-body {
		// 	background-color: $white;
		// }
		.close {
			align-items: center;
			background-color: $gray-200;
			border-radius: 50%;
			display: flex;
			height: calcRem(50);
			justify-content: center;
			width: calcRem(50);
			&:hover {
				background-color: $tertiary;
				color: $white;
			}
		}
		.sticky-padding & {
			bottom: 1.5rem;
		}
		@include media-breakpoint-up(lg) {
			// bottom: 1rem;
			left: 2rem;
			width: 100%;
		}
	}

}


// MODAL
dialog {
	animation: fade-out 0.4s ease-out;
	animation-delay: .4;
	width: 100%;
	max-width: calcRem(960);
	border: 0;
    border-radius: 15px;
	padding: 0;
	max-height: 90vh;
	overflow-y: auto;
	z-index: 5;	
	.modal-image {
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 275px;
		}		
	}		
}

dialog[open] {
	animation: fade-in 0.25s ease-out;
	animation-delay: .25;
	width: 100%;
	max-width: calcRem(960);
	border: 0;
    border-radius: 15px;
	padding: 0;
	max-height: 90vh;
	overflow-y: auto;
	z-index: 5;	
	.modal-image {
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 275px;
		}		
	}	
}

dialog[open]::backdrop {
	animation: backdrop-fade-in 0.25s ease-out forwards;
}
dialog::backdrop {
	animation: backdrop-fade-out 0.25s ease-out forwards;
}

.dialog-modal-body {
	padding: map-get($spacers, 5) map-get($spacers, 5) map-get($spacers, 6);
	@include media-breakpoint-up(sm) {
		padding: map-get($spacers, 5) map-get($spacers, 6) map-get($spacers, 6);
	}	
}

.dialog-close {
	border: 0;
	position: sticky;
	top: map-get($spacers, 4);
	left: 100%;
	padding: map-get($spacers, 2) map-get($spacers, 4) map-get($spacers, 2) map-get($spacers, 3);
	border-top-left-radius: 100px;
	border-bottom-left-radius: 100px;
	color: $white;
}

/* Animation keyframes */

@keyframes fade-in {
	0% {
		// opacity: 0;
		transform: translateY(100%);
		display: none;
	}

	100% {
		// opacity: 1;
		transform: translateY(0);
		display: block;
	}
}

@keyframes fade-out {
	0% {
		// opacity: 1;
		transform: translateY(0);
		display: block;
	}

	100% {
		// opacity: 0;
		transform: translateY(100%);
		display: none;
	}
}

@keyframes backdrop-fade-in {
	0% {
		background-color: rgb(0 0 0 / 0%);
	}

	100% {
		background-color: rgb(0 0 0 / 65%);
	}
}
@keyframes backdrop-fade-out {
	0% {
		background-color: rgb(0 0 0 / 65%);
	}
	
	100% {
		background-color: rgb(0 0 0 / 0%);
	}
}
@keyframes subpaneSlide {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
