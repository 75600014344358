// .swiper{
// 	padding-bottom: 25px;
// 	.swiper-pagination{
// 		bottom: 0;
// 		height: 25px;
// 	}
// }


.slick-nav{
	//border: 1px solid orange;
	pointer-events: none;
	.slick-arrow{
		.fas{
			font-size: 2rem;
		}
	}

	.slick-prev,
	.slick-next{
		pointer-events: all;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		display: flex!important;
		align-items: center;
		justify-content: center;

		&.slick-disabled{
			opacity: .5;
		}

		&::after,
		&::before{
			content: none;
		}
	}

	// &.slick-nav-stories{
	// 	.slick-prev {
	// 		left: -5px;
	// 	}
	// 	.slick-next {
	// 		right: -5px;
	// 	}
	// }

	//full-width arrows
	&--full{
		//border: 1px solid pink;
		.slick-prev,
		.slick-next{
			width: 50px;
			height: 50px;

			&.slick-disabled{
				//height: 0;
				opacity: .5;
			}
			box-shadow: 0 5px 14px rgba(0,0,0,.2);
		}
		.slick-prev {
			//left: 30px;
			left: calc(50% - 30px);
			transform: translateX(-50%);
		}
		.slick-next {
			//right: 30px;
			right: unset;
			left: calc(50% + 30px);
			transform: translateX(-50%);
		}

		@include media-breakpoint-up(xxl) {
			.slick-prev,
			.slick-next{
				width: 60px;
				height: 60px;
			}
			.slick-prev {
				left: calc(50% - 35px);
			}
			.slick-next {
				left: calc(50% + 35px);
			}
		}
	}

	&--img50{
		top: 35%;
		@include media-breakpoint-up(xxl) {
			top: 38%;
		}
	}
}


.sidebyside{
	.slick-nav{
		width: 100%;
		
		@include media-breakpoint-up(md) {
			width: 60px;
		}
	}
	.order-1{
		.slick-nav{
			margin-right: 3rem;
		}
	}
	.order-2{
		.slick-nav{
			margin-right: 2rem;
		}
	}

	&-image-wrapper{
		&::after{
			content: '';
			z-index: 9;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			@include background-gradient-corner(transparent, $transparentPrimary);

		}
	}
}


//todo-jk: verify where this should go
.slick-overflow{
	//border: 1px solid pink!important;
	&.slick-slider,
	.slick-list{
		overflow: visible;
	}
}

.article-slide-item {
    transition: opacity 0.3s ease;
}