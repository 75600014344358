
.attributes-modal,
.accessibility-modal{
    position: fixed;
    display: block;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-bg{
        position: absolute;
        background-color: rgba(0,0,0,.7);
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &-content{
        position: absolute;
        background-color: white;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media screen and (min-width: map-get($grid-breakpoints, sm)) {
            width: 80%;
            height: auto;
            border-radius: $btnBorderRadius;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 800px;
            max-height: 90%;
            min-height: 300px;
        }

        @media screen and (min-width: map-get($grid-breakpoints, md)) {
            width: 60%;
        }
    }


    &-header{
        //border: 1px solid pink;
    }

    &-body{
        //border: 1px solid pink;
        overflow-y: auto;
        //max-height: 200px;
        flex-grow: 1;
    }

    &-footer{
        //border: 1px solid pink;
    }

    &-filter-group{
        position: relative;
        //border: 1px solid blue;
        &::before{
            position: relative;
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $gray-300;
            margin: 1rem 0;
        }
        &:last-of-type::after{
            position: relative;
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $gray-300;
            margin: 1rem 0;
        }
    }

}