$social-overlay: #99876d;//#c6baa8;

.social-share-button {
	a{
		@extend .btn;
		@extend .btn-septenary;
		//color: white;
	}
	// padding: 5px 10px;
	// text-align: center;
	// a {
	// 	max-width: 100px;
	// 	margin: 0 auto;
	// 	@include roundBtn($primary, 1em, .5em);
	// 	text-decoration: none; 
 	// 	//font-family:  $accentFont;
	// 	outline: none;
	// 	&:link {
	// 		color: white;
	// 	}
	// 	.button__icon {
	// 		margin: -5px 5px 0 0;
	// 	}
	// }
}

.social-overlay {
	height: 100%;
	width: 100%;
	background-color: rgba($primary,.8);
	z-index: 3001;//99;//needs to be above bing maps infobox
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: fixed;
}

.social-overlay__close {
	right:2%;
	top: 2%;
	position: absolute;
	a {
		color: $primary;
		text-decoration: none; 
		outline: none;
		font-size: 1.5em;
		color: #000;
		&:visited {
			color: #000;
		}
		&:link {
			color: #000;			
		}
	}
}

.social-overlay__content {
 	position: absolute;
 	top: 45%;
  	transform: translateY(-45%);
 	color: $primary;
 	text-align: center; 
 	width: 100%;
}

.social-overlay__inner-content {
	display: inline-block;
	background-color: lighten($neutral, 2);
	padding: 25px 25px 35px 25px;
	position: relative;
}

.social-overlay__content--header {
	@include r-font-size(30px, 120px);
 	//@include responsive-font(3vw, 30px, 120px, 50px);	
 	//font-family:  'Oswald', sans-serif; 
 	margin-bottom: 3vw;
}

.social-overlay__content--icons {
	min-width: 250px;
	$social-overlay__icons--width: 28%;
	display: inline-block;
	width: $social-overlay__icons--width;
	right: calc(100% - #{$social-overlay__icons--width})/2;

	a{ 
		//$social-overlay__icons--icon-width: 20%;
		//width: $social-overlay__icons--icon-width;
		//margin-right: (100% - ($social-overlay__icons--icon-width*4))/3;
		width: 20%;
		margin-right: 3%;
		&:last-child {
			margin-right: 0%
		}
		position:relative;
		text-decoration: none;
		outline: none;

		img{
			width: 20%;
		}
	}
}


.hide-social-buttons {
	.social-share-button {
		display: none;
	}
}