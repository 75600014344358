
.ui-autocomplete{
	background-color: white;
	list-style-type: none;
	padding: .5rem .5rem .25rem .5rem;
	width: 200px;
	position: absolute;
	top: 0;
	left: 0;
	cursor: default;
	z-index: 6;

	.ui-menu-item{
		a,
		a:link, 
		a:visited{
			@include r-font-size(14,16);
			line-height: 1.1;
			display: block;
			margin-bottom: .5rem;
		}
	}

	@extend .shadow;
}


