.polaroid-container {
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

.polaroid-container--flex-wrapper {
	max-width: 1175px;
	margin: auto;
	padding: 0;
	text-align: center; //so it looks okay in unsupporting browsers
	display: flex;
	justify-content: space-around;
	flex-flow: row wrap;
	@media screen and (min-width: $mq-tablet-landscape){
		justify-content: space-between;
	}
	
}

.polaroid {
	box-sizing: border-box;
	width: 295px;
    height: auto;
	margin: 15px auto;
	display: inline-block;
	background-color: #fff;
	vertical-align: top;
	box-shadow: 0 0 5px 2px #ddd;
	@media screen and (min-width: $mq-tablet-portrait) {
		//width: 355px;
		width: 30%;
		//height: 420px;
		margin: 15px 1px;
	}
	@media screen and (min-width: $mq-tablet-landscape) {
		margin: 15px 10px;
	}
}

.polaroid--rectangle {
	@media screen and (min-width: $mq-tablet-portrait) {
		width: 560px;
		//height: 405px;
	}
}

.polaroid__inner {
	margin: auto;
	.polaroid--rectangle & {
		width: 265px;
		@media screen and (min-width: $mq-tablet-portrait) {
			width: 530px;
		} 
	}
}

.polaroid--empty {
	margin: 0 auto;
	background-color: transparent;
	box-shadow: none;
	height: auto;
}

.polaroid--small {
	width: 254px;
	//height: 340px;
	font-size: .8em;
}

.polaroid__image {
	width: 265px;
	display: inline-block;
	margin-top: 15px;
    margin-bottom: 15px;
	@media screen and (min-width: $mq-tablet-portrait) {
		width: 320px;
	}
	.polaroid--small & {
		width: 224px;
		@media screen and (min-width: $mq-tablet-portrait) {
			width: 224px;
		}
	}
	.polaroid--rectangle & {
		@media screen and (min-width: $mq-tablet-portrait) {
			width: 530px;
			//height: 335px;
		}
	}
}

.polaroid__link {
	&:link, &:visited {
		text-decoration: none;
	}
}

.polaroid__title {
	box-sizing: border-box;
	display: block;
	text-align: center;
	padding: 35px 35px 0;
	font-size: 1.1em;
	height: 107px;
	@media screen and (min-width: $mq-tablet-portrait) {
		//make sure they align regardless of how long the title is
		height: 95px;
	}
	.polaroid--small & {
		height: 76px;
	}
}

.polaroid__title--overflow {
	overflow: hidden;
}

.polaroid__category {
	display: inline-block;
	text-align: center;
	text-transform: uppercase;
	font-size: .8em;
	font-weight: bold;
	color: $tertiary;
	padding: 0 20px 20px 20px;
	vertical-align: bottom;
	&:before {
		content: ' ';
		width: 135px;
		border-top: 1px solid rgba($primary, .3);//; $transparentPrimaryDark;
		display: block;
		margin: auto;
		padding-bottom: 20px;
		margin-top: 20px;
	}
}



.polaroid__info{
	display: flex;
	//border: 1px solid blue;
	flex: 1;
	align-items: center;
	margin-bottom: 1rem;

	span{
		display: block;
		text-align: center;
		padding: 0 1rem 0 1rem;
		font-size: 1.1em;
		width: 100%;
	}
}