
//@import "imports";  


.destinationvideos{
	//border: 1px solid pink;

	$quaternary-light: #FCD6B1;
	$senaryLighter        : #FFDA88;

	// RESET
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
	

	// TYPOGRAPHY 
	color: $primary !important;
	font-size: 1.125rem;
	line-height: 1.3;
	p, h1, h2, h3, h4, h5, h6 {
		color: $primary;
	}
	h2, h3, h4 {
		margin-top: 0;
		line-height: 1;
	}
	h1 {
		line-height: .85;
	}
	p{
		margin-top: 0;
	}
	ul{
		margin-top: 0;
	}
	.btn{
		border: 0;
	}

	.text-shadow {
		text-shadow: 0 3px 47px rgba(0, 0, 0, .68) !important;
	}
	.text-shadow-small {
		text-shadow: 0 3px 11px rgba(0, 0, 0, .68) !important;
	}

	.pill {
		border-radius: 1.5rem
	}

	.text-ghosted{
		opacity: .1;
	}

	.text-light{
		p{
			color: $white!important;
		}
	}

	.input-group{
		select{
			border: 1px solid transparent;
			border-right: 1px solid $gray-400 !important;//todo-jk: come back and cleanup
			height: 40px;
			&:last-of-type{
				border-right: 0;
			}
		}
	}


	.bg-dark-ghosted{
		background-color: rgba(0,0,0,.5);
	}

	.bg-radial-gradient {
		
		&::after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			//height: 100px;
			content: " ";
			
			background-image: radial-gradient(
				farthest-corner at 1% 95%,
				rgba(0,0,0,.7) 0%,
				transparent 80%
			);
			z-index:1;
			pointer-events: none;
		}

		&.video-header{
			&::after{
				//border: 1px solid green;
				height: 100%;
				display: none;
				@include media-breakpoint-up(md) {
					display: block;
				}
				pointer-events: none;
			}
		}

		&.content-card-content{
			&::after{
				//border: 1px solid pink;
				display: block;
				height: 100px;
			}
		}
	}


	.bg-img-cover{
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	
	.bg-video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: relative;
		min-height: 350px;
	}




	.video-header{
		//font-size: 0;
		min-height: 350px;
		@include media-breakpoint-up(md) {
			height: 465px;
		}
		@include media-breakpoint-up(lg) {
			height: 600px;
		}

		.video-info{
			display: block;
			position: relative;
			background-color: $primary;

			@include media-breakpoint-up(md) {
				width: 100%;
				height: 100%;
				position: absolute;
				bottom: 0;
				z-index: 3;
				background-color: transparent;
			}

			.video-info-image{
				@include media-breakpoint-down(sm) {
					margin-top: -220px;
				}
			}
		}

		&::before{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 11px;
			content: " ";
			display: block;
			background: linear-gradient(to right, $tertiary 0, $tertiary 10%, $quaternary 10%, $quaternary 20%, $senary 20%, $senary 100%);
			z-index: 2;
		}

		.video-poster-foreground{
			border: 6px solid $senary;
		}

		//YOUTUBE STUFF from here: https://codepen.io/cvn/pen/WbXEoX it's in LESS and I updated to SASS
		$size: 30%;
		.video-bg {
			position: relative;
			min-height: 250px;
			@include media-breakpoint-up(md) {
				background: $white;
				position: absolute;
				top: 0; right: 0; bottom: 0; left: 0;
				z-index: 1;
				overflow: hidden;
				min-height: unset;
			}

			.poster-wrapper{
				img{
					object-fit: cover;
					min-height: 250px;
					width: 100%;
					height: 100%;
					filter: blur(.6rem);
					@include media-breakpoint-up(md) {
						filter: blur(.8rem);
					}
				}
				&::before,
				&::after{
					content: '';
					display: block;
					width: 100%;
					position: absolute;
					z-index: 1;
				}
				&::before{
					top: 0;
					right: 0;
					height: 100%;
					background-image: linear-gradient(to right, $secondary, transparent);
					opacity: .8;
				}
				&::after{
					@include media-breakpoint-down(md) {
						bottom: 0;
						right: 0;
						height: 50%;
						background-image: linear-gradient(to top, $primary, transparent);
						opacity: 1;
					}
				}
			}
		}
		.video-bg .video-fg,
		.video-bg iframe,
		.video-bg .poster-wrapper,
		.video-bg video {
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}



		/*
		Full page video background
		Simulate object-fit: cover
		Based on http://fvsch.com/code/video-background/
		*/

		@media (min-aspect-ratio: "12/9") {
			.video-bg.cover .video-fg { 
				height: 300%; top: -100%;
				//border: 1px solid red;
			}
		}
		@media (max-aspect-ratio: "12/9") {
			.video-bg.cover .video-fg { 
				width: 300%; left: -100%;
				//border: 1px solid yellow;
			}
		}

		@supports (object-fit: cover) {
			.video-bg.cover .video-fg.supports-cover {
				width: 100%;
				height: 100%;
				top: 0; left: 0;
			}
			.video-bg.cover video {
				object-fit: cover;
			}
		}


		/*
		Vertical centering for 16/9 youtube iframes and video elements
		Simulate object-fit: contain for entire element, not just contents of element
		*/

		.video-bg.contain {
			font-size: 0;
		}
		.video-bg.contain * {
			font-size: 16px;
		}
		.video-bg.contain:before {
		content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}
		.video-bg.contain .video-fg {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 75%; /* 12:9 */
			//padding-bottom: 56.25%; /* 16:9 */
			pointer-events: none;
		}
		.video-bg.contain iframe,
		.video-bg.contain video {
			pointer-events: auto;
		}

		@media (min-aspect-ratio: "12/9") {
			.video-bg.contain .video-fg {
				height: 100%;
				padding-bottom: 0;
				left: 50%;
				// max-width: calc(#{$size} * 16 / 9);
				// margin-left: calc(#{$size} * 16 / 9) / -2;
				max-width: calc(#{$size} * 12 / 9);
				margin-left: calc(#{$size} * 12 / 9) / -2;
			}
		}



	}


	.before-blue-top{
		position: relative;
		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 20px;
			content: " ";
			display: block;
			background: $tertiary;
			z-index: 2;
		}
		&.--relative{
			&::before{
				position: relative;
			}
		}
		&.--d-md-block{
			&::before{
				@include media-breakpoint-down(sm){
					display: none;
				}
			}
		}
		
	}



	.explore-widget{
		bottom: 1rem;
		z-index: 999998;//one less than popup traveler
		opacity: 0;
		transition: opacity 500ms ease-out;

		&.reveal{
			opacity: 1;
		}

		.sticky-padding &{
			bottom: 14%;
			@include media-breakpoint-up(sm) {
				bottom: 9%;
			}
		}

		&-close{
			width: 40px;
			height: 40px;
		}
		&-btn{
			@extend .btn-light;
			svg{
				color: $septenary;
				path{
					fill: $septenary;
				}
			}
		}
		&-body{
			display: none;
			&-content{
				max-height: 65vh;
				overflow-y: auto;
			}
		}

		&.is-active{
			.explore-widget-btn{
				@extend .btn-septenary;
				color: $white;
				svg{
					color: $white;
					path{
						fill: $white;
					}
				}
			}
			.explore-widget-body{
				display: block;
			}
		}
	}



	// HERO SECTION 
	$heroHei: 420px;
	.campaign-hero-section {
		height: $heroHei;
		// max-height: 475px;
		position: relative;
		z-index: 1;
		img {
			width: 100%;
		}

		.campaign-hero-img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			min-height: 425px;
			max-height: 475px;
			@include media-breakpoint-up(lg) {
				max-height: 515px;
			}
			@include media-breakpoint-up(xl) {
				max-height: 620px;
			}
		}

		.campaign-hero-heading {
			//border: 1px solid green;
			top: 24%;//32
			left: 50%;
			width: 70%;
			position: absolute;
			transform: translate(-50%, -50%);
			z-index: 2;

			@media (min-width: 480px) {
				//border: 1px solid yellow;
				top: 32%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			@include media-breakpoint-up(sm) {
				//border: 1px solid red;
				top: 40%;
				width: 50%;
			}

			@include media-breakpoint-up(md) {
				//border: 1px solid blue;
				top: 45%;
				max-width: 500px;
				width: 60%;
			}
		}

		.hero-video-logo {
			width: 55%;

			@include media-breakpoint-up(lg) {
				width: 24%;
			}
		}

		&::before{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 11px;
			content: " ";
			display: block;
			background: linear-gradient(to left, $senary 80%, $quaternary 20%);
			z-index: 2;
		}
		&::after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 10%;
			height: 11px;
			content: " ";
			display: block;
			background: $tertiary;
			z-index: 3;
		}

		@include media-breakpoint-up(md) {
			height: auto;
		}
		@media screen and (min-width: map-get($grid-breakpoints, lg)) {
			// max-height: 465px;
			max-height: 500px;
		}
		@media screen and (min-width: map-get($grid-breakpoints, xl)) {
			// max-height: 565px;
			max-height: 650px;
		}
		@media screen and (min-width: 1800px) {
			max-height: 800px;
		}
	}




	.hero-second{
		position: relative;
		
		&-img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			height: 325px;
			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				height: 465px;
			}
			@media screen and (min-width: map-get($grid-breakpoints, xl)) {
				height: 565px;
			}
		}

		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 11px;
			height: 100%;
			content: " ";
			display: block;
			background: linear-gradient($tertiary 80%, $quaternary 20%);
			z-index: 2;
		}
	}



	.custom-scrollbar{
		&::-webkit-scrollbar {
			width: 12px;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $gray-300;
			border-radius: 20px;
			//border: 3px solid $primary;
		}

		&:hover{
			&::-webkit-scrollbar-track {
				background: $gray-300;
			}
			&::-webkit-scrollbar-thumb {
				background-color: $gray-500;
			}
		}
	}


	.circle-icon{
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&.episode-guide{
			background-color: $tertiaryLighter;
			color: $septenary;
			width: 40px;
			height: 40px;
			img{
				width: 20px;
			}
			@include media-breakpoint-up(md){
				width: 70px;
				height: 70px;
				img{
					width: 30px;
				}
			}
		}
		&.play{
			background-color: $quaternary;
			color: $white;
			width: 40px;
			height: 40px;
		}
		&.highlight{
			background-color: $white;
			color: $quaternary;
			width: 40px;
			height: 40px;
		}
	}

	.btn.pill.icon-tight{
		padding-left: .35rem;
	}



	
	.colors-alternate {
		.content-card{
			&:nth-child(3n){
				.content-card-header{
					background-color: $tertiary;
				}
			}
			&:nth-child(3n-1){
				.content-card-header{
					background-color: $senary;
				}
			}
			&:nth-child(3n-2){
				.content-card-header{
					background-color: $quaternary;
				}
			}
		}
	}

	.colors-seasonal{
		[data-season="summer"]{
			background-color: $senary;
		}
		[data-season="fall"]{
			background-color: $quaternary;
		}
		[data-season="winter"]{
			background-color: $tertiary;
		}
	}

	.map-carousel{
		@include media-breakpoint-down(sm){
			margin-left: -1rem;
			margin-right: -1rem;
			.card{
				margin-left: .5rem;
				margin-right: .5rem;

				&:first-child{
					margin-left: 1rem;
				}
				box-shadow: $box-shadow !important;
			}
		}
		@include media-breakpoint-down(md){
			.card.slide-active{
				box-shadow: $box-shadow !important;
			}
			.slick-list{
				padding-bottom: 1.5rem;
			}
		}
		@include media-breakpoint-up(md){
			box-shadow: $box-shadow !important;
		}
	}

	.highlights-carousel-nav,
	.map-carousel-nav,
	.explore-carousel-nav,
	.content-carousel-nav{
		pointer-events: none;
		.slick-arrow{
			.fas{
				font-size: 2rem;
			}
		}

		.carousel-prev,
		.carousel-next{
			pointer-events: all;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			display: flex!important;
			align-items: center;
			justify-content: center;

			&.slick-disabled{
				//height: 0;
				opacity: .5;
			}
		}
	}

	.explore-carousel-nav{
		top: 0px;
		//border: 1px solid pink;
		margin-left: -15px;
		margin-right: -15px;
		width: calc(100% + 30px);

		.carousel-prev,
		.carousel-next{
			width: 38px;
			height: 38px;
			z-index: 2;
		}

		&::before,
		&::after{
			position: absolute;
			top: 0;
			width: 50px;
			height: 100%;
			content: " ";
			display: block;
			z-index: 1;
		}

		&::before{
			left: 0;
			background: linear-gradient(to right, $white, transparent);
		}
		&::after{
			right: .5rem;
			background: linear-gradient(to left, $white, transparent);
		}
	}


	.overview-carousel-nav{

		//top: 20%;//200px;
		left: 0;
		right: 0;

		// @media screen and (min-width: 380px) {
		// 	top: 25%;//240px;
		// }

		// @media screen and (min-width: 480px) {
		// 	top: 32%;//320px;
		// }

		// @include media-breakpoint-up(sm) {
		// 	top: 37%;//400px;
		// }

		@include media-breakpoint-up(md) {
			//top: 0;
			left: unset;
		}

		.slick-arrow{
			.fas{
				font-size: 2rem;
			}
		}

		.carousel-prev,
		.carousel-next{
			background-color: white;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

	}

	.explore-content-carousel{
		.slick-track{
			//border: 1px solid pink;
			padding-bottom: 1rem;
			display: flex;
			.slick-slide{
				height: inherit!important;
			}
		}
	}

	.overview-slider{

		.overview-slide{

		}

		//stretch to fit height of slider
		// .slick-track{
		// 	display: flex;
		// 	.slick-slide{
		// 		height: inherit!important;
		// 	}
		// }

		.overview-image-wrapper{
			&::before{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 11px;
				content: " ";
				display: block;
				background: linear-gradient(to right, $primary 80%, $tertiary 20%);
				z-index: 2;

				@include media-breakpoint-up(md) {
					width: 15px;
					height: 100%;
					background: linear-gradient($primary 80%, $tertiary 20%);
				}
			}
			&::after{
				position: absolute;
				display: block;
				width: 100%;
				height: 20px;
				right: 0;
				bottom: 0;
				content: " ";
				background-color: rgba($primary, .5);
				z-index: 2;
				@include media-breakpoint-up(md) {
					width: 30px;
					height: 100%;
					top: 0;
					bottom: unset;
				}
			}

		}
		.overview-image{
			width: 100%;
			height: 100%;
			min-height: 300px;
			object-fit: cover;
		}
		
	}



	.random-carousel-nav{
		.slick-arrow{
			.fas{
				font-size: 2rem;
			}
		}

		.carousel-prev,
		.carousel-next{
			background-color: $white;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}


	.random-slider{
		.random-slide-img{
			&::before{
				position: absolute;
				left: 0;
				top: 0;
				width: 11px;
				height: 100%;
				content: " ";
				display: block;
				background: linear-gradient($senary 80%, $quaternary 20%);
				z-index: 2;

			}
			&::after{
				position: absolute;
				left: 0;
				bottom: 0;
				width: 11px;
				height: 10%;
				content: " ";
				background-color: $tertiary;
				z-index: 2;
				display: block;
				// @include media-breakpoint-up(md) {
				// 	width: 30px;
				// 	display: block;
				// }
			}
		}
	}

	.masonry-grid{
		.js-gutter-sizer{
			width: 4%;
		}
		&:not(.always){
			.js-masonry-item,
			.js-masonry-sizer{
				width: 100%;
				@include media-breakpoint-up(md) {
					width: 48%;
				}
			}
		}
		&.always{
			.js-masonry-item,
			.js-masonry-sizer{
				width: 48%;
			}
		}
	}




	.quote-before{
		position: relative;
		&::before{
			content: open-quote;
			font-size: 60px;
			color: $quaternary;
			position: absolute;
			top: 0;
			left: -2rem;
			@include media-breakpoint-up(md) {
				left: -3.5rem;
				font-size: 100px;
			}
		}
	}


	.inspire-tabs,
	.explore-tabs{
		//border: 1px solid pink;
		.btn{
			//opacity: .9;
			background-color: $senaryLighter;
			&.active2{
				//opacity: 1;
				background-color: $senary;
			}
		}

		.slick-list{
			@include media-breakpoint-down(md) {
				padding-left: 1rem;
			}
		}

		&::before,
		&::after{
			position: absolute;
			top: 0;
			width: 50px;
			height: 100%;
			content: " ";
			display: block;
			z-index: 1;
		}

		&::before{
			left: 0;
			background: linear-gradient(to right, $white, transparent);
		}
		&::after{
			right: 0;
			background: linear-gradient(to left, $white, transparent);
		}
	}

	.explore-tab-bodies{
		position: relative;
		overflow: hidden;

		.js-explore-tab-body{
			position: relative;
			//border: 1px solid green;
			z-index: 2;

			&.offscreen{
				//border: 1px solid red;
				position: absolute;
				top: 0;
				left: -1000px;
				z-index: 1;
			}
		}

		
	}


	$vvcWid: 232px;
	.vertical-video-col{
		@include media-breakpoint-up(md) {
			width: $vvcWid;
		}
	}

	.vertical-video-info{
		position: relative;

		&::after{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: .75rem;
			width: 100%;
			display: block;
			background: linear-gradient(to right, $quaternary 0, $quaternary 80%, $primary 80%, $primary 90%, $tertiaryLight 90%, $tertiaryLight 100%);
			z-index: 2;
		}

		@include media-breakpoint-up(md) {
			
			width: calc(100% - #{$vvcWid});

			&::after{
				content: '';
				position: absolute;
				left: 0;
				top: unset;
				bottom: 0;
				width: .75rem;
				height: 100%;
				display: block;
				background: linear-gradient(to bottom, $tertiaryLight 0, $tertiaryLight 10%, $primary 10%, $primary 20%, $quaternary 20%, $quaternary 100%);
				z-index: 2;
			}
		}
	}

	.vertical-video {
		height: 395px;

		&::after{
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: .6rem;
			height: 100%;
			display: block;
			background: linear-gradient(to bottom, $primary 0, $primary 90%, $senary 90%, $senary 100%);
			z-index: 2;
		}

		@include media-breakpoint-down(md) {
			width: 200px !important;

			&::after{
				background: linear-gradient(to right, $primary 0, $primary 90%, $senary 90%, $senary 100%);
				height: .6rem;
				width: 100%;
				top: 0;
			}
		}

		&-loader{
			pointer-events: none;
		}
	}

	.vertical-video-pause{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: rgba(255,255,255,.3);
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&[data-is-paused="true"]{
			.play{
				display: block;
			}
			.pause{
				display: none;
			}
		}
		&[data-is-paused="false"]{
			.play{
				display: none;
			}
			.pause{
				display: block;
			}
		}
	}
	


	.overhang-padtop{
		@media screen and (max-width: map-get($grid-breakpoints, md)) {
			padding-top: 320px;
			margin-top: -350px;
		}
	}


	$storyGutter: 10px;
	.featured-stories{
		margin-left: 0;
		margin-right: 0;

		.featured-story{
			margin-left: $storyGutter;
			margin-right: $storyGutter;
			margin-bottom: 1.5rem;
			width: 100%;

			@media screen and (min-width: map-get($grid-breakpoints, sm)) {
				width: calc(50% - #{($storyGutter * 2)});
			}
			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				width: calc(33.33% - #{($storyGutter * 2)});
			}
		}

		@include media-breakpoint-up(md) {
			margin-left: -1 * $storyGutter;
			margin-right: -1 * $storyGutter;
		}
	}



	.scroll-text-wrapper{
		min-height: 300px;
		//border: 1px solid pink;
		.scroll-text{
			transition: all 300ms ease-in-out;
			// &.aos-animate{
			// 	left: -500px;
			// }
		}
	}

}


.tw-modal{
	z-index: 999999;
	.tw-modal-body{
		z-index: 9;
		top: 46%;
		left: 50%;
		transform: translate(-50%, -50%);

		@include media-breakpoint-up(md) {
			max-width: 90%;
			max-height: 80%;
		}
		// @media screen and (min-width: map-get($grid-breakpoints, xl)) {
		// 	max-width: 1200px;
		// }
		iframe {
			max-width: 1120px;
			max-height: 630px;
		}
	}
	
	.tw-close{
		top: 0;
		right: 0;
		z-index: 999;
		background-color: transparent;
		border: 0;
	}

	.tw-modal-backdrop{
		z-index: 8;
		background-color: rgba(0,0,0,.5);
		width: 100%;
		height: 100%;
	}

	.bg-video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: relative;
	}



}


.temp-max-wid-wrapper{
	max-width: 1175px;
	//margin: 0 auto;
	// @media screen and (min-width: $mq-tablet-portrait){
	//   //padding: 0 1.5%; 
	//   width: 97%;
	// }
}


.fw-callout-section {
	position: relative;

	&::before{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 24px;
		content: " ";
		display: block;
		background-color: $senary;
		//background: linear-gradient(to right, $tertiary 0, $tertiary 10%, $quaternary 10%, $quaternary 20%, $senary 20%, $senary 100%);
		z-index: 2;
	}
	&::after{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: " ";
		display: block;
		background-color: rgba(0,0,0,.2);
		z-index: 1;
	}

	img{
		min-height: 350px;
		max-height: 550px;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}