.js .clue {
	visibility: hidden;
}

.js .clue.js-clue-ready {
	visibility: visible;
}

.clue__location, .clue > .truncatedStory {
	box-sizing: border-box;
	background-color: #fff;
	width: 300px;
	border-radius: 5px;
	box-shadow: 0px 0px 7px 0px #aaa;
	margin: 1em auto;
	position: relative;
	background-position: center 12px;
	background-repeat: no-repeat;
	padding: 71px 15px 20px;
	margin-bottom: 150px;
	//color: #63615b;
	font-weight: bold;
	.lt-ie9 & {
		/*filter: progid:DXImageTransform.Microsoft.Shadow(Color=#aaaaaa, Strength=3, Direction=0),
         progid:DXImageTransform.Microsoft.Shadow(Color=#aaaaaa, Strength=3, Direction=90),
         progid:DXImageTransform.Microsoft.Shadow(Color=#aaaaaa, Strength=3, Direction=180),
         progid:DXImageTransform.Microsoft.Shadow(Color=#aaaaaa, Strength=3, Direction=270);*/
		 border: 1px solid #aaa;
	}
	@media screen and (min-width: $mq-tablet-portrait) {
		margin-bottom: 0;
	}
}

.clue__location {
	padding-top: 20px;
	margin-bottom: 50px;
	&:before {
		content: '\00A0';
		width: 0;
		height: 0;
		border: 15px solid transparent;
		border-bottom-color: #fff;
		border-left-width: 10px;
		border-right-width: 10px;
		position: absolute;
		top: -30px;
		left: 50%;
		margin-left: -10px;
	}
	.lt-ie9 & {
		&:before {
			content: '';
		}
	}
	.lt-ie9 &.js-content-reset {
		&:before {
			content: '\00A0';
		}
	}
	@media screen and (min-width: $mq-tablet-portrait) {
		margin-right: 0;
		margin-left: auto;
		margin-bottom: 0;
		margin-top: -20px;
		&:before {
			border-bottom-color: transparent;
			border-right-color: #fff;
			top: 50%;
			margin-top: -10px;
			left: -20px;
			margin-left: auto;
		}
		&:after {
			position: absolute;
			content: url('/Images/contest/shoe-right.png');
			right: auto;
			margin-right: auto;
			left: -110px;
			bottom: auto;
			top: 50%;
			margin-top: -45px;
		}
		.lt-ie9 & {
			&:after {
				content: '';
			}
		}
		.lt-ie9 &.js-content-reset {
			&:after {
				content: url('/Images/contest/shoe-right.png');
			}
		}
	}
}

.clue__image {
	display: block;
	width: 280px;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.clue__caption {
	padding-top: 10px;
	//padding-bottom: 10px;
	display: block;
	font-size: .9em;
}

/* Sort of hacky way to achieve ie8 compatible nth-child. Only works if there are no more than three of this class */

.clue > .truncatedStory {
	&:before {
		content: '\00A0';
		width: 0;
		height: 0;
		border: 15px solid transparent;
		border-top-color: #fff;
		border-left-width: 10px;
		border-right-width: 10px;
		position: absolute;
		bottom: -30px;
		left: 50%;
		margin-left: -10px;
	}
	&:after {
		content: url('/Images/contest/shoe-left.png');
		position: absolute;
		bottom: -130px;
		width: 90px;
		left: 50%;
		margin-left: -45px;
	}
	//this is for ie8 as it doesn't repaint the hidden pseudoelements after fading in unless their content gets updated
	.lt-ie9 & {
		&:before {
			content: '';
		}
		&:after {
			content: '';
		}
	}
	@media screen and (min-width: $mq-tablet-portrait) {
		margin-left: 0;
		&:before {
			border-top-color: transparent;
			border-left-color: #fff;
			right: -20px;
			bottom: auto;
			top: 70%;
			margin-top: -10px;
			margin-left: auto;
		}
		&:after {
			left: auto;
			margin-left: auto;
			right: -110px;
			bottom: auto;
			top: 70%;
			margin-top: -45px;
		}
	}
	//this is for ie8 as it doesn't repaint the hidden pseudoelements after fading in unless their content gets updated
	.lt-ie9 &.js-content-reset {
		&:before {
			content: '\00A0';
		}
		&:after {
		content: url('/Images/contest/shoe-left.png');
		}
	}
	
}



.clue--0 {
	background-image: url('/Images/contest/clue01.jpg');
}

.clue--1 {
	background-image: url('/Images/contest/clue02.jpg');
}

.clue--2 {
	background-image: url('/Images/contest/clue03.jpg');
}

%contest-ribbon {
	/* Ribbon */
	display: inline-block;
	background-color: #d8612a;
	padding: 5px 20px;
	margin-bottom: 20px;
	color: #fff;
	position: relative;
	h2 {
		margin: 0;
		font-weight: bold;
		color: #fff;
	}
	em {
		font-style: normal;
		//font-size: .8em;
		font-weight: normal;
		vertical-align: text-bottom;
	}
	// &:before {
	// 	content: '\00A0';
	// 	width: 0;
	// 	height: 0;
	// 	border: 5px solid transparent;
	// 	border-top-color: #918b79;
	// 	border-left-width: 15px;
	// 	border-right-width: 3px;
	// 	position: absolute;
	// 	left: 0;
	// 	bottom: -10px;
	// }
	// &:after {
	// 	content: '\00A0';
	// 	width: 0;
	// 	height: 0;
	// 	border: 5px solid transparent;
	// 	border-top-color: #918b79;
	// 	border-left-width: 3px;
	// 	border-right-width: 15px;
	// 	position: absolute;
	// 	right: 0;
	// 	bottom: -10px;
	// }
}

.contest {
	box-sizing: border-box;
	background-image: url('/Images/contest/bg-mobile.png');
	background-position: center top;
	background-repeat: no-repeat;
	min-height: 2242px;
	width: 100%;
	max-width: 720px;
	margin: 0 auto;
	//padding-top: 150px;
	position: relative;
	@media screen and (min-width: $mq-tablet-portrait) {
		background-image: url('/Images/contest/bg.png');
		min-height: 1964px;
		padding-top: 100px;
		padding-bottom: 100px;
	}
}

.contest-intro {
	//border: 5px solid pink;
	max-width: 960px;
	margin: 0 auto 25px;
	text-align: center;
	//color: #63615b;
	padding: 0 10px;
	// p {
	// 	font-size: 1.1em;
	// }
	h1 {
		// font-style: normal;
		// font-weight: bold;
		// font-size: 2.3em;
		margin-top: 0.5rem;
		margin-bottom: .5em;
	}
	// a {
	// 	font-size: .8em;
	// }
	@media screen and (min-width: $mq-tablet-portrait){
		padding: 0 50px;
		margin: 0 auto 0;
	}
	.truncatedStory {
		box-sizing: border-box;
		//text-align: left;
		@media screen and (min-width: $mq-tablet-portrait){
			//width: 48%;
			display: inline-block;
			vertical-align: top;
			.video-container {
				margin-top: 100px;
			}
		}
		/* For browsers that don't support CSS columns */
		.contest-intro__column {
			@media screen and (min-width: $mq-tablet-portrait){
				width: 50%;
				float: left;
			}
		}
	}

	&--2{
		//border: 1px solid red;
		display: none;
	}

	&.narrow{
		max-width: 600px;
	}
}

.contest-column {
	text-align: left;
	@media screen and (min-width: $mq-tablet-portrait) {
		width: 50%;
		float: left;
	}
}

.contest-column--custom-list ul {
	//list-style-image: url('/images/contest/cheese-bullet.png');
	& > li {
		margin: 15px 0;
	}
}

.contest-column--sun-list ul {
	list-style-image: url('/images/contest/sun-bullet.png');
	& > li {
		font-size: 1.3em;
	}
}

.contest-column--sun-list .truncatedStory {
	margin: 0;
	padding: 0;
}

.contest__prize {
	position: absolute;
	bottom: 20px;
	width: 100%;
	text-align: center;
	img {
		display: inline-block;
	}
	@media screen and (min-width: $mq-tablet-portrait) {
		bottom: 115px;
	}
}

.contest__header {
	text-align: center;
	.truncatedStory:first-child {
		@extend %contest-ribbon;
	}
	.truncatedStory + .truncatedStory {
		/* Text */
		max-width: 720px;
		margin: 0 auto;
		//color: #63615b;
		//font-size: 1.1em;
		padding: 0;
	}
}

.contest-winners {
	box-sizing: border-box;
	max-width: 1175px;
	margin: 100px auto 0;
	//padding-top: 100px;
	//color: #63615b;
	//text-align: center;
	padding-bottom: 20px;
	// p {
	// 	font-size: 1.1em;
	// }
	@media screen and (min-width: $mq-tablet-portrait){
		padding: 0 1.5%; 
		margin: 100px auto;
		width: 97%;
		text-align: left;
		.truncatedStory {
			padding: 0;
		}
	}
	.truncatedStory:first-child {
		@extend %contest-ribbon;
	}
	.media-scroller {
		margin-top: 10px;
		padding: 0;
		@media screen and (min-width: $mq-tablet-portrait){
			margin-top: 50px;
		}
	}
}

.contest-winners__photo {
	box-sizing: border-box;
	background-color: #fff;
	display: inline-block;
	width: 100%;
	max-width: 295px;
	padding: 15px;
	border-radius: 5px;
	box-shadow: 0px 0px 7px 0px #aaa;
	text-align: center;
	.lt-ie9 & {
		filter: progid:DXImageTransform.Microsoft.Shadow(Color=#aaaaaa, Strength=3, Direction=0),
         progid:DXImageTransform.Microsoft.Shadow(Color=#aaaaaa, Strength=3, Direction=90),
         progid:DXImageTransform.Microsoft.Shadow(Color=#aaaaaa, Strength=3, Direction=180),
         progid:DXImageTransform.Microsoft.Shadow(Color=#aaaaaa, Strength=3, Direction=270);
	}
	@media screen and (min-width: $mq-tablet-portrait){
		display: block;
		float: right;
		margin: 0 10px;
	}
	& + .truncatedStory {
		//Text to go with winner image
		min-height: 200px;
		margin-bottom: 20px;
		@media screen and (min-width: $mq-tablet-portrait){
			//margin-bottom: 50px;
		}
	}
}

.contest-winners__image {
	max-width: 100%;
	margin: 0 auto;
	display: block;
}

.contest-winners__caption {
	font-size: .9em;
	//color: #63615b;
	font-weight: bold;
}

.contest-form {
	max-width: 960px;
	margin: 0 auto;
	//color: #63615b;
	padding: 50px 10px;

	.stay-unique &{
		padding: 0 10px;
	}
	&.narrow{
		max-width: 600px;
	}
}