.photo-credit {
	//border: 1px solid blue;
	bottom: 1rem;
	right: 1rem;
	text-align: right;

	.photo-credit__icon-container {
		width: 40px;
		height: 40px;
		position: relative;
		border-radius: 50%;
		cursor: pointer;
		transition: all 200ms ease-in-out;

		@include media-breakpoint-up(md) {
			&:hover{
				scale: 1.2;
			}
		}
	}

	.photo-credit__text {
		left: 1rem;
		opacity: 0;
		position: relative;
		padding: .25rem 1rem;
		background-color: $white;
		font-weight: 400;
		line-height: 1.5;
		@include r-font-size(12,14);
		border-radius: .25rem;
		transition: all 200ms ease-in-out;
	}

	.icon-active{
		display: none;
	}
	.icon-inactive{
		display: block;
	}

	&.active{
		.photo-credit__text {
			opacity: 1;
			left: 0px;
		}

		.icon-active{
			display: block;
		}
		.icon-inactive{
			display: none;
		}
	}
}


.HasCaption {
	.photo-credit {
		bottom: 20%;
	}
}

.StaticCredit {
	.photo-credit {
		bottom: 70px;
		@media screen and (max-width:$mq-tablet-portrait) {
			bottom: 10%;
	    }
	}
}