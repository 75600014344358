.theme{
	//border: 1px solid blue;
	background-color: $theme-light-bg-color;
	color: $dark;
	h1,h2,h3,h4,h5,h6{
		color: $theme-light-header;//$primary;
	}

	.info{
		&-title{
			background-color: $theme-light-info-title;//white;
		}
	}

	a:link, a:visited {
		&:not(.btn-septenary){
			color: $dark;
			text-decoration: underline $dark;
		}
	}
	a:active, a:hover {
		&:not(.btn-septenary){
			color: darken($dark,10);
			text-decoration: underline darken($dark,10);
		}
	}


	&--dark{
		background-color: $theme-dark-bg-color;//$quinary;
		color: white;
		h1,h2,h3,h4,h5,h6{
			color: white;
		}

		.info{
			&-title{
				background-color: $theme-dark-info-title;//$quinary;
			}
		}

		.list-icons{
			.svg-inline--fa{
				color: $theme-dark-list-icon-color;//rgb(172, 102, 102);
			}
		}

		a:link, a:visited {
			&:not(.btn-light){
				color: white;
				text-decoration: underline white;
			}
		}
		a:active, a:hover {
			&:not(.btn-light){
				color: darken(white,10);
				text-decoration: underline darken(white,10);
			}
		}

		
	}
}



