// VARIABLES
$campaign-primary: #00B3C5;
$campaign-secondary: #004D71;
$campaign-accent-1: #FFC40C; 
$campaign-accent-2: #f07522;
$campaign-gray-300: #F0F0F0;
$campaign-body: #002A3D;

$campaign-primary-light: #C7E8EA;


$mdVh: 70vh;
$lgVh: 80vh;
$xlVh: 90vh;


.wondersofwi{
	// RESET
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
	
	// TYPOGRAPHY 
	color: $campaign-body !important;
	font-size: 1.25rem;
	line-height: 1.5;
	p, h1, h2, h3, h4, h5, h6 {
	    color: $campaign-body;
	}
	h2, h3, h4 {
		margin-top: 0;
		line-height: 1;
	}
	h1 {
		line-height: .85;
	}
	h2 {
        text-transform: none;
		&.small {
			font-size: 3.5rem;
		}
	}
	ul{
		margin-top: 0;
	}

	.text-shadow {
        text-shadow: 0 3px 47px rgba(0, 0, 0, .68) !important;
    }
    .text-shadow-small {
        text-shadow: 0 3px 11px rgba(0, 0, 0, .68) !important;
    }

	.line-h-1{
		line-height: 1;
	}


	// RESPONSIVE FONT SIZE UTILITY CLASSES
	$font-sizes: (
        12: ( min: 12, max: 12 ),
        16: ( min: 12, max: 16 ),
        18: ( min: 16, max: 18 ),
        21: ( min: 21, max: 21 ),
		28: ( min: 22, max: 28 ),
		34-sm: ( min: 18, max: 34), // kickers
		34-md: ( min: 20, max: 34 ),
		34-lg: ( min: 22, max: 34 ),
		42: ( min: 34, max: 42 ),
		// 43: ( min: 24, max: 43 ),
		56: ( min: 38, max: 56 ),
		80: ( min: 56, max: 80 ),
        100: ( min: 42, max: 100 ),
		
	);
	// Create new `text-XX` classes where `XX` can match any key from the $font-sizes map
	// Usage: `<element class="text-24"></element>
	@each $size, $value in $font-sizes {
		.text-#{$size} {
			@include r-font-size(map-get(map-get($font-sizes, $size), min), map-get(map-get($font-sizes, $size), max));
		}
	}


	// BACKGROUND COLORS
    .bg-primary {
        background-color: $campaign-primary !important;
    }
    .bg-secondary {
        background-color: $campaign-secondary !important;
    }
    .bg-senary {
        background-color: $senary !important;
    }
	.bg-tertiary {
        background-color: $tertiary !important;
    }
	.bg-tertiary-light {
        background-color: lighten($tertiary, 10%) !important;
    }
    .bg-accent-1 {
        background-color: $campaign-accent-1 !important;
    }
    .bg-accent-2 {
        background-color: $campaign-accent-2 !important;
    }
	.text-campaign-body{
		color: $campaign-body;
	}


	.mini-nav{
		position: fixed;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		
		z-index: 999;
		transform: translateY(-100%);
		opacity: .05;
		transition: all 150ms ease-in-out;

		&-wrapper{
			height: 58px;
		}

		&-content{
			transition: all 90ms ease-in;
			// margin: 0 30px;

			// //@media screen and (min-width: map-get($grid-breakpoints, md)) {
			// @media screen and (min-width: 900) {
			// 	margin: unset;
			// }
		}
		
		&.active{
			transform: translateY(0);
			opacity: 1;
		}

		&-btn{
			cursor: pointer;
			span{
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				line-height: 1;
				display: inline-block
			}

			&.active,
			&:hover{
				span{
					border-bottom: 5px solid $campaign-accent-2;
				}
			}
			
		}

		&-prev,&-next{
			position: absolute;
			top: 0;
			display: block;
			z-index: 2;
			cursor: pointer;
			height: 100%;
			text-decoration: none;
			.fas{
				font-size: 1.25rem!important;
			}
			&.slick-disabled{
				opacity: .3;
			}
			
		}
		&-prev{
			left: -.65rem;
			background-image: linear-gradient(to right, $senary , transparent);
		}
		&-next{
			right: 0;
			background-image: linear-gradient(to left, $senary , transparent);
			.fas{
				margin-right: -8px;
			}
		}


	}

	.bg-gradient-top{
		&::after{
			content: '';
			display: block;
			width: 100%;
			height: 100px;
			position: absolute;
			top: 0;
			z-index: q;
			left: 0;
			background-image: linear-gradient($campaign-secondary, transparent);
			pointer-events: none;

			@media screen and (min-width: map-get($grid-breakpoints, md)) {
				display: none;
			}
		}
	}


	// HERO SECTION 
	$heroHei: 320px;
	.campaign-hero-section {
		min-height: $heroHei;
		max-height: 375px;
		position: relative;
		z-index: 1;
		img {
			width: 100%;
		}

		&:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 11px;
            height: 100%;
            content: " ";
            display: block;
            background: linear-gradient($campaign-accent-2 80%, $campaign-primary 20%);
            z-index: 2;
        }

		.campaign-hero-img {
			width: 100%;
			height: 100%;
			min-height: 320px;
    		object-fit: cover;
			// object-position: center bottom;  //Week Seven Update
			
			max-height: 375px;
			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				max-height: 465px;
			}
			@media screen and (min-width: map-get($grid-breakpoints, xl)) {
				max-height: 565px;
			}

			
		}

		.campaign-hero-logo {
			position: relative;
			z-index: 9;
			width: 235px;
			bottom: 1.5rem;

			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				bottom: 9.5rem;
			}			
			@media screen and (min-width: map-get($grid-breakpoints, xl)) {
				width: 435px;
				bottom: 8.5rem;
			}			
		}

		.campaign-hero-heading {
            bottom: 0;
            left: 50%;
            max-width: 1175px;
            position: absolute;
            transform: translateX(-50%);
            width: 100%;
            z-index: 2;

			.heading-logo{
				max-width: 500px;
				width: auto;
			}

			@media screen and (min-width: map-get($grid-breakpoints, xl)) {
				bottom: 2.5rem;
			}
		}

		@media screen and (min-width: map-get($grid-breakpoints, md)) {
			min-height: unset;
		}
		@media screen and (min-width: map-get($grid-breakpoints, lg)) {
			max-height: 465px;
		}
		@media screen and (min-width: map-get($grid-breakpoints, xl)) {
			max-height: 565px;

            &:before {
                width: 19px;
            }
        }
		.wonders-hero-section {
			min-height: $heroHei;
			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				min-height: 565px;
			}
		}
	}

	.gradient-overlay {
		position: relative;
		&:before {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			content: " ";
			display: block;
			background: linear-gradient(to top right, rgba(0,0,0,.8), transparent 55%);
			z-index: 1;
		}
	}
	.wonder-hero{
		//border: 1px solid blue;
		// min-height: 300px;
		// @media screen and (min-width: map-get($grid-breakpoints, lg)) {
		// 	min-height: 535px;
		// }

		.wonder-hero-title{
			//border: 1px solid pink;
			position: absolute;
			bottom: .75rem;
		}

		img {
			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				height: 650px;
				object-fit: cover;
			}			
		}
	}

	.wonder-explore{
		// min-height: 500px;
		.slick-list{
			overflow: visible;
			
		}

		.explore-content-wrapper,
		.explore-content-carousel{
			overflow: visible;
			@media screen and (min-width: map-get($grid-breakpoints, xl)) {
				overflow: hidden;
			}
		}

		.explore-content-carousel{
			&::after{
				content: '';
				display: block;
				width: 50px;
				height: 100%;
				position: absolute;
				top: 0;
				z-index: 88;
				right: 0;
				background-image: linear-gradient(to right, transparent , white);
				pointer-events: none;
			}
		}

		.content-carousel-nav{
			z-index: 99;
			pointer-events: none;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			.carousel-prev,
			.carousel-next{
				pointer-events: all;
				&.slick-disabled{
					opacity: .3;
				}
			}
			.carousel-prev{
				margin-left: .4rem;
			}
			.carousel-next{
				margin-right: .4rem;
			}
		}

		&-info{
			max-width: 700px;
		}
	}

	$imgGradientColor: black;
	.wonder-content{
		// min-height: 500px;
		.wonder-images{
			.wonder-image{
				position: relative;
				&::after{
					content: '';
					background: linear-gradient(transparent, $imgGradientColor);
					width: 100%;
					height: 50%;
					position: absolute;
					display: block;
					bottom: 0;
					opacity: .5;
				}
			}
		}
	}


	ul.wonder-features{
		list-style-type: none;
		li{
			flex: 1;
			&:not(:last-of-type){
				border-bottom: 1px solid $gray-400;
			}

			@media screen and (min-width: map-get($grid-breakpoints, md)) {
				&:not(:last-of-type){
					border-bottom: unset;
					border-right: 1px solid $gray-400;
				}
			}
		}	
	}

	.wonder-articles-wrapper{
		//border: 1px solid red;
		// min-height: 270px;
		.wonder-articles{
			//border: 1px solid blue;
			.wonder-article{
				&:visited, &:active, &:link{
					text-decoration: none;
				}
				&:hover{
					text-decoration: underline;
				}
			}
		}

		@media screen and (min-width: map-get($grid-breakpoints, md)) {
			//border: 1px solid purple;
			// min-height: 400px;
			.wonder-articles{
				margin: 0 -.5rem;
				.wonder-article{
					//border: 1px solid blue;
					margin: 0 .5rem;
					flex: 1;
				}
			}
		}
	}

	.wonder-slider{
		&:before {
            position: absolute;
            right: 0;
            top: 0;
			bottom: 0;
            width: 11px;
            height: auto;
            content: " ";
            display: block;
            background: linear-gradient($campaign-accent-2 80%, $senary 20%);
            z-index: 2;
        }
		&:after {
            position: absolute;
            right: 0;
			bottom: 0;
            width: 11px;
            height: 10%;
            content: " ";
            display: block;
            background: $campaign-primary-light;
            z-index: 3;
        }
	}

	.wonder-carousel-nav{
		bottom: 1rem;
		left: 50%;
		transform: translateX(-50%);
		.carousel-prev,
		.carousel-next{
			&.slick-disabled{
				opacity: .3;
			}
		}
	}

	.wonder-zoom{
		cursor: pointer;
		display: block;
		z-index: 9;
		bottom: .5rem;
		right: 1rem;
	}

	.explore-card-content {
		a {
			text-decoration: none !important;
		}
	}

	.signup-section {
		position: relative;
	
		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 24px;
			content: " ";
			display: block;
			background-color: $senary;
			z-index: 2;
		}
		&::after{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: " ";
			display: block;
			background-color: rgba(0,0,0,.2);
			z-index: 1;
		}
	
		img{
			min-height: 350px;
			max-height: 700px;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.content-card {
		transition: opacity 0.3s ease;
	}

	.color-bars{
		//border: 1px solid blue;
		position: absolute;
        z-index: 9;
        top: 0;
		width: .75rem;
        height: 100%;

		&--2{
			left: 0;
			background-color: $campaign-accent-1;

			&::after{
				content: '';
				position: absolute;
				display: block;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 20%;
				background-color: $campaign-primary;
				z-index: 5;
			}
		}

		&--3{
			right: 0;
			background-color: $campaign-accent-2;

			&::before{
				content: '';
				position: absolute;
				display: block;
				bottom: 10%;
				left: 0;
				width: 100%;
				height: 10%;
				background-color: $campaign-accent-1;
				z-index: 5;
			}

			&::after{
				content: '';
				position: absolute;
				display: block;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 10%;
				background-color: $campaign-primary-light;
				z-index: 5;
			}
		}

		&--title{
			top: unset;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 10px;
			display: flex;
			.color-bar{
				position: relative;
				&:nth-child(1){
					width: 85%;
				}
				&:nth-child(2){
					width: 15%;
				}
			}
		}
	}

	.overhang-el-top{
		margin-top: 100px;
		@media screen and (min-width: map-get($grid-breakpoints, md)) {
			margin-top: 50px;
		}
	}
	.overhang-el-bot{
		margin-top: -100px;
		@media screen and (min-width: map-get($grid-breakpoints, md)) {
			margin-top: -50px;
		}
	}

}

.gallery-modal-dialog{
	position: fixed!important;
	top: 50%!important;
	left: 50%!important;
	transform: translate(-50%, -50%)!important;
	padding: 0!important;

	.ui-widget-header{
		position: absolute;
		top: -35px;
		right: -35px;
		z-index: 9;
	}

	@media screen and (min-width: map-get($grid-breakpoints, md)) {
		height: $mdVh!important;
	}
	@media screen and (min-width: map-get($grid-breakpoints, lg)) {
		height: $lgVh!important;
	}
	@media screen and (min-width: map-get($grid-breakpoints, xl)) {
		height: $xlVh!important;
	}
}

//this gets popped out into a modal, outside of the main html
.wonder-images-modal{
	//background-color:  yellow;

	.wonder-carousel-nav{
		//border: 1px solid red;
		top: 40%;
		transform: translateY(-50%)!important;
		width: 100%;
		display: flex;
		pointer-events: none;

		.carousel-prev{
			pointer-events: all;
		}
		.carousel-next{
			margin-left: auto;
			pointer-events: all;
		}
	}

	.slick-list{
		padding: 0!important;
	}

	//max-height: unset;

	// @media screen and (min-width: map-get($grid-breakpoints, md)) {
	// 	//width: 100%;
	// 	width: auto;
	// 	height: $mdVh;

	// 	.slick-list,
	// 	.slick-track {
	// 		height: $mdVh;
	// 	}

	// 	.slick-slide {
	// 		width: auto;
	// 		height: $mdVh;
	// 		opacity: 0;
	// 		transition: opacity 50ms ease-in;
	
	// 		&.slick-current{
	// 			opacity: 1;
	// 		}
	// 	}
	// }

	
	// @media screen and (min-width: map-get($grid-breakpoints, lg)) {
	// 	height: $lgVh;

	// 	.slick-list,
	// 	.slick-track {
	// 		height: $lgVh;
	// 	}

	// 	.slick-slide {
	// 		height: $lgVh;
	// 	}
	// }
	// @media screen and (min-width: map-get($grid-breakpoints, lg)) {
	// 	height: $xlVh;

	// 	.slick-list,
	// 	.slick-track {
	// 		height: $xlVh;
	// 	}

	// 	.slick-slide {
	// 		height: $xlVh;
	// 	}
	// }

}

