// VARIABLES
$campaign-primary: #00B3C5;
$campaign-secondary: #004D71;
$campaign-accent-1: #FFC40C; 
$campaign-accent-2: #f07522;
$campaign-gray-300: #F0F0F0;
$campaign-body: #002A3D;
$campaign-accessible-orange: #EF6F1A;

$campaign-primary-light: #C7E8EA;
$campaign-gray-500: #D9D9D9;


$mdVh: 70vh;
$lgVh: 80vh;
$xlVh: 90vh;


.nfl-draft{
	box-sizing: border-box;
	* {
		box-sizing: border-box;
	}
	
	// TYPOGRAPHY 
	color: $campaign-body !important;
	font-size: 1.125rem;
	line-height: 1.5;
	p, h1, h2, h3, h4, h5, h6 {
	    color: $campaign-body;
	}
	h2, h3, h4 {
		margin-top: 0;
		line-height: 1;
	}
	h1 {
		line-height: .85;
	}
	h2 {
        text-transform: none;
		&.small {
			font-size: 3.5rem;
		}
	}
	ul{
		margin-top: 0;
	}

	.text-shadow {
        text-shadow: 0 3px 47px rgba(0, 0, 0, .68) !important;
    }
    .text-shadow-small {
        text-shadow: 0 3px 11px rgba(0, 0, 0, .68) !important;
    }

	.line-h-1{
		line-height: 1;
	}

    .mw-700 {
        max-width: calcRem(700);
    }

    .mw-975 {
        max-width: calcRem(975);
    }

    .mw-1400 {
        max-width: calcRem(1400);
    }

    .opacity-60 {
        opacity: .60;
    }

	.lh-32-sm {
		line-height: 32px;
		@include media-breakpoint-up(md) {
			line-height: .85;
		}		
	}
    .bg-lightBlueGray {
        background-color: $lightBlueGray;
    }

    .border-left-dashed {
        border-left: 1px dashed $black;
    }

    .border-campaign-gray-500 {
        border-color: $campaign-gray-500 !important;
    }
	.contain {
		object-fit: contain;
	}

	.mh-650 {
		max-height: 650px;
	}
	
	// RESPONSIVE FONT SIZE UTILITY CLASSES
	$font-sizes: (
        12: ( min: 12, max: 12 ),
        16: ( min: 12, max: 16 ),
        18: ( min: 16, max: 18 ),
        20: ( min: 20, max: 20 ),
        21: ( min: 21, max: 21 ),
		24-sm: ( min: 18, max: 24),
		28: ( min: 22, max: 28 ),
		32: ( min: 18, max: 32 ),
		34-sm: ( min: 18, max: 34), // kickers
		34-md: ( min: 20, max: 34 ),
		34-lg: ( min: 22, max: 34 ),
		36: ( min: 32, max: 36 ),
		40: ( min: 32, max: 40 ),
		42: ( min: 34, max: 42 ),
		// 43: ( min: 24, max: 43 ),
		48: ( min: 32, max: 48 ),
		56-sm: ( min: 24, max: 56 ),
		56: ( min: 42, max: 56 ),
		76: ( min: 42, max: 76 ),
		80: ( min: 56, max: 80 ),
        100: ( min: 42, max: 100 ),
		
	);
	// Create new `text-XX` classes where `XX` can match any key from the $font-sizes map
	// Usage: `<element class="text-24"></element>
	@each $size, $value in $font-sizes {
		.text-#{$size} {
			@include r-font-size(map-get(map-get($font-sizes, $size), min), map-get(map-get($font-sizes, $size), max));
		}
	}

    .text-xxl-start {
        @media screen and (min-width: map-get($grid-breakpoints, xxl)) {
            text-align: start!important;
        }             
    }

    .accent-orange-before {
        position: relative;

		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 14px;
			content: " ";
			display: block;
			background-color: $campaign-accessible-orange;
			z-index: 2;

			@media screen and (min-width: map-get($grid-breakpoints, xxl)) {
                width: 18px;
                height: 100%;
			}            
		}        
    }
    .accent-senary-before {
        position: relative;

		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 14px;
			content: " ";
			display: block;
			background-color: $senary;
			z-index: 2;

			@media screen and (min-width: map-get($grid-breakpoints, xxl)) {
                width: 18px;
                height: 100%;
			}            
		}        
    }
    .accent-orange-before-nomobile {
        position: relative;

		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 18px;
			height: 100%;
			content: " ";
			display: block;
			background-color: $campaign-accessible-orange;
			z-index: 2;       
		}        
    }
    .accent-senary-before-nomobile {
        position: relative;

		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 18px;
			height: 100%;
			content: " ";
			display: block;
			background-color: $senary;
			z-index: 2;       
		}        
    }

	.accent-top-before{
        position: relative;

		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 26px;
			content: " ";
			display: block;
			background: linear-gradient(to right, $campaign-accessible-orange 20%, $secondary 20%);
			z-index: 2;
		}        
    }

	.accent-top-before-1{
        position: relative;

		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 40px;
			content: " ";
			display: block;
			background: linear-gradient(to right, $tertiary 0%, $tertiary 80%, $senary 80%, $senary 90%, $campaign-accessible-orange 90%, $campaign-accessible-orange 100%);
			z-index: 2;
		}        
    }

    .accent-senery-after {
        position: relative;

		&::before{
			position: absolute;
			right: 0;
			top: 0;
            width: 16px;
            height: 100%;
			content: " ";
			display: block;
			background-color: $senary;
			z-index: 2;        
		}        
    }

	.accent-border-left {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: 100%;
			left: 0;
			top: 0;
			width: 16px;
			background: linear-gradient($senary 0, $senary 80%, $quaternary 80%, $quaternary 90%, $tertiary 90%, $tertiary 100%);
		}
	}    

    .accent-border-left-1 {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: 100%;
			left: 0;
			top: 0;
			width: 16px;
			background: linear-gradient($senary 0, $senary 15%, $quaternary 15%, $quaternary 100%);
		}
	}    

    .accent-border-left-2 {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: 100%;
			left: 0;
			top: 0;
			width: 16px;
			background: linear-gradient($tertiary 0, $tertiary 15%, $quaternary 15%, $quaternary 100%);
		}
	}    

	.accent-border-left-3 {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: 100%;
			left: 0;
			top: 0;
			width: 16px;
			background: linear-gradient($quaternary 0, $quaternary 80%, $senary 80%, $senary 100%);
		}
	}    

    .accent-border-right {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: 100%;
			right: 0;
			top: 0;
			width: 16px;
			background: linear-gradient($secondary 0, $secondary 85%, $senary 85%, $senary 100%);
		}
	}    

    .accent-border-right-1 {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: 100%;
			right: 0;
			top: 0;
			width: 16px;
			background: linear-gradient($secondary 0, $secondary 85%, $tertiary 85%, $tertiary 100%);
		}
	}    

	.tertiary-overlay-border {
		background-color: $tertiary;
		height: calcRem(24);
		left: 0;
		opacity: .64;
		position: absolute;
		bottom: 0;
		width: 100%;
		@include media-breakpoint-up(lg) {
			height: calcRem(16);
			left: auto;
			right: 0;
			bottom: 0;
			width: 100%
		}
	}

    .senary-overlay-border {
		background-color: $senary;
		height: calcRem(24);
		left: 0;
		opacity: .64;
		position: absolute;
		bottom: 0;
		width: 100%;
		@include media-breakpoint-up(lg) {
			height: calcRem(16);
			left: auto;
			right: 0;
			bottom: 0;
			width: 100%
		}
	}

    .secondary-overlay-border {
		background-color: $secondary;
		height: calcRem(24);
		left: 0;
		opacity: .64;
		position: absolute;
		bottom: 0;
		width: 100%;
		@include media-breakpoint-up(lg) {
			height: calcRem(16);
			left: auto;
			right: 0;
			bottom: 0;
			width: 100%
		}
	}

	.image-gradient-overlay {
		position: relative;
		&:before {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			content: " ";
			display: block;
			background: linear-gradient(359.36deg, rgba(0, 0, 0, 0.7) 0.7%, rgba(0, 0, 0, 0) 80.9%);
			z-index: 1;
		}
	}

	.image-gradient-overlay-sm {
		position: relative;
		&:before {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			content: " ";
			display: block;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
			z-index: 1;
		}
	}

	// HERO SECTION 
	$heroHei: 320px;

	.hero-video {
		max-height: 575px;
		height: 100%;
		position: relative;
		z-index: 1;
		aspect-ratio: 16 / 9;
		width: 100%;
		
		@include media-breakpoint-up(md) {
			min-height: unset;
		}
		@include media-breakpoint-up(lg) {
			max-height: 500px;
		}
		@include media-breakpoint-up(xl) {
			max-height: 750px;
		}
		@media (min-width: 1800px) {
			max-height: 950px;
		}
	}
	
	.campaign-hero-section {
		min-height: $heroHei;
		max-height: 375px;
		position: relative;
		z-index: 1;
		img {
			width: 100%;
		}

		.campaign-hero-img {
			width: 100%;
			height: 100%;
			min-height: 320px;
    		object-fit: cover;
			
			max-height: 375px;
			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				max-height: 465px;
			}
			@media screen and (min-width: map-get($grid-breakpoints, xl)) {
				max-height: 565px;
			}			
		}

		@media screen and (min-width: map-get($grid-breakpoints, md)) {
			min-height: unset;
		}
		@media screen and (min-width: map-get($grid-breakpoints, lg)) {
			max-height: 465px;
		}
		@media screen and (min-width: map-get($grid-breakpoints, xl)) {
			max-height: 565px;
        }
		.nfl-hero-section {
			min-height: $heroHei;
		}

		.hero-video-logo {
			width: 165px;
			@media screen and (min-width: map-get($grid-breakpoints, xl)) {
				width: 335px;
			}			
		}

		.draft-logos {
			width: 200px;
		}		
	}

    .draft-countdown {
        .countdown-card {
            width: 85px;
            height: 80px;

			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
                width: 125px;
                height: 95px;
			}
        }
    }
	.draft-logos {
		width: 200px;
	}
	.draft-hero-video {
		// max-height: 375px;
		height: 450px;
		position: relative;
		z-index: 1;
		
		@include media-breakpoint-up(md) {
			min-height: unset;
		}
		@include media-breakpoint-up(lg) {
            height: 100%;
			max-height: 500px;
		}
		@include media-breakpoint-up(xl) {
			max-height: 700px;
		}
	}

    .campaign-hero-logo {
        width: 175px;
		@include media-breakpoint-up(lg) {
            width: 240px;
		}        
    }

    .slider-image-container {
        position: absolute;
        top: -8rem;
        right: 0;
        width: 300px;
        z-index: 10;

		@include media-breakpoint-up(lg) {
            position: relative;
            width: auto;
            top: -10rem;
		}
    }

    .slider-negative-top-position {
        margin-top: -6rem;
        position: relative;
        z-index: 10;
		@include media-breakpoint-up(lg) {
            margin-top: -15rem;
		}        
    }

    .slider-negative-top-position-sm {
        margin-top: -10rem;
        position: relative;
        z-index: 10;
    }

	.circle-nav-button {
		align-items: center;
		border-radius: 50%;
		box-shadow: 0 5px 14px rgba(0,0,0,.2);
		display: flex;
		height: calcRem(60);
		justify-content: center;
		width: calcRem(60);
		&.slick-disabled {
			pointer-events: none;
			opacity: .5;
		}
	}

	.circle-nav-button-sm {
		align-items: center;
		border-radius: 50%;
		display: flex;
		height: calcRem(44);
		justify-content: center;
		width: calcRem(44);
		&.slick-disabled {
			pointer-events: none;
			opacity: .5;
		}
	}

    .wonders-slide-height, .htbh-slide-height {
        height: 375px;
    }

	.culinary-hero-section {
		min-height: $heroHei;
		max-height: 375px;
		position: relative;
		z-index: 1;
		img {
			width: 100%;
		}

		.culinary-hero-img {
			width: 100%;
			height: 100%;
			min-height: 320px;
    		object-fit: cover;
			
			max-height: 375px;
			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				max-height: 465px;
			}		
		}

		@media screen and (min-width: map-get($grid-breakpoints, md)) {
			min-height: unset;
		}
		@media screen and (min-width: map-get($grid-breakpoints, lg)) {
			max-height: 465px;
		}
	}

	.htbh-play-button {
		background-color: $white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		height: 3rem;
		justify-content: center;
		width: 3rem;
		color: $quaternary;
	}    
	.football-fanatics {
		.photo-credit {
			bottom: 2rem;
		}
		.container {
			max-width: 1500px;
		}
		@include media-breakpoint-up(lg) {
			.col-lg-3-sm {
				flex: 0 0 23%;
				max-width: 23%;			
			}
			.col-lg-3-lg {
				flex: 0 0 30%;
				max-width: 30%;			
			}
		}		
		.photo-credit__icon-container {
			height: 32px;
			width: 32px;
		}		
	}
	.photo-credit__icon-container {
		background: #F5F8FA24;
		backdrop-filter: blur(18px);
		.fa-secondary {
			fill: #F5F8FA24;
			opacity: 1;
		}
	}

	.signup-section {
		position: relative;
	
		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 24px;
			content: " ";
			display: block;
			background-color: $tertiary;
			z-index: 2;
		}
		&::after{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: " ";
			display: block;
			background-color: rgba(0,0,0,.2);
			z-index: 1;
		}
	
		img{
			min-height: 400px;
			max-height: 700px;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	.collapse-content {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-in-out;
	}
	
	.collapse-content.open {
		max-height: 180px;
	}	
}

.modal-close-btn {
    position: sticky;
	background: $light;
	width: 40px;
	height: 40px;
	border-radius: 50%;
    border: 0;
    top: 0;
	left: 100%;

	&:hover{
		opacity: .8;
	}
}