
.account{
    //border: 1px solid green;
    &[data-active="dashboard"] {
        .account-dashboard{
            display: none;
        }
        .account-profile{
            display: flex;
        }
    }
    &[data-active="profile"] {
        .account-dashboard{
            display: flex;
        }
        .account-profile{
            display: none;
        }
    }
}


