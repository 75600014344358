/**
    Preheader section above main header
    - jk moved below both navs 2023-07-10
*/


.stickyhead{
    //border: 1px solid blue;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    //position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    background-color: $sh-color-bkgnd;

    &:after{
        @extend .clearfix;
    }
}



.stickyhead__info{
    //border: 1px solid blue;
    position: relative;
    display: block;
    z-index: 1;
    margin: .4rem auto .4rem;
    width: 95%;
    height: auto;
    text-align: center;
    @media only screen and (min-width: $mq-md) {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}




.stickyhead__info__text{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;

    font-size: 1.125rem;
    line-height: 1.35em;
    font-weight: 300;
    color: $primary;
    text-align: center;
    //font-family: $font-family-base;

    b{
        font-weight: 700;
    }

    @media only screen and (min-width: $mq-md) {
        display: inline-block;
        //font-size: 16px;
    }

    a:link, a:visited,
    a:hover, a:active{
        text-decoration: underline;
        color: $primary;
    }
}

.stickyhead__info__link{
    position: relative;
    display: inline-block;
    margin: .25rem 0 0;
    padding: 5px 10px;
    width: auto;
    font-weight: 300;
    text-transform: capitalize;
    //background-color: $sh-color-btn-bkgnd;
    line-height: 1.35em;
    //color: $primary;
    text-align: center;

    @media only screen and (min-width: $mq-md) {
        margin: 0 0 0 .5rem;
    }
}



.stickyhead__close {
    position: absolute;
    //padding-top: 8px;
    z-index: 9;
    top: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    background-color: $primary;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    span {
        font-size: 1.5em;
        color: white;
        text-align: center;
        text-transform: lowercase;
    }
}

// .stickyhead-spacer{
//     border: 1px solid red;
//     position: relative;
//     width: 100%;
//     height: auto;
//     min-height: 49px;
// }