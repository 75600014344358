  /* Contain floats: h5bp.com/q */
  .clearfix:before, .clearfix:after { content: ""; display: table; }
  .clearfix:after { clear: both; }
  .clearfix { *zoom: 1; }

  %clearfix { 
    *zoom: 1;
    &:before, &:after { content: ""; display: table; }
    &:after { clear: both; } 
  } 
