.modal {
	background-color: #fff;
	box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
	overflow: auto;
	border-radius: .5rem;
	padding: 3rem;
	top: 5% !important;
	height: 85% !important;

	.modal__section {
		input[type=text], textarea {
			width: 100%;
		}
	}
	label {
		display: inline;
	}
	
	.modal__section--half {
		width: 45%;
		float: left;
		box-sizing: border-box;
	}
	
	.modal__text-label {
		display: block;
	}
	
	.modal__text-input > input, .modal__text-input > textarea {
		width: 100%;
		box-sizing: border-box;
	}

	.ui-dialog-titlebar-close {
		position: absolute;
		top: 0;
		right: 0;	
		padding: 3rem;
	}
}