

.directory{
    .directory-search{
        &::before{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 16px;
            content: " ";
            display: block;
            background: linear-gradient(to left, $tertiary 80%, $quaternary 20%);
            z-index: 2;
        }
    }
}


.directory-wrapper{
    background-color: $gray-100;
    padding: 3rem 0;
    position: relative;
    &::after{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 16px;
        content: " ";
        display: block;
        background: linear-gradient(to left, $tertiary 80%, $quaternary 20%);
        z-index: 2;
    }

    @include media-breakpoint-up(md) {
        padding: 5rem 0;
    }
}