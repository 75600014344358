.button__icon {
	// display: inline-block;
	// vertical-align: middle;
	// margin: 0 5px;
	// height: 100%;
	// background-position: center center;
	// background-repeat: no-repeat;
	// .trip-planner__button & {
	// 	//to make up for button padding
	// 	margin-top: -4px;
	// }
	// .button & {
	// 	height: 100%;
	// }
}

.button__icon--share {
    border: 1px solid red;

    &:before{
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome Kit";
        font: var(--fa-font-solid);
        content: "\f007";
		// font-family: "Font Awesome 5 Pro"; 
		// content: "\f0c8";
		// position: absolute;
		// top: 6px;
		// display: inline-block;
		// font-size: 1rem;
		// color: $gray-600;
	}

	// width: 27px;
	// height: 20px;
	// background-image: url('/Images/trip-planner/share.png');
}

// .button__icon--light-share {
// 	width: 19px;
// 	height: 14px;
// 	background-image: url('/Images/trip-planner/share--white.png');
// }