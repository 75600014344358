.filter-bar {
	box-sizing: border-box;
	// width: 100%;
	// background-color: #fff;
	// padding: 15px;
	// @media screen and (min-width: $mq-mobile-landscape) {
	// 	text-align: center;
	// 	padding: 0;
	// }

	&-content{
		&::before{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 11px;
			content: " ";
			display: block;
			background: linear-gradient(to left, $tertiary 80%, $quaternary 20%);
			z-index: 2;
		}
	}
}

.filter-bar__input {
	// display: block;
	// width: 90%;
	// height: 60px;
	// line-height: 60px;
	// padding: 0 20px;
	// margin: 10px auto;
	// @media screen and (min-width: $mq-tablet-landscape) {
	// 	display: inline-block;
	// 	margin: 20px;
	// 	width: 260px;
	// 	height: 40px;
	// 	line-height: 40px;
	// }
}

.filter-bar__title {
	// display: inline-block;
	// margin-right: 20px;
}