

.submission__section {
	width: 100%;
	box-sizing: border-box;
	padding-bottom: 20px;
	@media screen and (min-width: $mq-tablet-portrait){
		width: 50%;
		float: left;
		padding-right: 10px;
		& + .submission__section {
			padding-right: 0;
			padding-left: 10px;
		}
	}
	.field-validation-error {
		color: $danger;
	}
	.input-validation-error {
		border: 1px solid $danger;
	}

	&.btn-labels{
		label{
			background-color: $quaternary;
			width: 40%;
			padding: .25rem .5rem;
			color: white;
			font-size: .7rem;

			@media screen and (min-width: $mq-tablet-portrait){
				font-size: 1rem;
				padding: .5rem 1rem;
				width: 30%;
			}

			&.label-left{
				margin-right: 1rem;
			}
			&.label-right{
				margin-left: 1rem;
			}
		}
	}
}

.submission__section--wide {
	width: 100%;
	padding-right: 0;
}

.submission__label {
	display: block;
}

.submission__input {
	width: 100%;
	input, textarea, select {
		box-sizing: border-box;
		width: 100%;
		//padding: 20px 10px;
		//border: none;
	}
}

.submission__input--outline {
	input {
		border: 1px solid #b3b3b3;
    	border-radius: 3px;
	}
}

.submission__key {
	font-size: .9em;
	font-style: italic;
}



.email-newsletters{

	$roundedCorners: 5px;

	.signup__form{
		input[type=submit]{
			width: 150px;
			background-color: $primary;
			color: white;
			// border-top-right-radius: $roundedCorners;
			// border-bottom-right-radius: $roundedCorners;
		}
	}

	.button--bevel{
		&[disabled],
		&.disabled{
			background-color: gray;
			box-shadow: 1px 2px 0px 2px darken(gray, 15%);
		}
	}

	.checkbox-interests{
		label{
			display: block;
			margin-bottom: .5rem;
		}
	}

	.step1-group{
		//border: 1px solid green;
		max-width: 500px;
		display:flex;
		margin: 0 auto 2rem;

		@media screen and (min-width: $mq-ts){
			//border: 1px solid red;
			min-width: 500px;
		}

	}

	.checkbox-group{
		//border: 1px solid green;
		display: block;
		max-height: 600px;
		height:auto;
	
		&.closed{
			//border: 1px solid red;
			max-height: 0px;
			overflow: hidden;
		}

		transition: max-height 300ms ease-out;
	}
}