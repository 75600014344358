// HERO SECTION 
$heroHei: 320px;
$campaign-primary: #004D71;
.callout-hero-section {
    // min-height: $heroHei;
    // max-height: 375px;
    position: relative;
    z-index: 1;
    .callout-hero-logo {
        width: 235px;
        @media screen and (min-width: map-get($grid-breakpoints, lg)) {
            width: 290px;
        }        
    }
    img {
        width: 100%;
    }

    .callout-hero-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
        min-height: 325px;
        max-height: 375px;
        @media screen and (min-width: map-get($grid-breakpoints, lg)) {
            max-height: 465px;
        }
        @media screen and (min-width: map-get($grid-breakpoints, xl)) {
            max-height: 565px;
        }
    }
	.hero-video {
        min-height: 300px;
	}
    .callout-hero-heading {
        //border: 1px solid orange;
        top: 48%;
        //bottom: 2rem;
        left: 50%;
        //max-width: 500px;
        width: 90%;
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 2;

        @media screen and (min-width: map-get($grid-breakpoints, lg)) {
            width: 75%;
        }
    }
	.senary-overlay-border {
		background-color: $senary;
		height: calcRem(24);
		left: 0;
		opacity: .64;
		position: absolute;
		bottom: 0;
		width: 100%;
		@include media-breakpoint-up(lg) {
			height: 100%;
			top: 0;
			width: calcRem(16);
		}
	}
    a {
        line-height: 30px;
    }

    &::before{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 16px;
		content: " ";
		display: block;
		background: linear-gradient(to right, $campaign-primary 0, $campaign-primary 80%, $tertiary 80%, $tertiary 90%, $quaternary 90%, $quaternary 100%);
		z-index: 2;
        
		@include media-breakpoint-up(lg) {
            background: linear-gradient($campaign-primary 0, $campaign-primary 80%, $tertiary 80%, $tertiary 90%, $quaternary 90%, $quaternary 100%);        
            left: 0;
            bottom: 0;
            width: 16px;
            height: 100%;
		}        
	}

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
        min-height: unset;
    }
    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
        max-height: 500px;
    }
    @media screen and (min-width: map-get($grid-breakpoints, xl)) {
        max-height: 620px;
    }

}