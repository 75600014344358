///*-------------------------------------------------------------------------- 
//  Global search form styles
//--------------------------------------------------------------------------*/
.global-nav-form{
    //border: 1px solid pink;
	position    : relative;
    padding: 0;//15px;
    *{
        box-sizing: border-box;
    }
    // @include media-breakpoint-up(xl) {
    //     body:not(.force-mobile) & {
    //         float: right;
    //         width: 34%;
    //         padding: 0;//7px 0;
    //     }
    // }
    &.js-loading{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                display: none;
            }

        }
    } 
    .customStyleSelectBox{
        border : 1px solid $midNeutral;
        padding: 0;
        padding-left: 10px;
        background: none;
        background-color: $reverseOut;
        border-radius: 3px;
        width: 100%;
    }
    .customStyleSelectBoxInner{
        padding: 11px 0;
        width: 100% !important;
        background: url(/images/public/nav-arrow.png) no-repeat center right;
    }	
}

.global-nav-form__1,
.global-nav-form__2,
.global-nav-form__3{
	position: relative;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            float: left;
        }
    }
    
}
.global-nav-form__1{
    margin-bottom: 10px;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            width: 47%;
            padding-right: 10px;
            margin-bottom: 0;
        }
    }
    
}
.global-nav-form__2{
    margin-bottom: 10px;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            width: 30%;
            padding-right: 10px;
            margin-bottom: 0;
        }
    }
}
.global-nav-form__3{
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            width: 23%;
        }
    }
}

.global-nav-form__input{
	border      : 1px solid $midNeutral;
	padding  : 12px;
    line-height: 1;
    width: 100%;
	border-radius: 3px;
}
.global-nav-form__select{
	background: $reverseOut;
    border      : 1px solid $midNeutral;
    width: 100%;
    padding  : 0; 
    height: 41px;
    box-sizing: border-box;
    padding-left: 10px;
}  
.global-nav-form__btn{
	background: $primary;
	border: none;
	color: #fff;
	padding  : 15px;
    line-height: 1;
    width: 100%;
	border-radius: 4px;
}