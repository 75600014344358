
.global-header{
  //padding-top     :  8px;
  padding-bottom  :  0;
  position        :  relative;
  z-index         :  5;
  //@include background-image(linear-gradient($transparentPrimary, $primary));
  background-color: $secondary;
  box-shadow: 0px 0px 7px $darkNeutral;
  @include media-breakpoint-up(xl) {
    body:not(.force-mobile) & {
        //padding-top: 3px;
        padding-bottom: 0;
    }
  }
  .icon-location-grey{
      @extend .icon-location-white !optional;
      @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
          background-image: url(/images/public/icons/icon-location-grey.png);
          background-image: url(/images/public/icons/icon-location-grey.svg), none;
        }
      }
      &.locationFound{
          @extend .icon-locationactive-white !optional;
      }
  }
  @media print {
	display: none;
  }
}
.utility-menu__wrapper,
.global-header__wrapper{
    max-width: $mq-desktop-sm;
    margin: 0 auto;
}

.utility-menu__wrapper{
    @include media-breakpoint-up(xl) {
        min-height: 60px;
    }
}

.global-header__wrapper {
	padding-top: 8px;
	@include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            padding-top: 3px;
        }
	}
}

.global-header__content{
    //text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            display: inline-block;
        }
    }
}
.global-header__logo{
    
    text-decoration: none;
    color: $reverseOut;
    margin: 10px 0 10px 10px;
	@include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            margin-top: 20px;
            margin-right: 10px;
            float: left;
        }
	}
}

.global-header__title {
    padding: 0 15px;
    color: #fff;
    font-size: 1.1em;
    @include media-breakpoint-up(xl) {
        font-size: 1.5em;
    }
}

.global-header__logo--solo {
	margin: 25px auto 20px !important;
	float: none !important;
	display: block;
	width: 134px;
}
.global-header__location-icon{
    display: inline-block;
    padding: 12px 10px;
    vertical-align: text-top;
    .setLocation{
        @extend .sr-only;
    }
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            display: none;
        }
    }
}
.global-header__mobile-btn{
    background: none;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: none;
    box-shadow: none;
    display: inline-block;
    vertical-align: text-top;
    padding: 12px 10px;
    &:focus{
        outline: none;
    }
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            display: none;
        }
    }
}

.lt-ie9 header .wrapper .brandingSpace .fontLoadingFix {display:none;}
.foutFix {
  display: none !important;
}


.lt-ie9 .favCountFix {
  font-family: "Helvetica", "Arial", "sans-serif";
  font-weight: bold;
  font-size:0.5em;
  color:$reverseOut; 
  position:absolute;
  margin-left:-17px;
  top:6px;
} 


.global-header{
    .color-bars{
        position: absolute;
        z-index: 9;
        display: flex;
        top: 0;
        left: 0;
        height: 100%;
    
        .color-bar{
            position: relative;
            width: auto;
            height: 100%;
    
            &.yellow{
                width: 5px;
                background-color: $senary;
            }
            &.blue{
                width: 9px;
                background-color: $tertiary;
            }

            @media screen and (min-width: 1300px){
                &.yellow{
                    width: 10px;
                }
                &.blue{
                    width: 30px;
                }
            }

            @media screen and (min-width: $mq-desktop-lg){
                &.yellow{
                    width: 40px;
                }
                &.blue{
                    width: 120px;
                }
            }
        }
    }
}