.directory-search {
	box-sizing: border-box;
	padding: 1px 20px 20px 20px;
	width: 100%;
	@media screen and (min-width:$mq-tablet-portrait){
		display: table;
	}

	.--accessibility &{
		@media screen and (min-width:$mq-tablet-portrait){
			display: block;
		}
		// @media screen and (min-width:$mq-tablet-landscape){
		// 	display: flex;
		// 	justify-content: space-between;
		// }
	}
}

// .directory-search--simple {
// 	text-align: center;
// 	@media screen and (min-width:$mq-tablet-portrait){
// 		display: block;
// 	}
// }
// .directory-search--shaded {
// 	background-color: $neutral;
// 	padding: 20px;
// }

.directory-search--transparent {
	padding: 10px;
	background-color: rgba(255,255,255,.7);
}
.directory-search__input {
	box-sizing: border-box;
	background: #fff;
	border: 1px solid #ccc;
	border-radius: $btnBorderRadius;
	font-size: 0.8125em;
	margin: 10px 0;
	padding: 7px;
	width: 100%;
	display: block;
	@media screen and (min-width: $mq-tablet-landscape) {
		max-width: 140px;
		margin: 5px 0;
		display: inline-block;
	}
}

// //because of iPhone zoom issue
// .directory-search__input--larger {
// 	font-size: 1em;
// }

.directory-search__input--block {
	@media screen and (min-width: $mq-tablet-landscape) {
		display: block;
		max-width: none;
		margin: 0;
	}
	& ~ & {
		@media screen and (min-width: $mq-tablet-landscape) {
			margin: 30px 0 0;
			
		}
	}
	.tight & ~ & {
		@media screen and (min-width: $mq-tablet-landscape) {
			margin: 10px 0 0;
			
		}
	}
}

.directory-search__input--datepicker {
	background-image: url(/images/public/calendar.png);
	background-position: right center;
	background-repeat: no-repeat;
}

.directory-column {
	//border: 1px solid pink;

	display: block;
	padding: 0 5px;
	@media screen and (min-width:$mq-tablet-landscape){
		display: table-cell;
		vertical-align: middle;
		padding: 5px;
		&.v-top{
			vertical-align: top;
		}
	}

	.--accessibility &{
		@media screen and (min-width:$mq-tablet-portrait){
			display: block;
		}
		@media screen and (min-width:$mq-tablet-landscape){
			display: flex;
			flex-direction: column;
		}
	}
}

// .directory-column--pagination {
// 	@media screen and (min-width:$mq-tablet-landscape){
// 		width: auto;//310px;
// 		max-width: 310px;
// 	}
// 	.pagination {
// 		text-align: left;
// 	}
// }

.directory-column--buttons {
	box-sizing: border-box;
	/* So as not to assume what the initial value was */
	@media screen and (max-width:$mq-tablet-landscape){
		.button {
			margin-right: 10px;
			margin-bottom: 10px;
		}
		.button--filter {
			width: 45%;
		}
	}
	@media screen and (min-width:$mq-tablet-landscape){
		padding-left: 10px;
		padding-right: 10px;
		width: 180px;
		vertical-align: bottom;
		.button {
			width: 100%;
		}
		.button--filter:first-child {
			margin-bottom: 25px;
		}
	}
	
}

// .directory-column___heading {
// 	font-size: 0.8125em;
// 	font-weight: bold;
// 	color: #666;
// }

// .directory-search__size {
// 	//font-size: 1.1em;
// 	select{
// 		color: $tertiary;
// 	}
// }

// .directory-search-heading {
// 	font-size: 2em;
// 	font-family: $accentFont;
// 	font-weight: 700;
// 	margin: 10px 15px;
// 	@media screen and (min-width:$mq-tablet-landscape){
// 		font-size: 2.4em;
// 		margin: 5px 0;
// 	}
// }

// .directory-search-location {
// 	font-weight: bold;
// }

// .directory-search__results {
// 	color: #666;
// 	font-size: .85em;
// 	margin-bottom: 20px;
// 	margin-left: 15px;
// 	@media screen and (min-width:$mq-tablet-landscape){
// 		margin-left: 0;
// 	}
// }

// .directory-search__results-label {
// 	font-weight: bold;
// }

// .directory__heading {
// 	font-family: $accentFont;
// 	font-weight: 700;
// 		font-size: 1.625em;
// 		margin-right: 10px;
// 	margin-left: 10px;
// 	display: inline-block;
// 	vertical-align: middle;
// }

// //Legacy code for "more filters"

.directoryGrid__filters-attributes{
	margin: 0 -.5rem;

// .directoryGrid__filters-attributes {
// 	text-align: left;
// 		@media screen and (max-width:$mq-tablet-portrait){
// 			width:83%;
// 			margin: auto;
// 		}

// 		@media screen and (max-width:$mq-mobile-landscape){
// 			ul {
// 				padding-left: 10px;
// 			}
// 		}

// 		margin-bottom: 1em;

		.directoryGrid__filters-attributes--header{
			width: 100%;
			clear: both;
			padding: 1em 0 0 0;
			position: relative;
			margin: 0 .25rem;
			//display: inline-block;
			display: block;
			left: 0;
		}

// 		clear: both;
// 		padding: 1em 15px;
// 		color: $secondary;
// 		background-color:#FFF;
// 		ul{
// 			list-style-type: none;
// 		}
// 	}

 

	.directoryGrid__checkbox {
		//font-size: 0.9em;
		@include r-font-size(14,18);
		width: 100%;
		//margin: 10px 0 10px 10px;
		margin: 0 .25rem .5rem;
		text-align: center;
		// @media screen and (max-width:$mq-mobile-landscape){
		// 	width: 45%;
		// 	//font-size: 0.7em;
		// }
		// @media screen and (min-width:$mq-mobile-landscape) and (max-width:$mq-tablet-portrait){
		// 	width: 31.0%;
		// 	//font-size: 0.8em;
		// }
		@include media-breakpoint-up(md) {
			width: calc(33.33% - .5rem);
		}
		position: relative;
		//height: 3.2em;
		height: auto;
		
		background-color: $gray-500;
		//display: inline-block;
		border-radius: 7px;
		display: flex;
		align-items: center;
    	justify-content: center;
	
		// &:before {
		// 	content: ' ';
		// 	height: 100%;
		// 	width: 1%;
		// 	vertical-align: middle;
		// 	display: inline-block;
		// }

		.directoryGrid__checkbox--label {
			//width: 88%;
			border-radius: 10px;
			text-align: center;
			//display: inline-block;
			//vertical-align: middle;
			padding: .5rem 1rem;
			color: #FFF;
			cursor: pointer;
			line-height: 1.1;
		}
		&.active {
			background-color: $primary;
		}
	}

}
	
// //these legacy styles are still being used by the media gallery 


// .directoryList{
// 	@media screen and (min-width: $mq-tablet-portrait){
// 		display: block;
// 	}
// 	margin: 30px 0;
// 	clear:both;
// 	padding-top:1em;
// 	.tabs{
// 		@extend .listNav;
// 		li{
// 			background: $primary;
// 			float: left;
// 			margin-right:1px;
// 			a:link, a:visited{
// 				color: #fff;
// 				display: block;
// 				text-decoration: none;
// 				font-size: .8em;
// 				padding: 10px;
// 			}
// 			&.ui-state-active{
// 				background: $secondary;
// 			}
// 		}
// 	}
	
	
// 	.filters{
// 		//background: $lightPrimary;
// 		padding: 1em 0;
// 		.filter-dropdown {
// 			float: left;
// 			font-size: .8em;
// 			margin: 0 1% 0 0;
// 			color: #fff; 
// 			width:99%; 
// 			input{
// 				box-sizing: border-box;
// 				margin-right:1.0%;
// 				width:20%;
// 			}
// 			input.filterkeyword {width:16%;}
// 			input.filterdatepicker{width:14%;}
// 			input.city, input.county {width:28.5%;}
// 			select {
// 				padding: 0.1em 0;
// 				width:18%;
// 			}
// 		}
// 		a:link, a:visited{
// 			&.btn{
// 				//margin: 0 1.0% 0 0;
// 				@include roundBtn($primary,.7em, 5px);
// 			}
// 			&.btn--filter {
// 		 display: inline-block;
// 		&.update {
// 			background-color: $primary;
// 		}
// 		}
// 			}
// 		form{ 
// 			margin: 0 0 1em 1.0%; 
// 			display: inline-block;
// 			width: 99%;
// 				}
// 			}
// 	.ajaxLoader{
// 		position: absolute;
// 		height: 100%;
// 		background: transparentize(#fff, .5);
// 		text-align: center;
// 		width: 100%;
// 		top: 0;
// 		left: 0;
// 		img{
// 			position: relative; 
// 			top: 18em;
// 		}
// 	}
// }
// .regionDirectory{
// 	ul.tabs {
// 		list-style:none;
// 		padding:0;
// 		margin:0;
// 		li{
// 			@media screen and (max-width:$mq-tablet-portrait){width:49.5%;}
// 			float: left;
// 			margin-right: 1px;
// 			a:link, a:visited{
// 				background: $primary;
// 				color: #fff;
// 				display: block;
// 				text-decoration: none;
// 				font-size: .8em;
// 				padding: 10px;
// 			}
// 		}
// 		li.ui-state-active {
// 			a:link, a:visited {background:$lightPrimary;}
// 		}

// 	}
	
// }
	
