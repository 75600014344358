.marquee { 
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	background-color: white;
	overflow: hidden;
	//border: 1px solid blue;

	&::before{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 11px;
		content: " ";
		display: block;
		background: linear-gradient(to left, $tertiary 80%, $quaternary 20%);
		z-index: 2;
	}
}


.marquee__headline {
    
    display: block;
    z-index: 3;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto; 

    & .truncatedStory {
        margin: 0;
        padding: 0;
    }

    & p, & h1 {
        margin: 0;
    }


    .marquee__heading-container {
        width: fit-content;
        width: -moz-max-content;
        margin: auto;
    }



    .text-big & {
        //border: 1px solid red;
        text-align: center;
        top: 30%;

        @media only screen and (min-width: $mq-tablet-portrait) {
            //top: 100px;
            top: 14%;
        }
        //@media only screen and (min-width: $mq-tablet-landscape) {
        //top: 150px;
        //}
    }

    .text-small & {
        text-align: left;
        top: 15px;

        @media only screen and (min-width: $mq-mobile-landscape) {
            top: 25px;
        }
    }

    &.align__center-left {
        .text-big & {
            text-align: left;
        }
    }

    @include animateElement();
}

.marquee__headline--default {
	//font-family: $accentFont;
	font-weight: 800;
	text-transform: uppercase;
	color: rgba(255,255,255,.95);
}

.marquee__headline--mobile-block {
	@media only screen and (max-width: $mq-tablet-landscape) {
		background-color: $secondary;
		position: static !important; //to override JS anchored headline
		padding: 1.5rem 1rem;
	}
}

.marquee__headline--bottom {
	.text-big & {
		top: auto;
		bottom: 10px;
		@media only screen and (min-width: $mq-tablet-portrait) {
			bottom: 20px;
		}
	}
}

.marquee__headline--mid-bottom {
	.text-big & {
		top: auto;
		bottom: 20px;
		@media only screen and (min-width: $mq-desktop) {
			bottom: 100px;
		}
	}
}

.marquee__headline--compressed {
    .text-big &.marquee__headline--bottom {
        .marquee__cta-btns {
            margin: 20px auto 10px;

            @media only screen and (min-width: $mq-tablet-portrait) {
                margin: 10px auto;
            }
        }

        .marquee__skip {
            margin: 20px auto;

            @media only screen and (min-width: $mq-mobile-landscape) {
                margin: 40px auto;
            }

            @media only screen and (min-width: $mq-tablet-portrait) {
                margin: 10px auto;
            }

            @media only screen and (min-width: $mq-tablet-landscape) {
            }
        }
    }

    .text-big & .marquee__heading--image {
/*         @media only screen and (min-width: $mq-tablet-portrait) {
            width: 50%;
        } */
 
        //@media only screen and (min-width: $mq-desktop) {
            width: auto;
        //}
    }
}
  
.marquee__heading {
	position: relative;
	display: block;
	//border: 0px solid yellow;
	padding: 0;
	
	line-height: 1.25em;
	font-weight: 900;
	letter-spacing: 0;
	cursor: default;


	.text-big & {
		margin: 0 20px;
		@media only screen and (min-width: $mq-ts) {
			margin: 0 50px;
		}
		h1, p {
			font-size: 2.25em;
			line-height: normal;
			/*@media only screen and (min-width: $mq-mobile-landscape) {
				font-size: 2.25em;
				margin: 0 50px;
			}*/

			@media only screen and (min-width: $mq-ts) {
				font-size: 2.75em;
			}

			@media only screen and (min-width: $mq-tablet-portrait) {
				font-size: 3.75em;
			}

			@media only screen and (min-width: $mq-tablet-landscape) {
				font-size: 4.75em;
			}

		}
		
		&.marquee__heading--long {
			h1, p {
				font-size: 1.4em;
				@media only screen and (min-width: $mq-tablet-landscape) {
					font-size: 4em;
				}
			}
		}

		&.marquee__heading--flush {
			margin-left: 0;
			margin-right: 0;
		}

	}

	.text-small & {
		margin: 0 20px;
		line-height: 1.25em;
		@media only screen and (min-width: $mq-mobile-landscape) {
			margin: 0 30px;
			
		}
		h1, p {
			font-size: 1em;
			text-align: left;
			@media only screen and (min-width: $mq-mobile-landscape) {
				font-size: 1em;
			}
		}
	}

	@include animateElement();

}

.marquee___headline--center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	//margin-top: -43px;
}

.marquee__heading--image {
	max-width: 100%;
	display: inline;
	.homepage & {
		width: 100% !important;
		max-width: 390px;
		@media only screen and (min-width: $mq-md) {
			width: auto;
			max-width: 600px;
		}
	}

	.text-big & {
		width: auto;
		@media only screen and (min-width: $mq-tablet-portrait) {
			width: auto;
		}
	}
	
	.text-small & {
		width: 25%;
		@media only screen and (min-width: $mq-tablet-portrait) {
			width: 30%;
		}
		@media only screen and (min-width: $mq-desktop) {
			width: 35%;
		}
	}
}


.marquee__subheading {
	position: relative;
	display: block;
	//border: 0px solid gray;
	padding: 0;
	line-height: 1.25em;
	cursor: default;

	.text-big & {
		font-size: 1em;
		margin: 0 20px;

		/*@media only screen and (min-width: $mq-mobile-landscape) {
			font-size: 1em;
			margin: 0 50px;
		}*/

		@media only screen and (min-width: $mq-tablet-portrait) {
			font-size: 1.1em;
			margin: 0 50px;
		}

		@media only screen and (min-width: $mq-tablet-landscape) {
			font-size: 1.5em;
		}

	}

	.text-small & {
		margin: 3px 20px 0;
		font-size: .8em;
		line-height: 1.25em;
		@media only screen and (min-width: $mq-mobile-landscape) {
			margin: 5px 30px 0;
			font-size: .8em;
		}

	}

	@include animateElement();
}


.marquee__image {
	display: block;
	z-index: 1;
	//border: 1px solid blue;
	//position: absolute;
	position: relative;
	top: 0;
	width: 100%; 
	left: -25%;
	@media only screen and (min-width: $mq-tablet-portrait) {
		left: auto;
		//@include background-gradient($transparentPrimary, $primary);
		// &::after{
		// 	content: '';
		// 	z-index: 9;
		// 	position: absolute;
		// 	display: block;
		// 	width: 100%;
		// 	height: 100%;
		// 	top: 0;
		// 	left: 0;
		// 	@include background-gradient-corner(transparent, $transparentPrimary);

		// }
	}
	img {
		image-rendering: pixelated;
		image-rendering: -webkit-optimize-contrast;
  		image-rendering: crisp-edges;
		width: 150%;
		@media only screen and (min-width: $mq-tablet-portrait) {
			width: 100%;
		}
	}

	&.marquee__image--left {
		left: 0%;
	}

	&.marquee__image--right {
		left: -50%;
		@media only screen and (min-width: $mq-tablet-portrait) {
			left: auto;
		}
	}
	
	&.marquee__image--zoomed {
		left: -70%;
		@media only screen and (min-width: $mq-tablet-portrait) {
			left: auto;
		}
		img {
			width: 200%;
			@media only screen and (min-width: $mq-tablet-portrait) {
				width: 100%;
			}
		}
	}
	
	.desktop & {
		position: absolute;
	}

	.novideo & {
		top: 0;
		margin: 0;
		//border: 1px solid red;
		position: relative;
	}

    &.nozoom{
       // border: 1px solid red;
        left: 0;
        img{
            width: 100%;
        }
    }
}
.marquee__image-container {
	position: static;
	top: 0;
	overflow: hidden;
	width: 100%;
	/*.desktop & {
		position: absolute;
	}
	.desktop .novideo & {
		position: static;
	}*/
}
/*.marquee__image--zoomed {
	position: relative; 
	top: -50%; 
	left: -50%; 
	width: 200%; 
	height: 400px;
	overflow: hidden;
	clear: both;
	
	@media only screen and (max-width: $mq-mobile-portrait) {
		height: 425px;
	}
	@media only screen and (min-width: $mq-mobile-landscape) {
		height: 425px;
	}

	@media only screen and (min-width: $mq-tablet-landscape) {
		height: 600px;
	}

	@media only screen and (min-width: $mq-desktop) {
		height: 782px;
	}
	.desktop & {
		height: 0;
		padding-bottom: 56.25%;
		padding-top: 25px;
	}
	.desktop .novideo & {
		padding-top: 0;
	}
	img {

		// width: 100%;
		position: absolute; 
		top: 0; 
		left: 0; 
		right: 0; 
		bottom: 0; 
		margin: 0 auto; 
		min-width: 50%;
		min-height: 50%;
		overflow-x: hidden;
		clear: both;
		width: auto;
	}
}*/


.marquee__video {
	display: block;
	position: absolute;
	//opacity: 0;
	//z-index: 80;
	//border: 1px solid blue;
	width: 100%;
	height: 100%;
	.desktop & {
		//display: block;
		position: relative;
		z-index: 2;
		width: auto;
		height: auto;
	}
	.buttonBar {
		//from the YouTube player plugin
		display: none;
	}
}

.marquee__play-container {
	
	text-align: center;

	&.overlay{
		//border: 1px solid red;
		position: absolute;
		top: 15%;
		left: 50%;
		z-index: 9;
		transform: translateX(-50%);
		padding: 20px;

		@media only screen and (min-width: $mq-tablet-landscape) {
			//border: 1px solid green;
			//top: 35%;
			display: none;
		}
	}
}

.marquee__play {
	//border: 1px solid blue;
	display: inline-block;
	opacity: 0;
	position: relative;
	padding: 10px;
	text-align: center;
	cursor: pointer;

	background: url($path_inspiration + 'main_play.png') no-repeat;//main_play.png
	background-size: 100%;
	background-position: center;

	width: (66px * .5);
	height: (66px * .5);

	@media only screen and (min-width: $mq-mobile-landscape) {
		margin: 20px 0 0;
		width: 66px;
		height: 66px;
	}

	.text-big & {
		margin: 10px 0 0;
	}

	.text-small & {
		margin: 10px 20px 0;
	}
	&.marquee__play--top {
		display: block;
		margin: 0 auto 20px;
		@media only screen and (min-width: $mq-tablet-landscape) and (min-height: 700px){
			margin: 0 auto 180px;//250px
		}
	}

}

.marquee__controls {
	display: none;
	position: absolute;
	z-index: 81;
	padding: 10px;
	left: 10px;
	top: 10px;
	//bottom: 10px;
	background: #000;
	background: rgba(0,0,0,.5);

	@media only screen and (min-width: $mq-tablet-landscape) {
		left: 15px;
		bottom: 10px;
		top: unset;
	}

}


.marquee__control {
	display: block;
	position: relative;
	float: left;
	margin: 0 5px 0 5px;
	cursor: pointer;

	@media only screen and (min-width: $mq-mobile-landscape) {
		margin: 0 10px 0 10px;
	}
}

.marquee__control--pause {

	&.pause {
		background: url($path_inspiration + 'pause.png') no-repeat;
		background-size: 100%;
		width: (11px * .6);
		height: (17px * .6);

		@media only screen and (min-width: $mq-mobile-landscape) {
			width: 11px;
			height: 17px;
		}
	}

	&.play {
		background: url($path_inspiration + 'small_play.png') no-repeat;
		background-size: 100%;
		width: (14px * .6);
		height: (19px * .6);

		@media only screen and (min-width: $mq-mobile-landscape) {
			width: 14px;
			height: 19px;
		}
	}
}

.marquee__control--mute {
	
	&.mute {
		background: url($path_inspiration + 'sound_on.png') no-repeat;
		background-size: 100%;
		width: (20px * .6);
		height: (20px * .6);
		
		@media only screen and (min-width: $mq-mobile-landscape) {
			width: 20px;
			height: 20px;
		}
	}

	&.unmute {
		background: url($path_inspiration + 'mute.png') no-repeat;
		background-size: 100%;
		width: (20px * .6);
		height: (20px * .6);

		@media only screen and (min-width: $mq-mobile-landscape) {
			width: 20px;
			height: 20px;
		}
	}
}

.marquee__control--replay {
	background: url($path_inspiration + 'replay.png') no-repeat;
	background-size: 100%;
	width: (29px * .6);
	height: (24px * .6);

	@media only screen and (min-width: $mq-mobile-landscape) {
		width: 29px;
		height: 24px;
	}
}

.marquee__skip {
	display: block;
	width: 27px;
	margin: 10px auto;
	z-index: 2;
    @media only screen and (min-width: $mq-mobile-landscape) {
		margin: 40px auto;
	}
	@media only screen and (min-width: $mq-tablet-landscape) {
		bottom: 60px;
	}
}


.marquee__action{
    position: relative;
	text-align: left;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 15px;
	margin: .5rem 0;
    @media only screen and (min-width: $mq-tablet-landscape) {
		margin: 1rem 0;
		padding-left: 0;
		padding-right: 0;
	}
	p {
		padding: 0;
		margin: 0 !important;
	}
}

.color-bar{
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	height: 100%;
	z-index: 999;
	&--yellow{
		background-color: #FACD10;
	}
}