.featured-article{

	.featured-article-info{
		width: 100%;
		@media screen and (min-width: map-get($grid-breakpoints, md)) {
			width: 40%;
		}
		@media screen and (min-width: map-get($grid-breakpoints, lg)) {
			width: 33.3%;
		}

		&::after{
			position: absolute;
			right: -20px;
			bottom: 0;
			width: 20px;
			height: 100%;
			content: " ";
			display: none;
			background-color: rgba($tertiary, .5);
			opacity: .5;
			z-index: 2;

			@media screen and (min-width: map-get($grid-breakpoints, md)) {
				display: block;
			}
		}
	}


	.featured-article-image {
		width: 100%;
		@media screen and (min-width: map-get($grid-breakpoints, md)) {
			width: 60%;
		}

		@media screen and (min-width: map-get($grid-breakpoints, lg)) {
			max-height: 600px;
			width: 66.6%;
		}

		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			max-height: 300px;
			@media screen and (min-width: map-get($grid-breakpoints, sm)) {
				max-height: 400px;
			}
			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				max-height: 500px;
			}
			@media screen and (min-width: map-get($grid-breakpoints, xl)) {
				max-height: 700px;
			}
		}
	}
}