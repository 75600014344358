///*-------------------------------------------------------------------------- 
//  User Navigation Menu - The menu at the top! 
//--------------------------------------------------------------------------*/
.utility-menu{
    //background: lighten($primary, 80%);
	background-color: white;
	font-weight: bold;
	font-size: .8em;
	position: relative;
	z-index: 6;
    *{
        font-weight: normal;
    }
	@media print {
		display: none;
	}


	ul {
          // Adding global nav CSS just to footer nav
	li {

		&:last-child  {    border: none; }


		&.topLevel{
		.dropMenu{
			z-index: 6;
		}
		}
		&.topLevel.hovered{
		background: red;//$reverseOut;
		ul{
			left: -74%;
			top: 38px;
			z-index: 6;
		}
		}
	}
	.iconFont       {
		font-size: 1.6em;   
		margin: -4px 5px 0 12%;
	}

	button.removeSetLocation {
		border:0;
		background:none;
	}
	.removeSetLocation.iconFont {
		color: purple;//$darkNeutral;
		font-size: 1em;
		//float: right;
		padding: 0;
		margin: 0 6% 0 0;
	}
	.locationName {
		max-width: 57%;
		overflow: hidden;
		text-overflow: ellipsis;
		//float: left;
		white-space: nowrap;
		@media screen and (min-width: $mq-desktop ){
		width: 65%;
		}
	}

	.tripPlanner    {   
		@media screen and (min-width: $mq-mobile-landscape){
		width: 22.30769230769231%;
		}
		@media screen and (min-width: $mq-tablet-landscape){
		width: 19.90821256038647%;
		}
		.iconFont     {
		&.map { 
			font-size: 1.6em;   
			margin: -6px 6% 0 6%;  }
		&.pullDown  { 
			float: right; 
			margin: -2px 6% 0 0;}
		}
		span{
			width: 55%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			display: block;
			float: left;
		}
		&.topLevel.hovered{
		ul{
			left: -13%;
		}
		}
		>a:link, a:visited{
		padding: 10px 0 2px 0;
		}
	}
	.favorites {
		position: relative;
		.iconFont{
		font-size: 2em; 
		margin-top: -.3em;
		}
		.icon.favCount { position: relative;}
		.favCount::after {
		    color: white;
		    //@include center();
            top: 36%;
			left: 18px;
		    content: attr(data-favcount);
            font-size: 0.8em;
		    text-align: center;
			position: absolute;
			//border: 1px solid green;
			width: 20px;
		}
		.dropMenu{
		max-height: 300px;
		overflow-y: auto;
		a.iconFont{
			font-size: .8em;
			margin-top: 1em;
		}
		li {padding-top:0.25em;}
		}
		// @media screen and (min-width: $mq-mobile-landscape){
		// width: 16.53846153846154%;
		// }
		// @media screen and (min-width: $mq-tablet-landscape){
		// width: 10%;
		// }
	}
	
	}
	.myTripsUtility {
		width: 130%;
		padding: 10px 0px;
		li {
			background: none;
			border-bottom: solid 1px #dedede;
			//&:last-child {border: none;}
		}
		.selectBtn, .deleteBtn {float: right;}
		.btn{
			display: inline-block;
			line-height: 1;
			margin: 6px 0 0 4px;
			padding: 0px;
			.circle{
			height: .8em;
			width: .8em;
			border: 1px solid $approved;
			border-radius: 9px;
			background: none;
			width: .8em;
			@include squareBtn($neutral, .7em, 2px, $travelPlannerRed);
			}
			&.active{
			.circle{background: $approved;}
			}
			.iconFont {margin: 0px;}
		}
		.createTrip {
		@include roundBtn (#096a00,1em,5px,#fff);
		width: 119px;
		font-weight:bold;
		text-align: center;
		float: right;
		}
	a {padding: 0px;}
	.navigateToTrip {display: inline;}
	}
}
.lt-ie9 .userNav ul{
	.location{
	.iconFont{
		&[data-icon]:before{
		content: " ";
		display: inline-block;
		width: 18px;
		height: 18px;
		background: url(/images/public/location.png) no-repeat;
		}
	}
	}
	.iconFont{
	&.removeSetLocation {
		margin:2px 6% 0px 0px;
	}
	}
}




