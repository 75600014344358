.text-block__heading, .text-block--widget h1, .text-block--widget-large-h2 h2 {
	box-sizing: border-box;
	color: $headline;
    display: block;
    //font-family: $accentFont;
    text-align: center;
	font-style: normal;
	font-weight: bold;
	//margin: 0 15px .3em;
	margin: 0 0 .3em;
	text-transform: none; 

	.marquee__headline &{
		line-height: .9;
	}
}

.text-block--widget h1{
	font-size: 2.5rem;

	// @media screen and (min-width:$mq-tablet-portrait){
	// 	font-size: 2.5rem;
	// }
}

.text-block--widget-small-h2 h2{
	// font-size: 2em;

	// @media screen and (min-width:$mq-tablet-portrait){
	// 	font-size: 2.5em;
	// }
	@include r-font-size(42,56);
}

.text-block--widget-large-h2 h2{
	// font-size: 2em;

	// @media screen and (min-width:$mq-tablet-portrait){
	// 	font-size: 2.5em;
	// }
	@include r-font-size(56,80);
}

.text-block--widget h2 {
	box-sizing: border-box;
	color: $headline;
    display: block;
	//font-family: $accentFont;
	font-style: normal;
	font-weight: 800;
	text-transform: none;
	
	@include r-font-size(42,80);
}

//we're moving from a brown to a gray standard heading, so this class is for legacy headings
.text-block__heading--secondary, .text-block__heading--secondary h1, .text-block__heading--secondary h2 {
	color: $secondary;
}

//for some reasons h2 have more aggressive margins to override
.text-block--widget-large-h2 .truncatedStory h2 {
	@media screen and (min-width:$mq-tablet-portrait){
		margin: 0 15px .3em;
	}
}

.text-block__heading--small {
	font-size: 1.3em;
	margin-bottom: 0;
}

.text-block__heading--normal {
	font-weight: normal;
}

.text-block--widget {
	.truncatedStory {
		padding: 0;
	}
}

.text-block__heading--subscribe {
	display: inline-block;
}

.text-block__heading--white {
	color: #fff;
}

.text-block__heading--above {
	margin: .6em 0 0;
}

.text-block__heading--flush {
	margin-left: 0;
	margin-right: 0;
}

.text-block__heading--no-space {
	margin: 0;
}

.text-block--widget-heading--flush {
	h1, h2 {
		margin-left: 0;
		margin-right: 0;
	}
	.truncatedStory h2 {
		margin-left: 0;
		margin-right: 0;
	}
}

.text-block__heading--caps {
	text-transform: uppercase;
}

.text-block__heading--small-text-below-medium {
	@media only screen and (max-width: $mq-tablet-landscape) {
		font-size: 1.2em;
	}
}

.text-block--widget-heading-caps {
	h1 {
		text-transform: uppercase;
	}
}

.text-block--widget-heading-caps-at-medium {
	@media screen and (min-width: $mq-tablet-landscape){
		h1 {
			text-transform: uppercase;
		}
	}
}

.text-block--widget-h2-caps {
	h2 {
		text-transform: uppercase;
	}
}

.text-block--widget-h2-left-mobile {
	h2 {
		@media screen and (max-width:$mq-tablet-portrait){
			text-align: left;
		}
	}
}

.text-block--widget-heading-large {
	h1, h2 {
		@media screen and (min-width:$mq-tablet-portrait){
			font-size: 4.75rem;
			margin-bottom: .3em;
		}
	}
}

.text-block__heading--large {
	@media screen and (min-width:$mq-tablet-portrait){
		font-size: 4.75rem;
		margin-bottom: .3em;
	}
}

.text-block__heading--extra-large {
	font-size: 4em;
	@media screen and (min-width:$mq-tablet-portrait){
		font-size: 6em;
		margin-bottom: .3em;
	}
}


.text-block--widget-heading-large-at-medium {
	h1 {
		@media screen and (min-width: $mq-tablet-landscape) and (min-height: 600px){
			font-size: 4.25em;
			margin-bottom: .1em;
		}
	}
}

.text-block__heading--medium {
	font-size: 3.5em;
	margin-bottom: .3em;
}

.text-block--widget-heading-medium {
	h1, h2 {
		@media screen and (min-width:$mq-tablet-portrait){
			font-size: 3.5em;
			margin-bottom: .3em;
		}
	}
}

.text-block--widget-heading-left {
	h1, h2 {
		text-align: left;
		@media screen and (min-width:$mq-tablet-portrait){
			margin-left: 0;
		}
	}
}

.text-block--widget-heading-white {
	h1, h2 {
		color: #fff;
	}
}

.text-block--widget-heading-white-at-medium {
	@media screen and (min-width: $mq-tablet-landscape){
		h1, h2 {
			color: #fff;
		}
	}
}

.text-block__heading--left {
	text-align: left;
}

.text-block--left-mobile {
	@media screen and (max-width: $mq-tablet-portrait){
		text-align: left;
	}
}
 
.text-block {
	//color: $darkNeutral;
    font-size: 1.125em;
	text-align: center;
	margin: 2.5em auto 1em;

	p.pad{
		padding: 0 15px;
	}

	&.no-margin-bot{
		margin-bottom: 0;
	}
}

.text-block--no-top-space {
	margin-top: 0;
}

.text-block--unspaced {
	margin: 0;
}

.text-block--balanced {
	margin: 1em auto;
}

.text-block--small-balanced {
	margin-top: .5em;
	margin-bottom: .5em;
}

.text-block--large-balanced {
	margin: 2.5em auto;
}

.text-block--extra-large-balanced {
	margin: 3.5em auto;
}

.text-block--dark-text {
	color: #000;
}

.text-block--light-text {
	color: #fff;
}

.text-block--light-text-at-medium {
	@media screen and (min-width: $mq-tablet-landscape){
		color: #fff;
	}
}

.text-block--large-text {
	font-size: 1.2em;
}

.text-block--extra-large-text {
	font-size: 1.5em;
}

.text-block--small-text {
	font-size: .95em;
}

.text-block--extra-small-text {
	font-size: .8em;
}

.text-block--shadow {
	text-shadow: 0px 0px 7px #000;
}

.text-block--transparent {
	@media screen and (min-width: $mq-tablet-landscape){
		opacity: .9;
	}
}

.text-block--shadow-at-medium {
	@media screen and (min-width: $mq-tablet-landscape){
		text-shadow: 0px 0px 7px #000;
	}
}

.text-block--left {
	text-align: left;
}

.text-block--left-below-small { 
	@media only screen and (max-width: $mq-tablet-portrait) {
		text-align: left;
	}
}

.text-block--center {
	text-align: center;
}

.text-block--small {
	width: auto;

	a:visited, a:link{
		text-decoration: underline;
	}
	a:hover, a:active{
		color: darken($primary, 20%);
	}
	@media only screen and (min-width: $mq-tablet-portrait) {
		width: 70%;
	}
}

.text-block--extra-small {
	width: auto;
	@media only screen and (min-width: $mq-tablet-portrait) {
		width: 55%;
	}
}

.text-block--fit-account {
	//IE11 is being problematic with flexbox at these sizes, so need to force wrap 
	@media only screen and (max-width: $mq-tablet-portrait) {
		max-width: 400px;
	}
	@media only screen and (max-width: $mq-tablet-landscape) {
		max-width: 500px;
	}
} 

.text-block--fit-marquee {
	@media only screen and (min-width: $mq-tablet-landscape) {
		.text-narrow &{
			width: 50%;
		}
		.text-wide & {
			width: 75%;
		}
	}
}

.text-block--padded-below-medium {
	@media only screen and (max-width: $mq-tablet-landscape) {
		padding: 15px;
	}
}

.text-block--no-bottom-space-below-medium {
	@media only screen and (max-width: $mq-tablet-landscape) {
		margin-bottom: 0;
	}
}

.text-block--flush {
	margin-left: 0;
	margin-right: 0;
}

.text-block--flush-mobile {
	@media only screen and (max-width: $mq-tablet-portrait) {
		margin-left: 0;
		margin-right: 0;
	}
}

.text-block--unstyle .truncatedStory {
	//just undoing everything from truncatedStory
	height: auto;
    margin-top: auto;
    padding: 0;
    @media screen and (min-width: $mq-tablet-portrait){
	  padding: 0;
    }
    h2 {
		margin-top: 0.83em;
		margin-bottom: 0.83em;
		margin-left: 0;
		margin-right: 0;
	}
	p,address{
	  margin-top: 1em;
	  margin-bottom: 1em;
	  margin-left: 0;
	  margin-right: 0;
	  @media screen and (min-width: $mq-tablet-portrait){
	    margin-top: 1em;
		margin-bottom: 1em;
		margin-left: 0;
		margin-right: 0;
	  }
    }
}

.text-block--padded {
	padding: 0 15px;
	ul {
		padding: 0;
		margin: 0;
	}
	//matching text block headings
	.truncatedStory p {
		margin-left: 0;
		margin-right: 0;
	}
}

.text-block--large-padding-right {
	@media screen and (min-width: $mq-tablet-landscape ){
		padding-right: 100px
	}
}

.text-block--large-padding {
	@media screen and (min-width: $mq-tablet-landscape ){
		padding: 0 100px
	}
}

.text-block-widget--inner .truncatedStory {
	//trying to recreate a text block with a truncated story inside of it
	color: $darkNeutral;
    font-size: 1.125em;
	text-align: center;
	margin: 2.5em auto 1em;
}

.text-block-widget--left .truncatedStory {
	//trying to recreate a left-aligned text block with a truncated story inside of it
	text-align: left;
}

.text-block-widget--padded .truncatedStory {
	//trying to recreate a padded text block with a truncated story inside of it
	ul {
		padding: 0;
		margin: 0;
	}
	padding: 1px 40px 20px;
}

.text-block-widget--balanced .truncatedStory {
	//trying to recreate a padded text block with a truncated story inside of it
	margin: 1em auto;
}

.text-block-a-bold{
	a:visited, a:link, a:hover,a:active{
		font-weight: 700;
	}
}

.text-right{
	text-align: right !important;
}

// .events-weekend-directory{
// 	h1,h2,h3,h4,h5{
// 		color: $blueDark;
// 	}
// }