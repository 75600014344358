// VARIABLES
$secondaryBlue: #3092A8;
$accessibleTeal: #007E85;

.bandwango-landing {
    //custom classes for bandwango-landing
	h2 {
		text-transform: none;
	}

	.text-accessible-teal {
		color: $accessibleTeal;
	}
	
    .accent-border-top {
        position: relative;
        &:before {
            content: " ";
            position: absolute;
			background: linear-gradient(to right, $senary 10%, $campaign-secondary 10%);
            display: block;
			z-index: 3; 
            height: 16px;
            left: 0;
            top: 0;
            width: 100%;
        }

        &.border-thick {
			&:before {
				height: 22px;
            }
        }
		&--tertiary {
			&:before {
				background: $tertiary;
			}
			&.border-thick {
				&:before {
					height: calcRem(24);
				}
			}
		}
		&--light {
			&:before {
				background: linear-gradient(to right, $tertiary 20%, $senary 10%);
			}
			&.border-thick {
				&:before {
					height: calcRem(24);
				}
			}
		}
    }

	.accent-border-bottom {
		&:before {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 16px;
			content: " ";
			display: block;
			background: linear-gradient(to right, $quaternary 0%, $quaternary 85%, $senary 85%, $senary 100%);
			z-index: 2;
		}
	}

	.accent-border-left {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: calcRem(12);
			left: 0;
			top: 0;
			width: 100%;
			background: linear-gradient(to right, $quaternary 0%, $quaternary 10%, $senary 10%, $senary 20%, $tertiary 20%, $tertiary 100%);
			z-index: 2;
			
			@include media-breakpoint-up(lg) {
				height: 100%;
				left: 0;
				top: 0;
				width: calcRem(16);
				background: linear-gradient($tertiaryLighter 0, $tertiaryLighter 5%, $senary 5%, $senary 15%, $quaternary 15%, $quaternary 100%);
			}			
		}
	}

	.accent-border-right {
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			display: block; 
			height: 100%;
			right: 0;
			top: 0;
			width: 22px;
			background: $quaternary;
		}
	}

	.tertiary-light-overlay-border {
		background-color: $tertiaryLighter;
		height: calcRem(16);
		left: 0;
		// opacity: .64;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.tertiary-overlay-border {
		background-color: $secondaryBlue;
		height: calcRem(12);
		position: absolute;
		top: 0;
		width: 100%;
		@include media-breakpoint-up(lg) {
			height: 100%;
			right: 0;
			width: calcRem(12);
		}
	}
	.senary-overlay-border {
		background-color: $senary;
		height: calcRem(24);
		left: 0;
		opacity: .64;
		position: absolute;
		bottom: 0;
		width: 100%;
		@include media-breakpoint-up(lg) {
			height: 100%;
			left: auto;
			right: 0;
			top: 0;
			width: calcRem(24);
		}
	}
	.bw-ic--highlight-single {
		font-weight: 600;
	}
	.bandwango-arrow {
		top: 85px;
		right: 50px;
	}

	.circle-nav-button {
		align-items: center;
		border-radius: 50%;
		box-shadow: 0 5px 14px rgba(0,0,0,.2);
		display: flex;
		height: calcRem(60);
		justify-content: center;
		width: calcRem(60);
		&.slick-disabled {
			pointer-events: none;
			opacity: .5;
		}
	}

	.circle-icon {
		align-items: center;
		border-radius: 50%;
		display: flex;
		height: calcRem(60);
		justify-content: center;
		width: calcRem(60);
	}	

	// HERO SECTION 
	.campaign-hero-section {
		max-height: 775px;
		position: relative;
		z-index: 1;
		img {
			width: 100%;
		}	
		.campaign-hero-img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
            max-height: 300px;
			@media screen and (min-width: map-get($grid-breakpoints, sm)) {
				max-height: 375px;
			}
			@media screen and (min-width: map-get($grid-breakpoints, lg)) {
				max-height: 465px;
			}
			@media screen and (min-width: map-get($grid-breakpoints, xl)) {
				max-height: 650px;
			}
		}

		@media screen and (min-width: map-get($grid-breakpoints, lg)) {
			max-height: 465px;
		}
		@media screen and (min-width: map-get($grid-breakpoints, xl)) {
			max-height: 650px;
		}
		@media screen and (min-width: $mq-tablet-portrait) {
			&:before {
				width: 19px;
			}
			
		}
	}
	.campaign-hero-heading {
		bottom: 0;
		//bottom: map-get($spacers, 3);
		left: 50%;
		max-width: 1175px;
		position: absolute;
		transform: translateX(-50%);
		width: 100%;
		z-index: 2;
	}

	.section-1-content {
		text-align: center;
		@include media-breakpoint-up(lg) {
			text-align: left;
		}				
	}
	
	.prizes-card {
		min-height: 520px;
		border-radius: 0;
		@include media-breakpoint-up(lg) {
			min-height: 575px;	
		}			
	}

	.prize-img {
		max-height: 350px;
		height: 100%;
		object-fit: cover;
	}

	.instruction-cards {
		border-bottom: 1px $gray-400 solid;
		@include media-breakpoint-up(lg) {
			border-right: 1px $gray-400 solid;
			border-bottom: none;
		}		
	}
	
	.instruction-cards:nth-last-child(1) {
		border-right: none;
		border-bottom: none;
	}	

	// MODAL
	dialog {
		animation: none;
		animation-delay: .4;
		width: 100%;
		max-width: calcRem(960);
		border: 0;
		border-radius: 15px;
		padding: 0;
		max-height: 90vh;
		overflow-y: auto;
		z-index: 5;	
		.modal-image {
			width: 100%;
			@include media-breakpoint-up(sm) {
				width: 275px;
			}		
		}		
	}

	dialog[open] {
		animation: fade-in 0.25s ease-out;
		animation-delay: .25;
		width: 100%;
		max-width: calcRem(960);
		border: 0;
		border-radius: 15px;
		padding: 0;
		height: 100vh;
		max-height: 100vh;
		overflow-y: auto;
		z-index: 5;	
		@include media-breakpoint-up(md) {
			height: fit-content;
			max-height: 90vh;
		}			
		.modal-image {
			width: 100%;
			@include media-breakpoint-up(sm) {
				width: 275px;
			}		
		}	
	}

	dialog[open]::backdrop {
		animation: backdrop-fade-in 0.25s ease-out forwards;
	}
	dialog::backdrop {
		animation: backdrop-fade-out 0.25s ease-out forwards;
	}

	.dialog-modal-body {
		padding: map-get($spacers, 5) 0;
		@include media-breakpoint-up(sm) {
			padding: map-get($spacers, 5) map-get($spacers, 6) map-get($spacers, 6);
		}	
	}

	.dialog-close {
		border: 0;
		position: sticky;
		top: map-get($spacers, 6);
		left: 100%;
		padding: map-get($spacers, 2) map-get($spacers, 4) map-get($spacers, 2) map-get($spacers, 3);
		border-top-left-radius: 100px;
		border-bottom-left-radius: 100px;
		color: $white;
		@include media-breakpoint-up(md) {
			top: map-get($spacers, 4);
		}				
	}

	/* Animation keyframes */

	@keyframes fade-in {
		0% {
			// opacity: 0;
			transform: translateY(100%);
			display: none;
		}

		100% {
			// opacity: 1;
			transform: translateY(0);
			display: block;
		}
	}

	@keyframes fade-out {
		0% {
			// opacity: 1;
			transform: translateY(0);
			display: block;
		}

		100% {
			// opacity: 0;
			transform: translateY(100%);
			display: none;
		}
	}

	@keyframes backdrop-fade-in {
		0% {
			background-color: rgb(0 0 0 / 0%);
		}

		100% {
			background-color: rgb(0 0 0 / 65%);
		}
	}
	@keyframes backdrop-fade-out {
		0% {
			background-color: rgb(0 0 0 / 65%);
		}
		
		100% {
			background-color: rgb(0 0 0 / 0%);
		}
	}	



	//embedded markup and styling coming from bandwango
	.bw-ic--info{
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.bw-image-card-ctn{
		height: unset!important;
	}
	.bw-ic-image{
		min-height: unset!important;
	}
}
