$mq-other-mid		: 600px !default;
$ideas-dark-green   : #28332d;
$picture-height: 258px; 
$box-width: 300px;
$box-height: ($picture-height + 210px);
$gutter-width: 30px;

// .page-ideas {
// 	background: url('/Images/inspiration/article_bg.png') no-repeat;//
// 	background-position: bottom center;

// }


.ideas-head { 
	display: block;
	position: relative;
	margin: 0;
	background-color: white;
	overflow: hidden;
}


.ideas-head__text {
	display: block;
	z-index: 2;
	position: absolute;
	margin: 0;
	width: 100%;
	height: auto;
	color: white;

	& .truncatedStory {
		margin: 0;
		padding: 0;
	}

	& p {
		margin: 0;
	}

	


	 .text-big & {
	 	text-align: center;
	 	top: 25px;

		@media only screen and (min-width: $mq-mobile-landscape) {
			top: 50px;
		}

		@media only screen and (min-width: $mq-tablet-portrait) {
			top: 150px;
		}

		@media only screen and (min-width: $mq-tablet-landscape) {
			top: 150px;
		}

		@media only screen and (min-width: $mq-desktop) {
			top: 200px;
		}

	 }


	@include animateElement();
}


.ideas-head__text__head {
	position: relative;
	display: block;
	//border: 0px solid yellow;
	padding: 0 30px;
	
	line-height: 1.25em;
	font-weight: 900;
	letter-spacing: 0;
	cursor: default;
	max-width: 1100px;
	margin: 0 auto;
	@include r-font-size(40,80);
	// font-size: 40px;


	// @media only screen and (max-width: $mq-mobile-portrait) {
	// 	font-size: 40px;
	// }
	// @media only screen and (min-width: $mq-mobile-landscape) {
	// 	font-size: 50px;
	// }

	// @media only screen and (min-width: $mq-tablet-landscape) {
	// 	font-size: 80px;
	// }

	// @media only screen and (min-width: $mq-desktop) {
	// 	font-size: 80px;
	// }

	@include animateElement();

	.ideas-head--dark & {
		color: $secondary;
	}

}


.ideas-head__text__sub {

	position: relative;
	display: block;
	font-style: italic;
	//border: 0px solid gray;
	padding: 0 20px;
	line-height: 1.25em;
	cursor: default;
	max-width: 1100px;
	margin: 30px auto 0 auto;
	@include r-font-size(18,24);
	// font-size: 14px;

	// @media only screen and (max-width: $mq-mobile-portrait) {
	// 	font-size: 14px;
	// }
	// @media only screen and (min-width: $mq-mobile-landscape) {
	// 	font-size: 16px;
	// }

	// @media only screen and (min-width: $mq-tablet-landscape) {
	// 	font-size: 25px;
	// }

	// @media only screen and (min-width: $mq-desktop) {
	// 	font-size: 25px;
	// }

	@include animateElement();

	.ideas-head--dark & {
		color: $secondary;
	} 
}





//=========pillars==============

.ideas-pillar__container {
	width:$box-width;
	@media only screen and (max-width: $mq-mobile-portrait) {
		width:$box-width;
	}
	@media only screen and (min-width: $mq-mobile-landscape) {
		width: ($box-width*2 + $gutter-width);
	}

	@media only screen and (min-width: $mq-tablet-landscape) {
		width: ($box-width*3 + $gutter-width*2);
	}

	@media only screen and (min-width: $mq-desktop) {
		width: ($box-width*3 + $gutter-width*2);
	}

	position: relative;
	margin:15px auto 60px auto;
	//overflow: hidden;

	.ideas-pillar {
		//height: $box-height;
		height: auto;
		width: $box-width;
		// background: url('/Images/trip-ideas/summary_bg.jpg') no-repeat;	
		// background-position: bottom;
		// background-size: $box-width;
		
		background-color: $secondary;
		position: relative;
		display: flex;
		flex-direction: column;
		//float: left;
		//margin: 15px 0px;
		// background-color: $ideas-dark-green;
		a {
			color: #FFF;
			text-decoration: none;
		}
		@media only screen and (max-width: $mq-mobile-landscape) {
			width:100%;
			//margin: 15px auto;
		}

		// &__img{
		// 	background-color: $secondary;
		// 	padding-bottom: 30px;
		// }

		.gems
		{
			&:before {
				content: url('/Images/trip-ideas/HiddenGems.png');
				left: 50%;
				margin-left: -49px; //half icon width
				position: absolute;
				top: ($picture-height - 50px); //$picture-height - half icon height
				z-index: 1;
			}
		}

		.wonders{
			&:before {
				content: url('/Images/trip-ideas/NaturalWonders.png');
				left: 50%;
				margin-left: -49px; //half icon width
				position: absolute; 
				top: ($picture-height - 35px); //$picture-height - half icon height
				z-index: 1;
			}
		}

		.getaways{
			&:before {
				content: url('/Images/trip-ideas/FamilyGetaways.png');
				left: 50%;
				margin-left: -49px; //half icon width
				position: absolute;
				top: ($picture-height - 50px); //$picture-height - half icon height
				z-index: 1;
			}
		}
		
		.girlfriend{
			&:before {
				content: url('/Images/trip-ideas/GirlfriendGetaways.png');
				left: 50%;
				margin-left: -57px; //half icon width
				position: absolute;
				top: ($picture-height - 60px); //$picture-height - half icon height
				z-index: 1;
			}
		}

        .fall-getaways{
			&:before {
				content: url('/Images/trip-ideas/FallGetaways.png');
				left: 50%;
				margin-left: -53px; //half icon width
				position: absolute;
				top: ($picture-height - 48px); //$picture-height - half icon height
				z-index: 1;
			}
		}
		
		.city{
			&:before {
				content: url('/Images/trip-ideas/City.png');
				left: 50%;
				margin-left: -60px; //half icon width
				position: absolute;
				top: ($picture-height - 59px); //$picture-height - half icon height
				z-index: 1; 
			}
		}
		
		.guys {
			&:before {
				content: url('/Images/trip-ideas/GuysGetaways.png');
				left: 50%;
				margin-left: -45px; //half icon width
				position: absolute;
				top: ($picture-height - 40px); //$picture-height - half icon height
				z-index: 1;
			}
		}
		
		.romantic-getaways {
			&:before {
				content: url('/Images/trip-ideas/RomanticGetaways.png');
				left: 50%;
				margin-left: -43px; //half icon width
				position: absolute;
				top: ($picture-height - 36px); //$picture-height - half icon height
				z-index: 1;
			}
		}
		.winter {
			&:before {
				content: url('/Images/trip-ideas/WinterGetaways.png');
				left: 50%;
				margin-left: -47px; //half icon width
				position: absolute;
				top: ($picture-height - 55px); //$picture-height - half icon height
				z-index: 1;
			}
		}
		.music {
			&:before {
				content: url('/Images/trip-ideas/MusicFestivals.png');
				left: 50%;
				margin-left: -44px; //half icon width
				position: absolute;
				top: ($picture-height - 40px); //$picture-height - half icon height
				z-index: 1;
			}
		}

		.pet {
			&:before {
				content: url('/Images/trip-ideas/PetFriendlyTravel.png');
				left: 50%;
				margin-left: -50px; //half icon width
				position: absolute;
				top: ($picture-height - 60px); //$picture-height - half icon height
				z-index: 1;
			}
		}
		.spring {
			&:before {
				content: url('/Images/trip-ideas/SpringGetaways.png');
				left: 50%;
				margin-left: -48px; //half icon width
				position: absolute;
				top: ($picture-height - 55px); //$picture-height - half icon height
				z-index: 1;
			}
		}
		.summer {
			&:before {
				content: url('/Images/trip-ideas/SummerGetaways.png');
				left: 50%;
				margin-left: -48px; //half icon width
				position: absolute;
				top: ($picture-height - 54px); //$picture-height - half icon height
				z-index: 1;
			}
		}
		.flw {
			&:before {
				content: url('/Images/trip-ideas/FLW.png');
				left: 50%;
				margin-left: -50px; //half icon width
				position: absolute;
				top: ($picture-height - 40px); //$picture-height - half icon height
				z-index: 1;
			}
		}


		.bigger-fonts &{
			//@extend .bblu;
			height: 405px;
		}

		.ideas-pillar__img{
			position: relative;
			//border: 1px solid red;
			&::after{
				content: '';
				width: 100%;
				height: 1rem;
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				background-color: rgba($secondary, .8);
			}
		}
		
		.ideas-pillar__name {
			font-size: 32px;
			font-weight: 700;
			text-align: center;
			padding: 0 10px;
			margin: 20px auto 8px auto;
			display: block;
			overflow: hidden;
			line-height: 1.1;
			//border: 1px solid blue;
			.no-names & {
				//border: 1px solid yellow;
				display: none;
			}
		}

		.ideas-pillar__summary {
			font-size: 16px;
			text-align: center;
			padding: 0 25px;
			margin: 0 auto;
			display: block;
			overflow: hidden;
			//max-height: 75px;
			@media only screen and (max-width: $mq-mobile-landscape) {
				line-height: 1.1;
			}
			.no-names & {
				margin: 15px auto;
			}
			.bigger-fonts & {
				//@extend .bred;
				font-size: 24px;
				padding: 0 15px;
				//max-height: 120px;
			}
		}
	}
}

//=========pillars end==========