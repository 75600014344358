//----------------------------------------------------------------------------//
// _GLOBALS
//----------------------------------------------------------------------------//
$element-sides: top, right, bottom, left !default; // DO NOT EDIT
//----------------------------------------------------------------------------//
// BORDERS
//----------------------------------------------------------------------------//
$enable-responsive-border-classes: false !default;
$enable-responsive-border-radius-classes: true !default;
//----------------------------------------------------------------------------//
// COLORS
//----------------------------------------------------------------------------//
$enable-responsive-inverted-color-classes:  false !default;
$enable-responsive-color-classes:           false !default;
$enable-responsive-bg-color-classes:        false !default;
// Inverted Text Colors
$body-color-inverted:                       $white !default;
$link-color-inverted:                       $white !default;
$link-hover-color-inverted:                 $white !default;
//----------------------------------------------------------------------------//
// CURSORS
//----------------------------------------------------------------------------//
$enable-responsive-pointer-cursors: false !default;
$enable-responsive-default-cursors: false !default;
//----------------------------------------------------------------------------//
// MAX WIDTH
//----------------------------------------------------------------------------//
$enable-responsive-mw-classes:  false !default;
//----------------------------------------------------------------------------//
// OPACITY
//----------------------------------------------------------------------------//
$enable-responsive-opacity-classes: false !default;
$opacity: (
    0 : 0,
    1 : 0.1,
    2 : 0.2,
    3 : 0.3,
    4 : 0.4,
    5 : 0.5,
    6 : 0.6,
    7 : 0.7,
    8 : 0.8,
    9 : 0.9,
    10 : 1
) !default;
//----------------------------------------------------------------------------//
// POSITIONING
//----------------------------------------------------------------------------//
$enable-responsive-centering-elements: true;
$enable-responsive-side-offsets: true;
//----------------------------------------------------------------------------//
// Text
//----------------------------------------------------------------------------//
$enable-responsive-text-size: true;

$letter-spacing-body: inherit !default;
$letter-spacing-heading: inherit !default;
$word-spacing-body: inherit !default;
$word-spacing-heading: inherit !default;

$text-styles: (
    h1: (min: 48, max: 64),
    h2: (min: 30, max: 36),
    h3: (min: 20, max: 24),
    h4: (min: 18, max: 22),
    body: (min: 16, max: 18)
);

$text-sizes: (
    xs: 0.8,
    sm: 0.9,
    base: 1,
    lg: 1.45,
    xl: 1.75
);

// RESPONSIVE FONT SIZE UTILITY CLASSES
$font-sizes: (
    12: ( min: 10, max: 12 ),
	14: ( min: 12, max: 14 ),
	16: ( min: 14, max: 16 ),
	18: ( min: 16, max: 18 ),
	21: ( min: 18, max: 21 ),
	24: ( min: 21, max: 24 ),
	32: ( min: 24, max: 32 ),
	36: ( min: 32, max: 36 ),
    42: ( min: 32, max: 42 ),
	56-sm: ( min: 32, max: 56 ),
	56: ( min: 42, max: 56 ),
	80-sm: ( min: 42, max: 80 ),
    80: ( min: 56, max: 80 ),
	100-sm: ( min: 56, max: 100 ),
	100: ( min: 80, max: 100 ),
    108: ( min: 108, max: 108 ),
	125: ( min: 100, max: 125 ),
	240: ( min: 200, max: 240 ),
	280: ( min: 240, max: 280 ),

    //stop using these vv
	34-sm: ( min: 18, max: 34), // kickers
	34-md: ( min: 20, max: 34 ),
	34-lg: ( min: 24, max: 34 ),
);

$font-weights: (
    thin: 100,
    extralight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
);