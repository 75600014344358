.accordion{
    //background-color: $gray-300;
    // &-btn{
    //     background-color: $gray-200;
    // }
    &-body{
        //background-color: $gray-400;
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: all 100ms ease-in-out;

        &.active{
            max-height: 1000px;
        }
    }
}

.alphabetical-column {
	column-count: 3;
	column-width: 17rem;
}

.form-cta{
    .color-bars{
		//border: 1px solid blue;
		position: absolute;
        z-index: 9;
        top: 0;
		width: .75rem;
        height: 100%;

        &--3{
			left: 0;
			background-color: $senary;

			&::before{
				content: '';
				position: absolute;
				display: block;
				//bottom: 10%;
                bottom: 0;
				left: 0;
				width: 100%;
				height: 30%;
				background-color: $tertiary;
				z-index: 5;
			}

			// &::after{
			// 	content: '';
			// 	position: absolute;
			// 	display: block;
			// 	bottom: 0;
			// 	left: 0;
			// 	width: 100%;
			// 	height: 10%;
			// 	background-color: $tertiary;
			// 	z-index: 5;
			// }
		}

    }
}