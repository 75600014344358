/* ONLY Coveo styles that are relevant to the search bar in the nav. All Coveo styles, including search results page, are in the search stylesheet */

.CoveoSearchInterface {
    min-height: 100%;
    position: relative;
    margin: 0;
    font-size: 15px;
    min-width: 300px;
    // @media screen and (max-width: $mq-desktop-sm) {
    //     min-width: 300px;
    // }
}

.coveo-after-initialization > * {
    display: inherit;
    visibility: inherit;
}

.CoveoSearchInterface * {
    box-sizing: border-box;
}

.coveo-search-section {
    display: flex;
}

.CoveoSearchInterface .coveo-search-section {
    max-width: 800px;
    //margin: 54px auto 47px;
    margin: 10px auto 10px;
    padding: 0 10px;

    @media screen and (min-width: $mq-desktop-sm) {
        margin: 7px 0 0;
        //padding: 0 10px;
    }
}

// .CoveoSearchInterface .coveo-search-section {
//     margin-top: 0;
// }

// .CoveoSearchInterface .coveo-search-section {
//     margin-bottom: 0;
// }

.CoveoSearchbox {
    display: flex;
}

.coveo-search-section .CoveoSearchbox {
    -ms-flex: 1;
    flex: 1;
    //margin-right: 10px;
}

.magic-box {
    position: relative;
    text-align: left;
    color: #212121;
}

.CoveoSearchbox .magic-box {
    // flex-grow: 1;
    // border: thin solid #bcc3ca;
    // border-radius: 2px;
    flex-grow: 1;
    border: 1px solid $gray-400;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    box-sizing: border-box;
    overflow: hidden;

    
}

.coveo-search-button-loading{
    display: none;
}

.magic-box .magic-box-input {
    background: #fff;
    height: 43px;
    overflow: hidden;
    position: relative;
    input{
        font-family: $font-family-base;
        font-weight: 800;
    }
}

// .CoveoSearchbox .magic-box .magic-box-input {
//     border-radius: 2px;
// }

.magic-box .magic-box-input .magic-box-underlay, .magic-box .magic-box-input > input {
    color: #212121;
    text-align: left;
    padding: 12px 49px 12px 0;
    text-indent: 12px;
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 43px;
    white-space: pre;
    overflow: auto;
    box-sizing: border-box;
    display: block;
}

.magic-box .magic-box-input .magic-box-underlay {
    color: transparent;
    overflow: hidden;
}

.magic-box .magic-box-input .magic-box-underlay > span {
    text-indent: 0;
}

.magic-box .magic-box-input .magic-box-underlay span {
    display: inline-block;
    vertical-align: top;
}

.coveo-query-syntax-disabled.magic-box .magic-box-input .magic-box-underlay span, .coveo-query-syntax-disabled .magic-box-highlight-container {
    display: none;
}

.magic-box .magic-box-input .magic-box-underlay span[data-value=''] {
    height: 24px;
}

.magic-box .magic-box-input .magic-box-underlay .magic-box-ghost-text {
    visibility: hidden;
    color: #bdbdbd;
}

.magic-box .magic-box-input .magic-box-underlay .magic-box-ghost-text, .magic-box.magic-box-hasFocus .magic-box-input .magic-box-underlay .magic-box-ghost-text {
    display: none;
}

.magic-box.magic-box-hasFocus .magic-box-suggestions.magic-box-hasSuggestion {
    display: block;
  }

.magic-box .magic-box-input input {
    background: none;
    border: none;
    resize: none;
    outline: 0;
}

.magic-box .magic-box-clear {
    position: relative;
    float: right;
    height: 43px;
    line-height: 43px;
    text-align: center;
    font-size: 24px;
    transition: width 0.3s;
    cursor: pointer;
    background: #fff;
    width: 0;
    overflow: hidden;
}

.CoveoSearchbox .magic-box .magic-box-clear-svg {
    width: 15px;
    height: 15px;
    color: $primary;
}

.magic-box .magic-box-suggestions {
    clear: both;
    position: absolute;
    top: 100%;
    left: -1px;
    right: -1px;
    overflow: hidden;
    background: #fff;
    z-index: 1;
    display: none;
    border: 1px solid #9e9e9e;
}

.CoveoOmnibox.magic-box .magic-box-suggestions {
    z-index: 3;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator,
.magic-box .magic-box-suggestions .magic-box-suggestion {
  opacity: 1;
  transition: opacity 0.3s;
}

.magic-box .magic-box-suggestions.magic-box-suggestions-loading .magic-box-suggestion-seperator,
.magic-box .magic-box-suggestions.magic-box-suggestions-loading .magic-box-suggestion {
  opacity: 0;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator {
  padding: 12px 12px 0;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
  height: 36px;
  border-top: 1px solid #e0e0e0;
  color: #9e9e9e;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator:first-child {
  border-top: none;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator .magic-box-suggestion-seperator-label {
  float: left;
  margin-right: 12px;
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator:after {
  content: '';
  display: block;
  position: relative;
  top: 50%;
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator + .magic-box-suggestion {
  border-top: none;
}

.magic-box .magic-box-suggestions .magic-box-suggestion {
  cursor: pointer;
  line-height: 24px;
  padding: 12px;
  font-size: 16px;
  background: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 1px solid #fafafa;
  color: #212121;
}

.magic-box .magic-box-suggestions .magic-box-suggestion .magic-box-hightlight {
  font-weight: bold;
  color: #000;
}

.magic-box .magic-box-suggestions .magic-box-suggestion:first-child {
  border-top: none;
}

.magic-box .magic-box-suggestions .magic-box-suggestion.magic-box-selected {
  background: #e0f7fa !important;
}

.magic-box .magic-box-suggestions .magic-box-suggestion:hover {
  background: #f5f5f5;
}

.magic-box.magic-box-inline {
  overflow: hidden;
}

.magic-box.magic-box-inline .magic-box-suggestions.magic-box-hasSuggestion {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  border: none;
  border-top: 1px solid #9e9e9e;
}

.CoveoSearchButton {
    color: white;
    border: 1px solid #bcc3ca;
    border-left: none;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    height: 50px;
    width: 60px;
    cursor: pointer;
    line-height: 0;
}

.coveo-after-initialization meter, .coveo-after-initialization progress, .coveo-after-initialization input, .coveo-after-initialization textarea, .coveo-after-initialization keygen, .coveo-after-initialization select, .coveo-after-initialization button {
    display: inline-block;
}

.CoveoSearchButton:before {
    content: none!important;
    // content: '';
    // vertical-align: middle;
    // height: 100%;
    // display: inline-block;
}

.coveo-visible-to-screen-reader-only {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

/* OVERRIDES */

.coveo-search-section {
    display: flex;
    padding: 0;
}

.coveo-search-section .CoveoSearchbox {
    flex: 1;
    //margin-right: 10px;
}

.coveo-search-section .CoveoSearchButton {
    //margin-left: 10px;
    margin-right: 10px;
}




$inputHeight: 43px;
$btnHeight: 45px;


/* Search in the mobile nav */ 
.global-nav-form { 
    @media screen and (max-width: $mq-desktop-sm) {
        .coveo-search-section {
            //flex-direction: column;
            margin: 10px;
            padding: 0;
        }
        .CoveoSearchButton {
            height: $btnHeight;
            //margin: 10px 0 0 0;
        }
        .CoveoSearchbox {
            margin-right: 0;
        }
        .CoveoSearchInterface {
            min-width: 0;
        }
    }
}



.magic-box .magic-box-input {
    height: $inputHeight;
}

.magic-box .magic-box-input .magic-box-underlay, .magic-box .magic-box-input > input {
    // padding-top: 6px;
    // padding-bottom: 6px;
    height: $inputHeight;
    box-sizing: border-box;
}

.magic-box .magic-box-input > input {
    background-color: $lightBlueGray;
}

.CoveoSearchButton {
    height: $btnHeight;
}

.magic-box .magic-box-clear {
    line-height: $inputHeight;
}

/* Aligning with search breakpoint */


.search-hide-desktop {
    @media screen and (min-width: 882px) {
        display: none !important;
    }
}