
//note: i didn't want to rebuild the widget for this because it would take to long to update all the instances in the cms. Had to just work with the markup in place.

.map-block__button {
	// border: none;
	// background: none;
	// color: $primary;
	// font-size: .9em;
	// padding: 0;
	// .icon {
	// 	vertical-align: middle;
	// }
	// margin-bottom: 1em;
	@extend .btn;
	@extend .btn-link;
	padding-left: 0;
	display: flex;
	align-items: center;
	font-weight: 800;
	@include r-font-size(18,24);
	color: $septenary;
	text-decoration: underline $septenary;

	.icon-map{
		margin-right: .5rem;
	}
}

// .map-block-container {
// 	padding: 1em;
// 	.truncatedStory + & {
// 		@media screen and (min-width: $mq-tablet-portrait){
// 			padding:  0 0 1em;
// 		}
// 	}
// 	@media screen and (min-width: $mq-tablet-portrait){
// 		padding:  .5em 0 1em;
// 	}
// }

// .map-block__map {
// 	max-width: 100%;
// 	overflow: hidden;
// }