.trip-planner__section-heading {
	display: inline-block;
	margin-top: 0;
	font-size: 3em;
	line-height: normal;
	vertical-align: baseline;
	@media screen and (min-width: $mq-mobile-landscape) {
		margin-right: 50px;
		margin-bottom: 20px;
	}
}

.trip-planner__section {
	box-sizing: border-box;
	margin-top: 20px;
	padding: 0;
	text-align: center;
	@media screen and (min-width: $mq-mobile-landscape) {
		text-align: left;
	}
}

.trip-planner__button-container {
	margin: 0 15px;
	box-sizing: border-box;
	@media print {
		display: none;
	}
}

.trip-planner__button-wrapper {
	display: inline-block;
	position: relative;
}

.trip-planner__button {
	/*box-sizing: border-box;
	display: inline-block;
	margin: 5px 0;
	height: 32px;
	line-height: 32px;
	//Give the illusion of text being centered including shadow
	padding: 2px 8px 0;
	text-align: center;
	&.button--disabled {
		padding-top: 0;
	}*/
}

.trip-planner__button--block {
	margin: 15px 0;
	width: 100%;
	box-sizing: border-box;
	@media screen and (min-width: $mq-mobile-landscape) {
		margin-left: 10px;
		margin-right: 10px;
		width: auto;
	}
	& + & {
		@media screen and (min-width: $mq-desktop) {
			margin-left: 75px;
			margin-right: 0;
		}
	}
	@media print {
		display: none;
	}
}

.trip-planner__button--load {
	display: block;
	width: 150px;
	margin: 0 auto 50px;
}

.trip-planner__print {
	margin: 15px;
	box-sizing: border-box;
	display: inline-block;
	height: 32px;
	line-height: 32px;
	text-align: center;
	&:link, &:visited {
		text-decoration: none;
	}
}

.trip-planner__map {
	margin: 0 auto 50px;
	border: 1px solid $gray-700;
	width: 80%;
	overflow: hidden;
	box-sizing: border-box;
	text-align: left;
    height: 666px;
	@media screen and (min-width: $mq-mobile-landscape) {
		max-width: 1175px - 2px;
		width: 100%;
		margin-bottom: 50px;
	}
	@media print {
		display: block !important;
	}
}

.trip-planner__header {
	box-sizing: border-box;
	width: 100%;
	//background-color: #372012;
	background-image: url('/Images/trip-planner/trip-planner-header-small.jpg');
	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Images/trip-planner/trip-planner-header-small.jpg', sizingMethod='scale')";
	background-size: cover;
	background-position: center;
	background-position: top center;
	height: 0;
	padding-bottom: 53.5%;
	margin-bottom: 50px;
	.breadcrumbs {
		color: #fff;
		margin: 15px;
		a:link, a:visited {
			color: #fff;
		}
	}
	@media print {
		display: none;
	}
	@media screen and (min-width: $mq-tablet-portrait) {
		background-image: url('/Images/trip-planner/trip-planner-header.jpg');
		-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/Images/trip-planner/trip-planner-header.jpg', sizingMethod='scale')";
		padding-bottom: 24.48%;
	}
}

.trip-planner__title {
	color: #f17c0a;
	font-size: 3.5em;
}

.trip-planner__directory-container {
	position: relative;
}

// Print Styles
.printStyles{
  font-family: arial, sans-serif; 
  background: #fff; 
  padding: 1em;
  h1{font-family: arial, sans-serif; }
  .printWrapper{margin: 1em auto;width: 900px;} 
  .printTrip{
    width: 100%;
    .placeDetail{width: 100%;}
    .travelPlanningMap{
      height: 400px;
      .MicrosoftMap{
        width: 100%;
      }
    }
    .tripList{
      float: none;
      width: 100%;
    } 
  }
}

.trip-planner__btn-fav-small{
	max-width: 80px;
}