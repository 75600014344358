

.noBreak{
	white-space:nowrap;
}


///*--------------------------------------------------------------------------
//  Dropmenu that isn't nested (can be resused throughout site)
//  Add topLevel & dropdown class to parent li, Add dropMenu to child ul
//--------------------------------------------------------------------------*/
.drop-menu__content {
	background:#fff;
	color: #5D5D5D;
	left:-9999px;
	position:absolute;
	padding:10px 0 30px 0;
	width:300px;
	z-index:6;
	li {
	margin:0 6%;
	width:88%;
	}
}

.drop-menu {
	position:relative;
	&.hovered {
	background:#fff;
	a, a:link, a:visited {color:$primary;}
	}
}

.drop-menu--email {
	input[type=text] {
	font-size:0.9em;
	width:70%;
	display: inline-block;
	}
	form {width:100%;}
}





// Styling for Social Login.  Due to nesting... has to be nested.
//Janrain styling
.userNav ul li ul li.social-login__item {
	margin:0 auto !important;
	width:277px;
	.socialLogin__link   {   
	margin   :  7px 2px 0;
	padding  :  0;

	.iconFont   {
		color: #fff;
		background: $darkNeutral;
		float:left;
		font-size: 1.3em;
		line-height: 1;   
		margin   :  0; 
		padding  :  0;
		border-radius: 4px;
	}
	}
	.social-login__button {
	border:1px solid #444;
	border-radius: 3px;
	overflow:hidden;
	padding: 2px 5px; 
		width:76px;
	.social__text {
		float:left;
		margin:0 0 0 5px;
	}
	}
}
.social-login-button {
	text-align:center;
	.socialLogin__link {
		font-weight:bold;
		font-size:0.8em;
		margin   :  7px 2px 0;
		padding  :  0;
		.iconFont   {
			color: #fff;
			background: $darkNeutral;
			float:left;
			font-size: 1.3em;
			line-height: 1;   
			margin   :  0; 
			padding  :  0;
			border-radius: 4px;
		}
		&:link, &:visited, &:hover {color:#5A5A5A;}
	}
	.social-login__button {
		background:#fff;
		border:1px solid #444;
		overflow:hidden;
		padding: 2px 5px; 
		width:76px;
		.social__text {
			float:left;
			margin:0 0 0 5px;
		} 
		display: inline-block; 
		border-radius: 3px;
	}
}
.sharingWidgets {
	display:block;
	float:left;
	margin-right:2px;
}