
.featured-deals,
.dynamic-deals{
    background-color: $tertiary;
    padding: 3rem 3rem 5rem;
    //max-width: unset!important;
    position: relative;

    h2{
        text-transform: uppercase;
        color: white;
        @include r-font-size(56,80);
    }

    &::before{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 15px;
		content: " ";
		display: block;
		background: rgba(255,255,255,.5);
		z-index: 2;
	}

    &::after{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 11px;
		content: " ";
		display: block;
		background: linear-gradient(to left, $secondary 80%, $quaternary 20%);
		z-index: 2;
	}
}