.categories-slider{

	.slick-track{
		display: flex;
	}

	.categories-item{
		height: inherit;
		background-color: $tertiary;

		.categories-header{
			position: relative;
			&::after{
				content: '';
				position: absolute;
				top: -.5rem;
				left: 0;
				right: 0;
				width: 100%;
				height: .5rem;
				background-color: $tertiary;
				opacity: .6;
			}
		}

		// border-bottom-left-radius: .5rem;
		// border-bottom-right-radius: .5rem;

		// &:nth-child(3n+1){
		// 	background-color: $quaternary;
		// }
		// &:nth-child(3n+2){
		// 	background-color: $senary;
		// }
		// &:nth-child(3n+3){
		// 	background-color: $tertiary;
		// }
	}
	
}