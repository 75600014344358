// .seasonal-grid-wrapper{
//     border: 1px solid blue;
// }
.seasonal-grid{
	//border: 1px solid pink;

	&-toggle{
		//https://getbootstrap.com/docs/5.0/components/buttons/#mixins
		.btn-seasonal{
			@include button-variant($tertiaryLighter, $tertiaryLighter, lighten($septenary, 5%), lighten($septenary, 5%), $septenary, $septenary);
			color: $primary;
			.icon-active{
				display: none;
			}
			.icon-inactive{
				display: block;
			}
			&.active{
				.icon-active{
					display: block;
				}
				.icon-inactive{
					display: none;
				}
			}
		}
	}

	.toggle__section {
		opacity: 0;
		height: 0;
		overflow-y: hidden;
		transition: opacity 0.4s ease-in-out;
	}
	
	.toggle__section--animate {
		height: auto;
		opacity: 1;
	}

	// &-item{
	// 	//border: 1px solid orange;
	// 	width: 100%;

	// 	@include media-breakpoint-up(md) {
	// 		width: 33.33%;
	// 	}

	// 	img{
	// 		width: 100%;
	// 	}

	// 	// &--video{
	// 	//     border: 1px solid red;
	// 	// }
	// }
}