///*-------------------------------------------------------------------------- 
//  Menu Styles and Header styles
//--------------------------------------------------------------------------*/

@mixin nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  >li{
    
    >a{
      display: block;
      text-decoration: none;
    }
  }
}


.global-nav{
    display: none;
	position: absolute;
	top: 75px;
	left: -300px;
	background-color: $secondary;
	width: 300px;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            position: relative;
            top: 0;
            left: 0;
            width: auto;
            display: block;
            float: right;
            margin-top: 15px;
        }
    }
    &.visible{
        display: block;
    }
    .icon{
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                display: block;
            }
        }
    }
	@media print {
		display: none;
	}
}


.global-nav__primary-list{
    @include nav-list;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            float: right;
        }
    }
}
.global-nav__primary-item{
    .global-nav__primary-link {
        font-family: $font-family-base;
        font-weight: 800;
    }
    border-top: 1px solid $reverseOut;
    position: relative;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            border-top: none;
            display: inline-block;
        }
        
    }
}


.global-nav__primary-link{
    //font-family: 'Oswald', sans-serif;
    padding: 15px 23px;
    position: relative;
    &:link,
    &:visited{
        color: $reverseOut;
    }
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            margin-bottom: 15px;
        }
    }
    .no-menu-hover &,
    .menu-shown &{
        &:before{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
             @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    border-bottom: 3px solid $reverseOut;
                }
            }
        }
    }
}


.global-nav__dropdown{
    display: none;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            overflow: hidden;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    > .global-nav__dropdown-list{
        display: block;
        &.-flex {
            @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    display: flex;
                }
            }
        }
        &.-rows{
            //border: 1px solid red;
            @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    flex-wrap: wrap;
                }
            }
        }
    }
    &.-small-dropdown{ 
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                @include center--horz;
                width: 200px;
            }
        }
    }
    &.-three-level,
    &.-four-level,
    .events &,
    &.-trips{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                position: absolute;
            }
        }
    }
    &.-three-level{
        @include media-breakpoint-up(xl) {
           body:not(.force-mobile) & {
               background: $reverseOut;
               width: 700px;
               height: 400px;
           }
       }
   }
   &.-four-level{
         @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background: $reverseOut;
                width: 820px;
                height: 600px;
            }
        }
        .two &{
            @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    width: 1175px;
                }
            }
        }
    }
    .events &{
		@include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                width: 550px;
                height: 350px;

                //&.-three-level {
                    .-secondary{
                        //border: 1px solid blue!important;
                        width: 350px!important;
                        height: 350px;
                    }
                //}
            }
            
		}
    }
    &.-one-level{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                //background-image: url(/images/public/trip-ideas-menu-bg.jpg);
                //background-color: lighten($lightSlider, 50%);
                background: lighten($neutral, 2);
                width: 825px;//1175px;
                height: 410px;
            }
        }
    } 
    &.-four-horizontal {
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background: lighten($neutral, 2);
                width: 825px;
                height: 220px;
                @include center--horz; 
            }
        }
    }
    &.-five-horizontal {
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background: lighten($neutral, 2);
                width: 1000px;
                height: 220px;
                @include center--horz; 
            }
        }
    }
    &.-wisfunsin {
        img{
            display: none;
        }
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background: lighten($neutral, 2);
                width: 800px;
                height: auto;
                @include center--horz;
            }
            img{
                display: block;
            }
        }
    }
    .-newsletter &,
    .favorites &{
        font-size: 1em;
        padding: 1em;
        @include center--horz();
    }
    .-newsletter &{
        background: $reverseOut;
        width: 400px;
    }
    .favorites &{
        width: 500px;
        background: lighten($lightSlider, 50%);
    }
    .login &{
        width: 700px;
        @include center--horz();
        @extend .clearfix;
        background: lighten($lightSlider, 50%);
        .loginUtility{
            float: left;
            margin: 0; 
            padding: 2em;
            width: 50%;
            box-sizing: border-box;
            
        }
        .createAccountUtility{
            margin: 0; 
            padding: 2em;
            width: 50%;
            float: right;
            box-sizing: border-box;
            background: lighten($lightSlider, 55%);
        }
    }
    .searchForm{
		font-size: .9em;
		padding: 1em 6%;
		text-align: left;
		@extend %formStyles;
        @extend .clearfix;
		box-sizing: border-box;
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                padding: 3em 6%;
                height: 600px;
            }
        }
		label, h3{
			color: $primary;
			margin: 0;
			text-transform: none;
		}
		h3{font-size: 1.4em;}
		label{font-size: 0.95em;}
		.searchSbmt, .drop-down__view-all{
			float: left;
			margin-top: 1em;
			text-align: center;
			width: 30%;
			@include roundBtn($primary, 1em, .5em);
		}
		.drop-down__view-all{
			margin-left: 0.5em;
		}
	}
    &.-columns{
        @extend .clearfix;
    }
}

.global-nav__dropdown-col1,
.global-nav__dropdown-col2{
    font-size: 1.2em;
    float: left;
    padding: 2em;
    text-align: left;
    width: 50%;
    box-sizing: border-box;
    .btn-holder{
        text-align: right;
    }
}
.global-nav__dropdown-col2{
    background: lighten($lightSlider, 60%);
}
.global-nav__col-btn{
    @include roundBtn($primary, 1em, 1em);
    border-radius: 3px;
    border: none;
    display: inline-block;
    &:link, &:visited{
        color: $reverseOut;
    }
}

.social-login__button{
    width: 52px;
    height: 52px;
    &.facebook{ 
        background-image: url("/Images/public/social/facebook-footer-over.png");
    }
    &.google{
        background-image: url("/Images/public/social/google-footer-over.png");
    }
    &.twitter{
        background-image: url("/Images/public/social/twitter-footer-over.png");
    }
    .social__text{
        @extend .sr-only;
    }
}

.social-login__link {
	display: block;
}

.global-nav__dropdown-list{
    display: none;
    &.-primary{
        background: lighten($primary, 10%);
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                //background: lighten($lightSlider, 50%);
                background: lighten($neutral, 2);
            }
        }
    }
    &.-secondary{
        background: lighten($lightSlider, 50%);
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background: lighten($lightSlider, 60%);
            }
        }
        .one &{
            @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    display: none;
                }
            }
        }
    }
    &.-tertiary{
        background: lighten($lightSlider, 80%);
    }
    .-four-level &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                position: absolute;
                top: 0;
                height: 100%;
            }
        }
        &.-primary{
             @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    left: 0;
                    width: 200px;
                    //box-shadow: 0px 0px 10px 0px $lightSlider;
                }
             }
        }
        &.-secondary{
             @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    left: 200px;
                    width: 300px;
                    box-shadow: inset 10px 0px 10px -10px $lightSlider;
                }
            }
        }
        &.-tertiary{
             @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    left: 300px;
                    width: 250px;
                    box-shadow: inset 10px 0px 10px -10px $lightSlider;
                }
            }
        }
    }
    .-three-level &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                position: absolute;
                top: 0;
                height: 100%;
            }
        }
        &.-primary{
             @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    left: 0;
                    width: 200px;
                    //box-shadow: 0px 0px 10px 0px $lightSlider;
                }
             }
        }
        &.-secondary{
             @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    left: 200px;
                    width: 500px;
                    box-shadow: inset 10px 0px 10px -10px $lightSlider;
                }
            }
        }
        &.-tertiary{
             @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    left: 300px;
                    width: 250px;
                    box-shadow: inset 10px 0px 10px -10px $lightSlider;
                }
            }
        }
    }
    .-one-level &{
         background: lighten($primary, 10%);
           @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background: none;  
                width: 720px;
                padding: 2em 0 2em 2em;
            }
           }
    }
    .hovered > &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                display: block;
            }
        }
    }
    .one &,
    .three &{
        &.-primary{
             @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    box-shadow: 0px 0px 10px 0px $lightSlider;
                }
             }
        }
    }
}


// Setting decorative arrows - will only work if transforms are available

    .global-nav__dropdown-item.hovered{
        background-color: $alt-Accent;
        > .global-nav__dropdown-link{
            @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    font-weight: bold;
                }
            }
            .global-nav__right-arrow{
                display: none;
                @include media-breakpoint-up(xl) {
                    body:not(.force-mobile) & {
                        display: flex;//block;
                    }
                }
                
            }
        }
    }

    .-secondary .global-nav__dropdown-item.hovered{
        > .global-nav__dropdown-link{
            .global-nav__right-arrow{
                &:after{
                    background: lighten($lightSlider, 60%);
                }
            }
        }
    }
    .-tertiary .global-nav__dropdown-item.hovered{
        > .global-nav__dropdown-link{
            .global-nav__right-arrow{
                display: none;
            }
        }
    }


.global-nav__search{
    background: lighten($lightSlider, 50%);
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            width: 400px;
        }
    }
}


.global-nav__dropdown-item{
    border-top: 1px solid $reverseOut;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            border-top: none;
        }
    }
    .-tertiary &{
        border-top: 1px solid lighten($lightSlider, 50%);
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                border-top: none;
            }
        }
    }
    .-one-level &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                float: left;
                width: 33%;
            }
        }
    }
    .-rows &{
        //flex: 1 1 160px;
        width: auto;
        @include media-breakpoint-up(xl) {
            width: 24%;
        }
    }

    .global-nav__right-arrow{
        width: 50px;
        height: 50px;
        @include center--vert;
        overflow: hidden;
        left: calc(100% - 50px);//100%
        z-index: 3;
        display: none;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: end;
    }
}


.global-nav__dropdown-link{
    position: relative;
    padding: 10px 25px 10px 75px;
    display: block;
    line-height: 1.3;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            padding-left: 25px;
            padding-right: 20px;
        }
    }
    &:link,
    &:visited{
        color: $reverseOut;
        text-decoration: none;
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                color: $primary;
            }
        }
    }
    .-secondary &,
    .-tertiary &
    {
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                padding: 5px 40px 5px 20px;
            }
        }
        &:link,
        &:visited{
           color: #444;
        }
    }
    .-secondary &{
        padding-left: 95px;
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                padding-left: 40px;
            }
        }
    }
    .-has-dropdown > &{
        &:after{
            display: none;
            content: "";
            z-index: 8;
            width: 0; 
	        height: 0; 
	        border-top: 6px solid transparent;
	        border-bottom: 6px solid transparent;
	        border-left: 6px solid $primary;
            @include center--vert;
            right: 15px;
            @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    display: block;
                }
            }
        }
    }
    .-one-level &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                font-weight: bold;
                padding-left: 55px;
                padding-top: 25px;
                padding-bottom: 25px;
                background-position: left center;
                background-repeat: no-repeat;
                background-image: url(/images/public/icons/menu-icon-sun.png);
            }
        }
        
        &:hover{
            @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    text-decoration: underline;
                }
            }
        }
    }
    .icon-1 &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-boot.png);
            }
        }
    }
    .icon-2 &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-fence.png);
            }
        }
    }
    .icon-3 &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-camper.png);
            }
        }
    }
    .icon-4 &{
         @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-wine.png);
            }
         }
    }
    .icon-5 &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-bag.png);
            }
        }
    }
    .icon-6 &{
         @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-golf.png);
            }
         }
    }
    .icon-7 &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-getaway.png);
            }
        }
    }
    .icon-8 &{ 
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-music.png);
            }
        }
    }
    .icon-9 &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-fall.png);
            }
        }
    }
    .icon-10 &{ 
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-snow.png);
            }
        }
    }

    .icon-11 &{ 
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-pet.png);
            }
        }
    }
	.icon-12 &{ 
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-spring.png);
            }
        }
    }
	
	.icon-12 &{ 
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-spring.png);
            }
        }
    }
	
	.icon-13 &{ 
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-sun.png);
            }
        }
    }
    .icon-14 &{ 
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background-image: url(/images/public/icons/menu-icon-design.png);
            }
        }
    }
}


.global-nav__menu-subheading{
    display: none;
    color: #444;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            display: block;
            padding-left: 40px;
            padding-right: 20px;
        }
    }
}
.global-nav__img-window{
    display: none;
    box-sizing: border-box;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            width: 610px;
            padding: 2em;
            position: absolute;
            left: 210px;
            top: 0;
            height: 100%;
            background: $reverseOut;
        }
    }
    
        .two &{
            body:not(.force-mobile) & {
                width: 675px;
                left: 300px;
                box-shadow: inset 10px 0px 10px -10px $lightSlider;
            }
        }
        .two .-tertiary &{
            body:not(.force-mobile) & {
                box-shadow: none;
                width: 425px;
                left: 250px;
            }
        }
        .-one-level &{
            body:not(.force-mobile) & {
                width: 400px;
                left: auto;
                right: 0;
                background: none;
            }
        }
        .four &{
            @include media-breakpoint-up(xl) {
                body:not(.force-mobile) & {
                    left: 400px;
                }
            }
        }
    // This would be considered bad practice to nest selectors like this - but this menu has too many unique dropdowns.
    // Forgive me.
    .one .hovered > &,
    .four.menu-shown &,
    .three .hovered > &,
    .two .hovered .hovered > &,
    .-one-level.shown &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                display: block;
            }
        }
    }
    .two .hovered .hovered.-has-dropdown > &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                display: none;
            }
        }
    }
    .one .global-nav__dropdown-item:first-child &,
    .two .global-nav__dropdown-item:first-child .global-nav__dropdown-item:first-child &,
    .three .global-nav__dropdown-item:first-child &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                display: block;
            }
        }
    }
} 
.global-nav__img-group{
    width: 43%;
    float: left;
    margin: 0 1em;
    .two .-tertiary &{
        width: 90%;
        a{
            height: 60px;
        }
    }
    .seven &{
        width: 90%;
    }
    img{
        display: block;
        width: 100%;
        height: auto;
    }
    a{
        display: block;
        height: 75px;
        padding-top: 5px;
        text-decoration: none;
        color: $specialCallout;
        line-height: 1.1;
    }
}
.global-nav__expander{
    float: left;
    position: relative;
    padding: 15px;
    background: none;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: none;
    box-shadow: none;
    line-height: 18px;
    vertical-align: middle;
    z-index: 2;
    &:focus{
        outline: none;
    }
    &:before{
        content: "+";
        display: block;
        border: 1px solid $reverseOut;
        width: 18px;
        height: 18px;
        color: $reverseOut;
        position: relative;
        top: 50%;
        margin: 0;
        padding: 0;
        vertical-align: middle;
    }
    &.menu-shown:before{
        content: "-";
    }
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            display: none;
        }
    }
    .-secondary &,
    .-tertiary &{
        &:before{
            border: 1px solid lighten($primary, 10%);
            color: lighten($primary, 10%);
        }
    }
    .-primary &{
        margin-left: 20px;
    }
    .-secondary &{
        margin-left: 40px;
    }
}
.global-nav__form{
    font-size: 1.2em;
    @extend %formStyles;
    input[type="submit"]{
        border-radius: 3px;
        width: 27%;
        padding-top: 0.67em;
        padding-bottom: 0.67em;
    }
    input[type="text"]{
        box-shadow: none;
        border: 1px solid $midNeutral;
        width: 68%;
    }
}

.global-nav__icon-list{
    //@include nav-list;
    >li{
        >a{//jkjk
            // display: block;
            display: flex;
            //align-items: center;
            text-decoration: none;
        }
    }
    background: $primary;
	//list-style-type: none;
	position: relative;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            display: flex;
            background: none;
            //float: left;
            //width: 65%;
        }
    }
    .global-header &{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                display: none;
            }
        }
    }
    .global-nav__expander{
        display: none;
    }
}


.global-nav__icon-item{
    border-top: 1px solid $reverseOut;
    position: relative;
    @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            //float: left;
            font-size: 0.9em;
            //width: 18%;
            width: auto;
            text-align: center;
            border-top: none;
            border-right: 1px solid $gray-300;//was $lt-grey;
            padding: 0 1rem;
        }
    }
    // &.-short-item{
    //     @include media-breakpoint-up(xl) {
    //         body:not(.force-mobile) & {
    //             width: 12%;
    //         }
    //     }
    // }
    &.hide-on-desktop{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                display: none;
            }
        }
    }
}


.global-nav__icon-link{

    font-family: $font-family-base;
    font-weight: 800;
    padding: 15px;
     @include media-breakpoint-up(xl) {
        body:not(.force-mobile) & {
            //padding: 5px 0;
            padding: 0;
            height: 100%;
            display: flex;
            align-items: center;
        }
     }
    .menu-shown &{
         @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                background: lighten($lightSlider, 63%);
            }
         }
    }
    &:link,
    &:visited{
        color: $reverseOut;
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                color   :  $secondary;//$darkNeutral;
            }
        }
    }
    &.-callout{
        &:link,
        &:visited{
            color: $quaternaryLight;//was $callout
        }
    }
    .icon{
        @include media-breakpoint-up(xl) {
            body:not(.force-mobile) & {
                display: block;
                float: none;
                //margin: 0 auto 5px;
                margin: 0 .25rem 0 0;
            }
        }
    }
    // .icon-mail{
    //     // width: 18px;
    //     // @include media-breakpoint-up(xl) {
    //     //     body:not(.force-mobile) & {
    //     //         margin-top: 5px;
    //     //         margin-bottom: 8px;
    //     //     }
    //     // }
    // }
    // .icon-person{
    //     // @include media-breakpoint-up(xl) {
    //     //     body:not(.force-mobile) & {
    //     //         margin-top: 4px;
    //     //         margin-bottom: 5px;
    //     //     }
    //     // }
    // }
    // .icon-deal{
    //     // @include media-breakpoint-up(xl) {
    //     //     body:not(.force-mobile) & {
    //     //         margin-top: 4px;
    //     //         margin-bottom: 5px;
    //     //     }
    //     // }
    // }
    // .icon-heart{
    //      @include media-breakpoint-up(xl) {
    //         body:not(.force-mobile) & {
    //             margin-top: 5px;
    //             margin-bottom: 5px;
    //         }
    //     }
    // }
}

/* Adding style for favorites dropdown */
.global-nav__fav-list{
    text-align: left;
    margin: 0;
    padding: 0;
    max-height: 500px;
    overflow-y: auto;
}
.global-nav__fav-listitem{
    padding: 1.5em 1em;
    border-bottom: 1px solid $midNeutral;
}
.global-nav__fav-list-text{
    display: inline-block;
    font-size: 1.3em;
    font-weight: normal;
    a{
        text-decoration: none;
        color   :  $darkNeutral;
    }
}
.global-nav__fav-remove{
    float: right;
    display: inline-block;
    padding: 0.15em 0.3em;
    margin-top: 10px;
    background: $reverseOut;
    border: 1px solid $midNeutral;
    text-decoration: none;
    span{
        color: red;
        text-transform: uppercase;
    }
}

.global-nav__fav-link {
	text-align: left;
	font-size: 1.4em;
	&:link, &:visited {
		color: $primary;
	}
}


/*Nav Button*/
.navbar-toggle {
  background: none;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: none;
  box-shadow: none;
  display: inline-block;
  vertical-align: top;
  //@include center--vert;

  &:focus,
  &:hover,
  &:active{
    outline: 0;
    background-color: none;
    box-shadow: none;
  }
  /*@include respond(less-than-stack){
    right: 0;
  }*/

  // Bars
  .bar-holder{
    margin-top: 4px;
    position: relative;
  }
  .bar-holder__label {
      font-size: .75em;
      color: #fff;
  }
  .icon-bar {
    display: block;
    position: relative;
    width: 29px;
    height: 2px;
    border-radius: 2px;
    background: $reverseOut;
    -webkit-transition: background 0 0.3s;
    transition: background 0 0.3s;
    &.first,
    &.last{
      -webkit-transition-duration: 0.3s, 0.3s;
      transition-duration: 0.3s, 0.3s;
      -webkit-transition-delay: 0.3s, 0;
      transition-delay: 0.3s, 0;
    }
    &.first{
      -webkit-transition-property: -webkit-transform;
      transition-property: top, transform;
    }
    &.first{
      -webkit-transition-property: -webkit-transform;
      transition-property: bottom, transform;
    }
  }
  &.menu-shown .icon-bar{
    &.mid{
      background: transparent;
    }
    &.first,
    &.last{
      -webkit-transition-delay: 0, 0.3s;
      transition-delay: 0, 0.3s;
    }
    &.first{
      top: 8px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &.last{
      bottom: 8px;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  .icon-bar + .icon-bar {
    margin-top: 6px;
  }
}






.global-nav__dropdown{
    .item-block{
       // border: 1px solid pink;
        margin: 0 1rem 1rem 0;
        a:visited,a:link{
            text-decoration: none;
            display: flex;
            flex-direction: column;
            color: $primary;
            span{
                font-weight: 700;
            }
            img{
                width: 140px;
                //max-height: 95px;
            }
        }
        a:hover,a:active{
            color: darken($primary, 15%);
        }
    }
}