.footer-nav {
	box-sizing: border-box;
	display: block;
	padding: 0;
	margin: 0;
	list-style-type: none;
	//border-top: 1px solid #424242;
	// margin-top: 15px;
	// & + & {
	// 	margin-top: 0;
	// }
	@media screen and (min-width:$mq-desktop-md) {
		width: 188px;
		//float: left;
		//border: none;
		text-align: left;
		//padding: 10px;
		//padding-top: 23px;
		//margin-top: 0;
	}
}

.footer-nav__item {
	display: block;
	margin: 7px 10px;
	@media screen and (min-width:$mq-desktop-md) {
		margin: 2px 0;
	}
}

.footer-nav__item--banner {
	display: block;
	@media screen and (min-width:$mq-desktop-md) {
		margin: 10px 0;
	}
	&:first-child {
		margin: 0 0 10px;
	}
}

.footer-nav__link {
	&:link, &:visited {
		color: #d9d9d9;
	}
	text-decoration: none;
	font-weight: bold;
	@media screen and (min-width:$mq-desktop-md) {
		font-weight: normal;
	}
	img {
		max-width: 100px; 
	}
}