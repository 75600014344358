$infoHei: 500px;
$infoHeiMobile: 300px;
$infoBtnHei: 75px;

.listing-nearby{
    .btn-secondary:not(:disabled):not(.disabled).active{
        background-color: $septenary;
        border-color: $septenary;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // &:hover{
        //     background-color: $red;
        // }
    }
    &-header{
        min-height: 60px;
        position: relative;
        &--title{
            &::before{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 11px;
                height: 100%;
                content: " ";
                display: block;
                background: linear-gradient(to bottom, $senary 80%, $quaternary 20%);
                z-index: 2;
            }
        }
    }

    &-map,
    &-info {
        height: $infoHeiMobile;
        @include media-breakpoint-up(md) {
            height: $infoHei;
        }
    }

    &-info{
        &-scroll{
            //border: 1px solid pink;
            overflow-y: scroll;
            height: calc(#{$infoHeiMobile} - #{$infoBtnHei});
            @include media-breakpoint-up(md) {
                height: calc(#{$infoHei} - #{$infoBtnHei});
            }
        }
        &-btn{
            border-top: 1px solid $gray-400;
            height: $infoBtnHei;
        }
    }

    .map-location{
        &-badge{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            background-color: $secondary;
        }

        &:hover{
            .map-location-badge{
                background-color: $quaternary;
            }
        }
    
        &-info{
            position: relative;
            margin-bottom: 3rem;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: $gray-400;
                margin: 1rem 0;
                bottom: -2.25rem;
                left: 0;
            }
        }
    }

    
}



.listing-map{
    height: $infoHeiMobile;
    @include media-breakpoint-up(md) {
        height: $infoHei;
    }
}


.listing-header {
	position: relative;
	&::before{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 11px;
		height: 100%;
		content: " ";
		display: block;
		background: linear-gradient($senary 85%, $quaternary 15%);
		z-index: 2;
	}
}

.wonder-of-wi-callout {
    .listing-accent-border-left {
        position: relative;
        &:before {
            content: " ";
            position: absolute;
            display: block; 
            z-index: 2;
            height: 100%;
            left: 0;
            top: 0;
            width: calcRem(4);
            background: linear-gradient($quaternary 0, $quaternary 80%, $senary 80%, $senary 100%);		
        }
    }

    .campaign-hero-logo {
        width: 100px;
    }
}

.wonders-hero-logo {
    width: 85px;
    transition: $transition-base;

    &:hover {
        transform: scale(1.25);
    }

    @include media-breakpoint-up(lg) {
        width: 115px;
    }        
}
