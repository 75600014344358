

.truncatedStory{
    h1,h2,h3,h4,h5,h6{
        color: $primary;
    }
    h2{
        @include r-font-size(36,56);//56,80);
        &:not(:first-of-type){
            margin-top: 2rem;
        }
    }
    h3{
        @include r-font-size(24,36);//42,56);

        &:not(:first-of-type){
            margin-top: 2rem;
        }
    }

    h4{
        @include r-font-size(22,24);//(32,42);

        &:not(:first-of-type){
            margin-top: 1.5rem;
        }
    }



    figure{
        margin: .5rem 0;
        width: 100%;
        text-align: center;
        img{
            margin: 0 auto;
            width: 100%;
            max-width: 800px;
        }
        figcaption{
            text-align: center;
            margin: .35rem 0 1rem;
            font-size: .875rem;
            color: $body-color;
        }
    }


    a:link, a:visited {
		//font-weight: bold;
        &:not(.btn-light){
            color: $septenary;
            text-decoration: underline $septenary;
        }
	}
	a:active, a:hover {
        &:not(.btn-light){
            color: darken($septenary,10);
            text-decoration: underline darken($septenary,10);
        }
	}


}


