/*Assorted Extends and Mixin Effects*/

%contentList{
	h2{
		font-size: 1.1em;

	}
	ul{
		margin: 0;
		padding: 0;
		list-style-type: none;
		li{
			@extend .clearfix;
			margin: 10px 0;
		}
		img{
			max-width: 100%;
		}
		h3{
			font-size: .9em;
			margin: 5px 0 0 0;
		}
		p{
			font-size: .8em;
			color: $darkNeutral;
			line-height: 1.2;
			margin-top: 0;
		}
		&.thumbnails{
			figure{
				float: left;
				width: 20%;
			}
			h3{
				float: right;
				width: 75%;
			}
		}
	}
	a{
		&:link, &:visited{
			color: $primary;
			text-decoration: none;
			&.viewAll{
				text-decoration: underline;
			}
		}
	}
	.viewAll{
		font-size: .8em;
		float: right;
		margin: 15px 15px 0 0;
	}
}

// .listNav{
// 	margin: 0;
// 	padding: 0;
// 	list-style-type: none;
// }

// .hideForMobile{
// 	display: none;
// 	@media screen and (min-width: $mq-tablet-portrait){
// 		display: block;
// 	}
// }

// /* Text meant only for screen readers. */
// .screen-reader-text {
// 	clip: rect(1px, 1px, 1px, 1px);
// 	position: absolute !important;
// 	height: 1px;
// 	width: 1px;
// 	overflow: hidden;

// 	&:hover,
// 	&:active,
// 	&:focus {
// 		background-color: $reverseOut;
// 		border-radius: 3px;
// 		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
// 		clip: auto !important;
// 		color: $promoBlack;
// 		display: block;
// 		font-weight: bold;
// 		height: auto;
// 		left: 5px;
// 		line-height: normal;
// 		padding: 15px 23px 14px;
// 		text-decoration: none;
// 		top: 5px;
// 		width: auto;
// 		z-index: 100000; /* Above WP toolbar. */
// 	}
// }

// .sr-only {
//   position: absolute;
//   width: 1px;
//   height: 1px;
//   margin: -1px;
//   padding: 0;
//   overflow: hidden;
//   clip: rect(0,0,0,0);
//   border: 0;
// }

// .sr-only-focusable {
//   &:active,
//   &:focus {
//     position: static;
//     width: auto;
//     height: auto;
//     margin: 0;
//     overflow: visible;
//     clip: auto;
//   }
// }

// @mixin borderRadius ($rad: $btnBorderRadius) {
//     -webkit-border-radius: $rad;
//     -moz-border-radius: $rad;
//     border-radius: $rad;
// }
// @mixin borderRadiusAll ($radTop: $btnBorderRadius, $radRight: $btnBorderRadius, $radBot: $btnBorderRadius, $radLeft: $btnBorderRadius) {
//     -webkit-border-radius: $radTop $radRight $radBot $radLeft;
//     -moz-border-radius: $radTop $radRight $radBot $radLeft;
//     border-radius: $radTop $radRight $radBot $radLeft;
// }

@mixin roundBtn($background, $fontSize, $space, $color: #fff){
	background: $background;
	color: $color;
	display: block;
	padding: $space;
	font-size: $fontSize;
	border-radius: $btnBorderRadius;
	text-decoration: none;
}

@mixin background-gradient ($from, $to) {
	background-color: $from;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background-image: -webkit-linear-gradient(top, $from, $to);
	background-image: -moz-linear-gradient(top, $from, $to);
	background-image: -o-linear-gradient(top, $from, $to);
	background-image: linear-gradient(to bottom, $from, $to);
}

@mixin background-gradient-corner ($from, $to) {
	background-color: $from;
	// background-image: -webkit-gradient(to bottom right, from($from), to($to));
	// background-image: -webkit-linear-gradient(to bottom right, $from, $to);
	// background-image: -moz-linear-gradient(to bottom right, $from, $to);
	// background-image: -o-linear-gradient(to bottom right, $from, $to);
	background-image: linear-gradient(to bottom left, $from, $from, $to);
}

@mixin squareBtn($background, $fontSize, $space, $color: #fff) {
	background: $background;
	color: $color;
	display: block;
	padding: $space;
	font-size: $fontSize;
	text-decoration: none;
}

// @mixin arrow-up($size, $color) {
// 	width: 0;
// 	height: 0;
// 	border-left: $size solid transparent;
// 	border-right: $size solid transparent;
// 	border-bottom: $size solid $color;
// }

// @mixin arrow-down($size, $color) {
// 	width: 0;
// 	height: 0;
// 	border-left: $size solid transparent;
// 	border-right: $size solid transparent;
// 	border-top: $size solid $color;
// }

// @mixin arrow-right($size, $color) {
// 	width: 0;
// 	height: 0;
// 	border-top: $size solid transparent;
// 	border-bottom: $size solid transparent;
// 	border-left: $size solid $color;
// }

// @mixin arrow-left($size, $color) {
// 	width: 0; 
// 	height: 0; 
// 	border-top: $size solid transparent;
// 	border-bottom: $size solid transparent; 
// 	border-right:$size solid $color;;
// }


@mixin infoBoxDownArrow($arrowBaseWid: 10px, $arrowBaseColor: white, $arrowBeforeWid: 8px, $arrowBeforeColor: red, $arrowAfterWid: 7px, $arrowAfterColor: blue) {
	position: relative;
    
    width: 0; 
    height: 0; 
	margin: 0 3px;
    border-left: $arrowBaseWid solid transparent;
    border-right: $arrowBaseWid solid transparent;
    border-top: $arrowBaseWid solid $arrowBaseColor;

	@if ($arrowAfterWid == 0) {

		top: 0px;

		&::before{
			content: '';
			position: absolute;
			width: 0; 
			height: 0; 
			border-left: $arrowBeforeWid solid transparent;
			border-right: $arrowBeforeWid solid transparent;
			border-top: $arrowBeforeWid solid $arrowBeforeColor;
			z-index: 1;
			top: -12px;
			left: -1 * $arrowBeforeWid;
		}


	} @else {
		top: 1px;

		&::before{
			content: '';
			position: absolute;
			width: 0; 
			height: 0; 
			border-left: $arrowBeforeWid solid transparent;
			border-right: $arrowBeforeWid solid transparent;
			border-top: $arrowBeforeWid solid $arrowBeforeColor;
			z-index: 1;
			top: -11px;
			left: -1 * $arrowBeforeWid;
		}

		&::after{
			content: '';
			position: absolute;
			width: 0; 
			height: 0; 
			border-left: $arrowAfterWid solid transparent;
			border-right: $arrowAfterWid solid transparent;
			border-top: $arrowAfterWid solid $arrowAfterColor;
			z-index: 2;
			top: -13px;
			left: -1 * $arrowAfterWid;
		}
	}
}



// %travelPlannerList{
// 	margin: 1em 0 0 0;
// 	header{
// 		margin: 0;
// 		background: $primary;
// 		padding: 1px 0;
// 		border-radius: 5px;
// 		h2{
// 			font-size: 1.2em;
// 			margin: 0;
// 			padding: 4px 2%;
// 			color: $primary;
// 			@media screen and (min-width: $mq-tablet-portrait){
// 				color: $reverseOut;
// 			}
// 		}
// 	}
// }

// %list{
// 	header{
// 		margin: .5em 0;
// 		@extend .clearfix;
// 		padding: .75em;
// 		background: $primary;
// 		h2{
// 			color: $reverseOut; 
// 			float: left; 
// 			font-size: 1.1em; 
// 			margin: 0 2%;
// 		}
// 		a:link, a:visited{
// 			color: $reverseOut; 
// 			display: block; 
// 			font-size: .7em; 
// 			float: right;
// 			margin-top: .4em;
// 		}
// 		@media screen and (min-width: $mq-tablet-portrait){
// 			background: none;
// 			h2{
// 				color: $primary;
// 				margin: 0;
// 			}
// 			a:link, a:visited{color: $primary;}
// 		}
// 	}
// 	ul{
// 		@extend .listNav;
// 		padding: 0 3%;
// 		li{
// 			font-size: .8em;
// 			margin: 10px 0;
// 			line-height: 1.2;
// 			position: relative;
// 			a{
// 				display: block;
// 				@extend .clearfix;
// 			}
// 			h3{
// 				margin: 5px .5em 5px;
// 				font-size: 1.1em;
// 			}
// 			p{
// 				margin: 5px .75em 5px ;
// 				font-size: 1em;
// 				color: $darkNeutral;
// 			}
// 		}
// 	}
// }

// %thumbnailList{
// 	@extend %list;
// 	ul{
// 		li{
// 			a:visited, a:link{
// 				font-family: "Helvetica", "Arial", "sans-serif";
// 				font-weight: bold;
// 				text-decoration: none;
// 				.dates {
// 					font-size:0.9em;
// 					color: $darkNeutral;
// 				}
// 				.city {
// 					font-size:0.9em;
// 					font-weight:normal;
// 					color: $darkNeutral;
// 				}
// 			}
// 			.location{
// 				font-size: .8em; 
// 				color: darken($neutral, 40%); 
// 				display: block; 
// 				margin: 5px 0 5px 22%;
// 			}
// 			figure{
// 				float: left; 
// 				margin-right: 1%;
// 				width: 20%;
// 				img{max-width: 100%;}
// 			}
// 			h3, .dates, .city{
// 				margin-left: 22%;
// 			}
// 			.category{
// 				display: block;
// 				margin: 5px 0;
// 				font-size: .7em;
// 				text-decoration: none;
// 				font-weight: normal;
// 				color: $darkNeutral;
// 			}
// 		}
// 	}
// }

// %mobileNav{
// 	@media screen and (max-width: $mq-tablet-portrait){
// 		&:before {
// 			font-family: 'icomoon';
// 			content: '`';
// 			color: $primary;
// 			font-size: 1.5em;
// 			position: absolute;
// 			top: 1.25em;
// 			right: 0;
// 			width: .75em;
// 			height: 1.5em;
// 			speak: none;
// 			font-weight: normal;
// 			-webkit-font-smoothing: antialiased;
// 		}
// 	}
// }

%formStyles{ 
	.fltL {float:left}
	.fltR {float:right;}
	div {padding-top:.5em;}
	label {font-size:1.2em; color:#666;}
	input, select {margin-top:0.5em;}
	.medium {
		width:49%;
		input[type="text"] {
			width:100%;
		}
	}
	.large {
		width:100%;
		input {width:100%;}
		select {width:100%; padding:4px;}
	}
	.clearfix{
		clear: both;
	}
	input[type="submit"] {
		background:$primary;
		color:white;
		border:0;
		border-radius:5px;
		margin:0.5em;
    	padding:0.5em;
	}
	input[type="reset"] {
		background:#000;
		color:white;
		border:0;
		border-radius:5px;
		margin:0.5em;
		padding:0.5em;
		box-sizing: border-box;
	}
	input[type="text"], .selectHolder {
		box-shadow:0 0 8px #9A9A9A inset;
		border:1px #F3F3F3;
		border-radius:3px;
		box-sizing: border-box;
		padding:8px;
	}
	textarea{
		box-shadow:0 0 8px #9A9A9A inset;
		border:1px #F3F3F3;
		border-radius:3px;
		box-sizing: border-box;
		padding:8px;
	}
	.selectHolder{
		padding: 4px; 
		background: #fff;
		select{border: none; padding: 4px; margin-top: 0;}
	}
}

// %fancydropdown{
// 	.eventsDropdown, .citiesRegionsDropdown{
// 		position: absolute;
// 		left: -9999px;
// 		background: $secondary;
// 		width: 800px;
// 		z-index: 3;
// 		box-shadow: 1px 1px 18px darken($primary, 10%);
// 		@extend .clearfix;
// 		ul{
// 			position: relative;
// 			left: auto;
// 			float: left;
// 			width: 62%;
// 		}
// 		.searchForm{
// 			float: right;
// 			font-size: .9em;
// 			padding: 1em 6%;
// 			text-align: left;
// 			width: 30%;
// 			@extend %formStyles;
// 			box-sizing: border-box;
// 			label, h3{
// 				color: $reverseOut;
// 				margin: 0;
// 				text-transform: none;
// 			} 
// 			h3{font-size: 1.4em;}
// 			label{font-size: 0.95em;}
// 			.searchSbmt, .drop-down__view-all{
// 				float: left;
// 				margin-top: 1em;
// 				text-align: center;
// 				width: 30%;
// 				@include roundBtn($primary, 1em, .5em);
// 			}
// 			.drop-down__view-all{
// 				margin-left: 0.5em;
// 			}
// 		}
// 	}
//   .citiesRegionsDropdown{
//     box-shadow: 1px 1px 18px darken($citiesRegionsAccent2, 10%);
//   }
// 	.eventsDropdown {
// 		width:250px;
// 		ul {
// 			width:45%;
// 			li {float:none; width:100%;}
// 		}
// 		.searchForm {
// 			width:100%;
// 		}
// 	}
// 	&.hovered, &:focus{
// 		>a:link, >a:visited{
// 			background: $secondary; position: relative; z-index: 4;
// 		}
// 		.eventsDropdown{
// 			left: -300px;
// 		}
// 		.citiesRegionsDropdown{
// 			left: -350px;  
// 		}
// 	}
// }

// %pop-up {
// 	border: 3px solid $darkNeutral;
// 	left: 2%;
// 	top:-7.6em;
// 	select {width:100%;}
// 	.addingToTrip {
// 		a.closePopup {
// 			float:right; border:0;
// 			&:link, &:visited {
// 				color: $darkNeutral; 
// 				font-size:1.25em; 
// 				width:15%;
// 				@media screen and (min-width:$mq-tablet-portrait) {width:25%;}
// 			}
// 		}
// 		div {
// 			p{
// 				color: $darkNeutral;
// 				font-size:1.25em; 
// 				padding:0.25em 0 0.5em 0;
// 			}
// 		}
// 	}
// 	.popupArrow{
// 		@include arrow-down(1em,$darkNeutral);
// 		bottom:-1em;
// 		left:5.0em;
// 	}
// 	&.top{
// 		top: -123px;
// 		.popupArrow{
// 			@include arrow-down(1em,$darkNeutral);
// 			bottom: -1em;
// 		}
// 	}
// 	&.bottom{
// 		top: 55px;
// 		.popupArrow{
// 			@include arrow-up(1em,$darkNeutral);
// 			top: 1em;
// 			right: 1em;
// 		}
// 	}
// 	.actionBtns{
// 		margin-top: 1em;
// 		@extend .clearfix;
// 		a{width: 39%;}
// 		.confirmBtn{
// 			float: left;
// 			text-align: center;
// 			@include roundBtn($approved, .9em, .5em 1em);
// 		}
// 		.cancelBtn{
// 			float: right;
// 			text-align: center;
// 			@include roundBtn($darkNeutral, .9em, .5em 1em);
// 		}
// 	}
// 	.availableDays{
// 		@extend .clearfix;
// 		margin: .75em 0;
// 		border-bottom: 1px solid $lightPrimary;
// 		.day{
// 			float: left;
// 			margin: 0 .25em .5em;
// 			@include roundBtn($lightPrimary, .9em, .5em);
// 			&.active{background: $primary;}
// 		}
// 	}
// 	@media screen and (min-width: $mq-tablet-portrait){
// 		left: -1.0em;
// 		width: 20em;
// 	}
// 	@media screen and (min-width: $mq-tablet-landscape){}
// 	&.deleteConfirm{
// 		left: -14em;
// 	}
// }

// // Makes lists into a table of 3 columns
// %tableDirectory {
// 	@media screen and (min-width: $mq-tablet-portrait) {
// 		display:table;
// 		width:100%;
// 		padding:0;
// 		margin:0;
// 		font-size:0.8em;
// 		color:$primary;
// 		background: $lightNeutral;
// 		li{ 
// 			display:table-row-group;
// 			border-bottom: 1px solid $secondary;
// 			ul{ 
// 				display:table-row; 
// 				padding:0; 
// 				margin:0;
// 				.navArrow {display:none;}
// 				li {
// 					&:first-child {
// 						width:40%; 
// 						h3{margin:0; padding:0;}
// 					}
// 					display:table-cell;
// 					width:40%;
// 					padding:12px;
// 				}
// 				&:hover {
// 					background-color:$primary; 
// 					cursor:pointer;
// 					&:first-child {h3 a{color:#fff;}}
// 					li {color:#fff;}
// 				}
// 			}
// 		}
// 	}
// }

// /* Stitching effect */
// // @mixin stitching($location, $marginTop: 40px, $marginBottom: 40px, $minWidth: $mq-tablet-portrait) {
// // 	//$location is 'before' or 'after'
// // 	@media screen and (min-width: $minWidth){
// // 		&:#{$location} {
// // 			content: ' ';
// // 			height: 8px;
// // 			width: 100%;
// // 			background-image: url('/images/public/stitch.png');
// // 			background-repeat: repeat-x;
// // 			margin: $marginTop 0 $marginBottom;
// // 			display: block;
// // 		}
// // 	}
// // }

// // @mixin mobileStitching($location, $marginTop: 10px, $marginBottom: 10px) {
// // 	&:#{$location} {
// // 		content: ' ';
// // 		height: 8px;
// // 		width: 100%;
// // 		background-image: url('/images/public/stitch.png');
// // 		background-repeat: repeat-x;
// // 		margin: $marginTop 0 $marginBottom;
// // 	}
// // }



@mixin animateElement ($attr: all, $sec: 0.2s, $ease: ease-in-out) {
	transition: $attr $sec $ease;
    -ms-transition: $attr $sec $ease;
    -moz-transition: $attr $sec $ease;
    -webkit-transition: $attr $sec $ease;
}


// Respond - easier media queries

@mixin respond($media) {
  // MINIMUM WIDTH QUERIES (mobile first)
  @if $media == md-320 { // 320+
    @media only screen and (min-width: $mq-mobile-portrait) { @content; }
  }
  @else if $media == md-480 { // 480+
    @media only screen and (min-width: $mq-mobile-landscape) { @content; }
  }
  @else if $media == md-760 { // 760+
    @media only screen and (min-width: $mq-tablet-portrait) { @content; }
  }
  @else if $media == md-1023 { // 1023+
    @media only screen and (min-width: $mq-tablet-landscape){ @content; }
  }
  @else if $media == md-1175 { // 1175+
    @media only screen and (min-width: $mq-desktop-sm){ @content; }
  }
  @else if $media == md-1382 { // 1382+
    @media only screen and (min-width: $mq-desktop) { @content; }
  }
  @else if $media == min-document-width { // 1660+
    @media only screen and (min-width: $document-width + 1) { @content; }
  }

  // MIN AND MAX WIDTH MEDIA QUERIES
  /*@else if $media == portrait-phones {
    @media only screen and (max-width: $min-device-width) { @content; }
  }
  @else if $media == all-phones {
    @media only screen and (max-width: $tablet-device-width - 1) { @content; }
  }
  @else if $media == less-than-stack {
    @media only screen and (max-width: $stack-width) { @content; }
  }
  @else if $media == tablets {
    @media only screen and (min-width: $tablet-device-width + 1) and (max-width: $document-width) { @content; }
  }
  @else if $media == document-width {
    @media only screen and (max-width: $document-width - 1) { @content; }
  }
  @else if $media == big-device-width {
    @media only screen and (max-width: $big-device-width - 1) { @content; }
  }
  @else if $media == print {
    @media print { @content; }
  }*/
  @else {
    @media only screen and ('#{$media}') { @content; }
  }
}

// Centering

@mixin center--vert {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin center--horz {
  position: absolute;
  left: 50%;
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}


// @mixin divider() {
//     content: " ";
//     border-right: 1px solid #ddd;
//     height: 100%;
//     position: absolute;
//     margin-right: 15px;
//     top: 0;
//     right: 0;
// }


// //bootstrap spacing
// //https://codepen.io/danielmorales/pen/rmmRpv
// @mixin bootstrapSpacing() {
// 	@each $prop, $abbrev in (margin: m, padding: p) {
// 	  @each $size, $lengths in $bootspacers {
// 		$length-x:   map-get($lengths, x);
// 		$length-y:   map-get($lengths, y);

// 		.#{$abbrev}-#{$size} { #{$prop}:         $length-y $length-x !important; }
// 		.#{$abbrev}t-#{$size} { #{$prop}-top:    $length-y !important; }
// 		.#{$abbrev}r-#{$size} { #{$prop}-right:  $length-x !important; }
// 		.#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
// 		.#{$abbrev}l-#{$size} { #{$prop}-left:   $length-x !important; }
// 		.#{$abbrev}x-#{$size} { 
// 		  #{$prop}-left:   $length-x !important; 
// 		  #{$prop}-right:   $length-x !important; 
// 		}
// 		.#{$abbrev}y-#{$size} { 
// 		  #{$prop}-top:   $length-y !important; 
// 		  #{$prop}-bottom:   $length-y !important; 
// 		}
// 	  }
// 	}
// }


