html{
	background: white;
}

body {
	background-color: white;
	//font-family: $font-family-base;
	h1 {
		//font-family: $accentFont;
		text-transform: uppercase;
	}

	h1 em {
		//font-family: $accentFont;
        //font-weight: 700;
		//font-style:normal;
		font-style: italic;
	}

	// h2, h3, h4, h5, h6 {
	// 	font-family: $font-family-base; 
	// 	//font-weight:normal;
	// }

	@media print {
		background-image: none;
		background-color: #fff;
	}
}

.min-height {
	min-height: 600px; //making a container the min height of the main wrapper
}



.clear-background {
	position: relative;
	width: 100%;
	background-color: #fff;
}




.account-background {
	@media screen and (min-width: $mq-tablet-landscape){
		background-image: url('/Images/public/account-bg.jpg');
		background-size: cover;
	}
}

.randomizer-background {
	position: relative;
	@media screen and (min-width: $mq-desktop){
		&:before {
			content: url('/Images/public/randomizer-footer-left.png');
			position: absolute;
			left: 0;
			bottom: 0;
			width: 193px;
			height: 173px;
			z-index: 1;
		}
		&:after {
			content: url('/Images/public/randomizer-footer-right.png');
			position: absolute;
			right: 0;
			bottom: 0;
			width: 245px;
			height: 181px;
			z-index: 1;
		}
	}
}

.randomizer-background--small {
	position: relative;
	@media screen and (min-width: $mq-tablet-portrait){
		&:before {
			content: " ";
			background-image: url('/Images/public/randomizer-footer-left.png');
			position: absolute;
			left: 0;
			bottom: 0;
			width: 150px;
			height: 137px;
			background-size: cover;
		}
		&:after {
			content: " ";
			background-image: url('/Images/public/randomizer-footer-right.png');
			position: absolute;
			right: 0;
			bottom: 0;
			width: 150px;
			height: 111px;
			background-size: cover;
		}
	}
	@media screen and (min-width: $mq-desktop){
		&:before {
			width: 193px;
			height: 173px;
		}
		&:after {
			width: 245px;
			height: 181px;
		}
	}
}

.season-background {
	padding-top: 15px;
	padding-bottom: 15px;
	@media screen and (min-width: $mq-desktop){
		padding-top: 100px;
		padding-bottom: 150px;
	}
}

//body.desktop {min-width:763px;}
///*-------------------------------------------------------------------------- 
//    Size Controls
//--------------------------------------------------------------------------*/

.wrapper{
	@extend .container;
	// max-width: 1175px;
	// margin: 0 auto;
	// @media screen and (min-width: $mq-tablet-portrait){
	// 	padding: 0 1.5%; 
	// 	width: 97%;
	// }
	@extend .clearfix;
}

//This isn't pretty but since this is going away... not worth a 40 hour refactor
@media screen and (max-width:$mq-tablet-portrait){
	.truncatedStory {
		p {margin:0 2.5%}
		h1 {margin:0 2.5%}// !important;
		h3 {margin:0 2.5%}
	}
}

.wrapper--full-width {
	max-width: none;
	@media screen and (min-width: $mq-tablet-portrait){
		padding: 0; 
		width: 100%;
	}
}

.wrapper--full-width .content-wrapper {
	width: 97%;
	margin: 0 auto;
}


.wrapper--window-height { 
	//roughly the height of the window, with some leeway for header/footer
	min-height: 75vh;
}

.wrapper--vh-mobile-head { 
	//roughly the height of the window, with some leeway for header
	min-height: calc(100vh - 74.5px) !important;
}

.wrapper--comments {
	//width of the facebook commenting widget on desktop
	max-width: 550px;
	padding-bottom: 1em;
}

.wrapper--small {
	max-width: 710px;
}

.wrapper--narrow {
	max-width: 800px;
}

//for content without a wrapper to give them mobile padding
.mobile-wrapper {
	margin-left: 15px;
	margin-right: 15px;
	@media screen and (min-width:$mq-tablet-portrait){
		margin-left: auto;
		margin-right: auto;
	}
}

.wrapper--toolbar-offset {
	@media screen and (min-width: $mq-desktop-xl){
		left: -75px;
	}
}

.main {
	//background: $reverseOut;
	@media screen and (min-width: $mq-tablet-portrait){
		//background: $neutral;
		background: none;
		min-height: 600px;
	}
	img{
		z-index: 0;
	}
}

// .main{
// 	a:link, a:visited{
// 		color: $primary;
// 	}
// }

h1,h2,h3,h4,h5,h6{
	color: $primary;
}

// h1{
// 	font-size: 2em;
// 	font-family: openSans-condensed;
// }

small{
	font-size: .8em;
}

// Left column
.featureContent{
	position: relative;
	@media screen and (min-width: $mq-tablet-landscape){
		float: left;
		margin: 10px 0;
		width: 80%;
	}

	h1{
		margin: .5em 3% 0;
		@media screen and (min-width: $mq-tablet-portrait){
			margin: 10px 0 5px;
		}
	}

	h2{
		font-size: 1.1em;
		margin-bottom: .25em;
	}

	h3, h4, h5, h6{
		font-size: 1.1em;
	}

	hr{
	clear: both;
	}

	article{
		background: #fff; 
		figure{
			margin: 20px 3%;
			width: 50%;
			@media screen and (min-width: $mq-tablet-landscape){
				width: 30%;
			}
			img{width: 100%;}
			&.floatLeft{
				float: left;
				margin-left: 0;
			}
			&.floatRight{
				float: right;
				margin-right: 0;
			}
		}
	}
	.posted {
		padding: 0 3%;
		@media screen and (min-width: $mq-tablet-portrait){
			padding: 0;
		}
	}
}

.travelGreen{
	color: $travelGreen;
}

iframe{
	border: 0px none transparent !important;
	background: none;
}

// AJAX Loader
.ajaxLoader{
	text-align: center;
	//background: $neutral;
	background-color: transparent;
	padding: 0;
	width: 100%;
	margin-bottom: 1rem;
	// @media screen and (min-width: $mq-tablet-portrait){
	// 	padding: 0;
	// 	//width: 74.46808510638298%;
	// }
}

.ajaxLoader-container {
	position: relative;
	min-height: 75px;
}

//for media gallery
.mediaAjax {
	position: relative;
	opacity: .5;
}

//Loading for Ajax
.trip-planner__loading { 
	background: transparentize(#fff, .5);
	position:absolute;
	top:0;
	width:100%; 
	height:666px;
	z-index:999999;
	img {margin-top:300px;}
}

//Upcoming Events widget
.introduction .upcomingEvents ul li {
	figure{
		margin-right: 4%;
		margin-bottom: 3%;
	}
}

.contentList{
	@extend %contentList;
}

//Icon fonts
.iconFont{ 
	display: block; 
	float: left;
	font-size: 1.1em;
	font-family: icomoon;
}

.toggle-checkbox {
	display: none;
}

.btn{
	.toggle-checkbox:checked + & {
		background-color: $primary;
		color: #fff;
		box-shadow: none;
	}
}

.covid-alert{
	padding: 0 20px;
	margin: 0 auto 1rem;
	color: $quinary;
	max-width: 1000px;
	h2 {
		margin: 1rem 0 .5rem;
		color: $quinary;
	}
	p{
		margin: .5rem 0 1rem;
	}

	@media screen and (min-width: $mq-tablet-portrait){
		padding: 0;
	}
}

/* For some reason Google Data Highlighter isn't loading TW pages. This is a bad hack to try and force it to show the page and not put a display: none on this custom tag of theirs */

zsoyz {
	display: block !important;
}

.city-landing,
.deals-landing,
.directory-landing,
.hub-page-full{
	h2,h3,h4,h5,h6{
		// font-family: $accentFont;
        // font-weight: 700;
		color: $primary;
	}
}

// .events-weekend-directory{
// 	.text-block--widget-heading-large{
// 		margin-bottom: 0 !important;
// 		h1{
// 			margin-bottom: 0 !important;
// 		}
// 	}
// }