html{
	scroll-behavior: smooth;
}

// .testjk{
// 	@include r-font-size(60, 100);
// }

main{
	min-height: 400px;
}

.hidden{
	display: none !important;
}

.pe-none{
	pointer-events: none;
}
.pe-auto{
	pointer-events: auto;
}

.b-red{
    border: 1px solid red;
}
.b-blu{
    border: 1px solid blue;
}
.b-grn{
    border: 1px solid green;
}

.z-0{
	z-index: 0;
}
.z-1{
	z-index: 1;
}
.z-2{
	z-index: 2;
}
.z-3{
	z-index: 3;
}
.z-9{
	z-index: 9;
}
.z-999{
	z-index: 999;
}
.top-33p{
	top: 33.33%;
	transform: translateY(-50%);
}
.top-45p{
	top: 45%;
	transform: translateY(-50%);
}
.top-50p{
	top: 50%;
	transform: translateY(-50%);
}
.w-md-12{
	width: 100%;
	@include media-breakpoint-up(md) {
		//border: 1px solid green;
		width: 12.5%;
	} 
}
.w-md-20{
	width: 100%;
	@include media-breakpoint-up(md) {
		//border: 1px solid green;
		width: 20%;
	} 
}
.w-md-25{
	width: 100%;
	@include media-breakpoint-up(md) {
		//border: 1px solid green;
		width: 25%;
	} 
}
.w-md-33{
	width: 100%;
	@include media-breakpoint-up(md) {
		//border: 1px solid green;
		width: 33.33%;
	} 
}
.w-md-40{
	width: 100%;
	@include media-breakpoint-up(md) {
		//border: 1px solid green;
		width: 40%;
	} 
}
.w-md-50{
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 50%;
		&--gutter-2{
			width: calc(50% - .5rem);
		}
	} 
	// @include media-breakpoint-up(xl) {
	// 	&--gutter-2{
	// 		width: auto;
	// 	}
	// }
}
.w-md-60{
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 60%;
	} 
}
.w-md-66{
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 66.66%;
	} 
}
.w-md-75{
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 75%;
	} 
}
.w-md-80{
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 80%;
	} 
}
.w-md-100{
	@include media-breakpoint-up(md) {
		width: 100%;
	} 
}

.mw-md-75{
	@include media-breakpoint-up(md) {
		max-width: 75%;
	}
}

.w-sm-100{
    @include media-breakpoint-down(sm) {
        width: 100%;
    } 
}

.w-lg-50{
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: 50%;
	} 
}
.w-lg-auto{
	@include media-breakpoint-up(lg) {
		width: auto!important
	} 
}
.w-xl-auto{
	@include media-breakpoint-up(xl) {
		width: auto!important
	} 
}

.mw-200{
	max-width: 200px;
}
.mw-md-200{
	@include media-breakpoint-up(md) {
		max-width: 200px;
	}
}
.mw-300{
	max-width: 300px;
}
.mw-md-300{
	@include media-breakpoint-up(md) {
		max-width: 300px;
	}
}
.mw-400{
	max-width: 400px;
}
.mw-720{
	max-width: 720px;
}

.mh-100{
	min-height: 100%;
}
.mh-md-300{
	@media screen and (min-width: $mq-tablet-portrait) {
		min-height: 300px;
	}
}

.mx-md-n2{
	margin-left: -.5rem;
	margin-right: -.5rem;
}


.lh-075{
	line-height: .75;
}



$flexColSpacer: 1rem;
.flex-col-2{
	@include media-breakpoint-up(md) {
		.order-1{
			padding-right: $flexColSpacer;
		}
		.order-2{
			padding-left: $flexColSpacer;
		}
	}
}

.flex-1{
	flex: 1;
}


.gradient-bottom{
	background: linear-gradient(transparent, rgba(0,0,0,.8));
}


$gridGutter: .75rem;
.directory-grid{
	margin-right: -1 * $gridGutter;
	.directory-grid-item{
		box-sizing: border-box;
		width: 48%;
		padding-right: $gridGutter;
	
		@include media-breakpoint-up(md) {
			width: 33.33%;
		}
	
		@include media-breakpoint-up(md) {
			width: 25%;
		}
	}
}


.xy-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-50{
	top: 50%;
}
.start-50{
	left: 50%;
}
.translate-middle{
	transform: translate(-50%, -50%);
}
.translate-middle-x{
	transform: translateX(-50%);
}
.position-md-absolute{
	@include media-breakpoint-up(md) {
		position: absolute;
	}
}


.transform-none{
	transform: unset!important;
}

.bg-quinary-ghosted{
	background-color: rgba($quinary, .5);
}


.hover-img-zoom{
	overflow: hidden;
	
	@include media-breakpoint-up(md) {
		img{
			text-align:center;
			transition: all 0.15s;
			transition-timing-function: ease-out;
		}
	
		&:hover{
			img{
				transform: scale(1.1);
			}
		}
	}
}

//internal stretched-link is overridding the regular hover-img-zoom functionality
.ideas-pillar{
	&:hover{
		.hover-img-zoom{
			img{
				transform: scale(1.1);
			}
		}
	}
}


.c-pointer,
.clickable{
	cursor: pointer;
}

.c-grab{
	cursor: grab;
}


hr{
	&.dashed{
		border-top: 1px dashed $gray-500;
		margin: 2rem 0
	}
}

.vr{
	height: unset;
	width: 1px;
    display: block;
    position: relative;
}

.hr-md-vr{
	width: 100%;
	height: 1px;
    display: block;
    position: relative;
	@include media-breakpoint-up(md) {
		width: 10px;
		height: unset;
	}
}
.hr-lg-vr{
	width: 100%;
	height: 1px;
    display: block;
    position: relative;
	@include media-breakpoint-up(lg) {
		width: 1px;
		height: unset;
	}
}
.hr-xl-vr{
	width: 100%;
	height: 1px;
    display: block;
    position: relative;
	@include media-breakpoint-up(xl) {
		width: 1px;
		height: unset;
	}
}

.hr-color-bar{
	width: 100%;
	height: 8px;
	display: block;
	position: relative;
	margin: 3rem 0;
	background: linear-gradient(to left, $tertiaryLight 80%, $quaternary 20%);

	@include media-breakpoint-up(md) {
		margin: 5rem 0;
		height: 12px;
	}
}

.circle-icon{
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	&.place-info{
		display: inline-flex;
		background-color: $tertiaryLighter;
		color: $tertiary;
		width: 30px;
		height: 30px;
	}

}

.opacity-0{
	opacity: 0;
}
.opacity-25{
	opacity: .25;
}
.opacity-50{
	opacity: .5;
}

.text-transform-none{
	text-transform: none;
}

.text-underline{
	text-decoration: underline;
}

.text-invert * {
    color: $white!important
}

.svg-shadow {
	filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
}

.image-sq100{
	display: flex;
	align-items: end;
	width: 100px;
	height: 100px;
	img{
		object-fit: contain;
		object-position: left bottom;
		width: 100%;
		height: 100%;
	}
}

.hero-fit{
	width: 100%;
    height: 100%;
    min-height: 200px;
    object-fit: cover;
}

.object-fit-cover{
	object-fit: cover;
}

.bg-center-cover{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.gradient-overlay {
	position: relative;
	&:before {
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		content: " ";
		display: block;
		background: linear-gradient(to top, rgba(0,0,0,.4), transparent 55%);
		z-index: 1;
		//diagonal
		//background: linear-gradient(to top right, rgba(0,0,0,.8), transparent 55%);
	}
}
.gradient-overlay-nomobile{
	@extend .gradient-overlay;
	&:before {
		display: none;
		@media screen and (min-width: map-get($grid-breakpoints, md)) {
			display: block;
		}
	}
}

.tint-overlay{
	&:before {
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		content: " ";
		display: block;
		background: rgba(0,0,0,.3);
		z-index: 1;
	}
	&--fade {
		&:before {
			background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
		}		
	}
}

#ae_launcher.ae-cta-position-preset-right-lower {
	bottom: 24px;
    right: 20px;
	animation: bottom ease-in 100ms;

	.sticky-padding &{
		bottom: 64px;
		right: 20px;
	}
}

.bg-tertiaryLighter {
	background-color: $tertiaryLighter !important;	
}

 //animations
 
 @-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } };
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fade-in {
	.cssanimations & {
		opacity: 0;
		-webkit-animation:fadeIn ease-in 1;
		-webkit-animation-fill-mode:forwards;
		-webkit-animation-duration:.5s;
		animation:fadeIn ease-in 1;
		animation-fill-mode:forwards;
		animation-duration:.5s;
	}
}