
.btn-light,
.btn-dark,
.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-quaternatry,
.btn-quinary,
.btn-senary,
.btn-septenary
{
	text-transform: uppercase;
}

.btn-icon{
	display: inline-flex;
	align-items: center;
	white-space: nowrap!important;
	span{
		white-space: nowrap!important;
	}
	svg{
		font-size: 1rem;
	}
}

.btn-icon{
	&-right{
		@extend .btn-icon;
		.svg-inline--fa{
			margin-left: .75rem;
		}
	}
	&-left{
		@extend .btn-icon;
		.svg-inline--fa{
			margin-right: .75rem;
		}
	}
}

.btn-group-right{
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}