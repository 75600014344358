﻿
/**
    Prefooter section above main footer
*/


$dark-btn: $primary;



.prefoot{
    position: relative;
    margin: 0;
    padding: 20px;
    background-color: white;
    &:after{
        @extend .clearfix;
    }

    @media only screen and (min-width: $mq-mobile-landscape) {
        padding: 35px;
    }

    @media only screen and (min-width: $mq-tablet-landscape) {
        padding: 50px;
    }
}

.prefoot__item{
    //border: 1px solid blue;
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
	overflow: hidden;
    &:first-of-type{
        margin: 0 0 10px;
    }
    
    @media only screen and (min-width: $mq-tablet-landscape) {
        display: inline-block;
        width: calc(50% - 10px);

        &:first-of-type{
            margin: 0 10px 0 0;
        }

    }
}


.prefoot__item__background{
    position: relative;
    display: block;
    width: 100%;
    font-size: 0;
    line-height: 0;
    background-color: black;
    img{
        width: 100%;
        opacity: .75;//tint the background
    }
}

.prefoot__item__info{
    //border: 1px solid red;
    position: absolute;
    display: block;
    z-index: 1;
    width: 90%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.prefoot__item__sponsor{
    position: absolute;
    display: block;
    z-index: 1;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.prefoot__item__info__text{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;

    font-size: 21px;
    line-height: 1.0em;
    font-weight: 800;
    color: white;
    text-align: center;
    text-transform: uppercase;
    //font-family: $accentFont;

    @media only screen and (min-width: $mq-mobile-landscape) {
        font-size: 34px;
    }

    @media only screen and (min-width: $mq-tablet-landscape) {
        font-size: 42px;
    }
}

.prefoot__item__info__link {
    position: relative;
    display: block;
    margin: 8px auto 0;
    padding: 0;
    width: auto;
    text-align: center;

    @media only screen and (min-width: $mq-tablet-landscape) {
        margin: 12px auto 0;
    }

    a:link, a:visited,
    a:hover, a:active {
        @extend .btn;
        @include button-variant($septenary, $septenary);
        font-size: 18px;
        text-transform: uppercase;

        // display: block;
        // margin: 0 auto;
        // padding: 5px 10px;
        // width: 150px;
        // background-color: $dark-btn;
        // -webkit-border-radius: $btnBorderRadius;
        // -moz-border-radius: $btnBorderRadius;
        // border-radius: $btnBorderRadius;
        // font-size: 12px;
        // line-height: 1.35em;
        // font-weight: 100;
        // color: white;
        // text-align: center;
        // text-decoration: none;

        // @media only screen and (min-width: $mq-mobile-landscape) {
        //     font-size: 14px;
        // }
    }
}