 .icon {
  background-repeat: no-repeat;
}

.icon--left {
  margin-right: .5em;
}

.icon--float-l{
  display: block;
  float: left;
  margin-right: 0.5em;
}
.icon--float-r{
  display: inline-block;
  float: right;
  margin-left: 0.5em;
}
.icon--il-blk {
  display: inline-block;
}
.icon--center {
  vertical-align: middle;
}

.logo-travel-wisconsin{
  background-image: url(/images/public/icons/Travel-Wisconsin-logo-white-2021.png);
  background-image: url(/images/public/icons/Travel-Wisconsin-logo-white-2021.svg), none;
  width: 134px;
  height: 46px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-search-white{
  background-image: url(/images/public/icons/icon-search-white.png);
  background-image: url(/images/public/icons/icon-search-white.svg), none;
  width: 26px;
  height: 34px;
}

.icon-start-over {
	background-image: url(/images/public/icons/icon-startover.png);
    width: 21px;
    height: 16px;
}

.icon-randomizer {
  background-image: url(/images/public/icons/icon-randomizer-white.svg);
  width: 27px;
  height: 27px;
  @include media-breakpoint-up(xl) {
    body:not(.force-mobile) & {
      background-image: url(/images/public/icons/icon-randomizer-grey.svg);
    }
  }
}

.icon-deal{
  width: 16px;
  height: 17px;
  // background-image: url(/images/public/icons/icon-deal-grey.png);
  background-image: url(/images/public/icons/icon-deal-white.svg), none;
  @include media-breakpoint-up(xl) {
      /*background-image: url(/images/public/icons/icon-deal-grey.png);
      background-image: url(/images/public/icons/icon-deal-grey.svg), none;*/
      background-image: url(/images/public/icons/icon-deal-blue.svg), none;
  }
  // .active &{
  //     background-image: url(/images/public/icons/icon-deal-active.png);
  //     background-image: url(/images/public/icons/icon-deal-active.svg), none;
  // }
}

.icon-deal-white {
  width: 21px;
  height: 22px;
  background-image: url(/images/public/icons/icon-deal-white.png);
  background-image: url(/images/public/icons/icon-deal-white.svg), none;
}

.icon-heart{
  width: 18px;
  height: 16px;
  background-image: url(/images/public/icons/icon-heart-white.png);
  background-image: url(/images/public/icons/icon-heart-white.svg), none;
  @include media-breakpoint-up(xl) {
    body:not(.force-mobile) & {
      // background-image: url(/images/public/icons/icon-heart-grey.png);
      background-image: url(/images/public/icons/icon-heart-blue.svg), none;
    }
  }
  .active &{
      background-image: url(/images/public/icons/icon-heart-active.png);
      background-image: url(/images/public/icons/icon-heart-active.svg), none;
  }
}

.icon-mail{
  width: 20px;//29px;
  height: 12.4px;//18px;
  background-image: url(/images/public/icons/icon-mail-white.png);
  background-image: url(/images/public/icons/icon-mail-white.svg), none;
  @include media-breakpoint-up(xl) {
    body:not(.force-mobile) & {
      // background-image: url(/images/public/icons/icon-mail-grey.png);
      background-image: url(/images/public/icons/icon-mail-blue.svg), none;
    }
  }
  .active &{
      background-image: url(/images/public/icons/icon-mail-active.png);
      background-image: url(/images/public/icons/icon-mail-active.svg), none;
  }
}

.icon-mail-white {
  width: 29px;
  height: 18px;
  background-image: url(/images/public/icons/icon-mail-white.png);
  background-image: url(/images/public/icons/icon-mail-white.svg), none;
}

.icon-person{
  width: 22px;
  height: 23px;
  background-image: url(/images/public/icons/icon-person-white.png);
  background-image: url(/images/public/icons/icon-person-white.svg), none;
  @include media-breakpoint-up(xl) {
    body:not(.force-mobile) & {
      // background-image: url(/images/public/icons/icon-person-grey.png);
      background-image: url(/images/public/icons/icon-person-blue.svg), none;
    }
  }
  .active &{
      background-image: url(/images/public/icons/icon-person-active.png);
      background-image: url(/images/public/icons/icon-person-active.svg), none;
  }
}

.icon-wisco{
  width: 21px;// 35px;
  height: 18.6px;//31px;
  margin-bottom: 0!important;
  background-size: contain;
 // background-image: url(/images/public/icons/icon-order-guide-white@2x.png);
  //background-image: url(/images/public/icons/icon-wisco-white.svg), none;
  background-image: url(/images/public/icons/utility-nav-wi-white.svg), none;
  @include media-breakpoint-up(xl) {
      // background-image: url(/images/public/icons/icon-order-guide-gray@2x.png);
      // background-image: url(/images/public/icons/icon-wisco-blue.svg), none;
      background-image: url(/images/public/icons/utility-nav-wi-blue.svg), none;
  }
  // .active &{
  //     background-image: url(/images/public/icons/icon-wisco-active.png);
  //     background-image: url(/images/public/icons/icon-wisco-active.svg), none;
  // }
}

.icon-wisco-white {
  width: 24px;
  height: 24px;
  // background-image: url(/images/public/icons/icon-wisco-white.png);
  // background-image: url(/images/public/icons/icon-wisco-white.svg), none;
  background-image: url(/images/public/icons/icon-wisco-white-2022.svg), none;
}

.icon-snow{
  width: 25px;
  height: 28px;
  background-image: url(/images/public/icons/icon-snow-white.png);
  background-image: url(/images/public/icons/icon-snow-white.svg), none;
  @include media-breakpoint-up(xl) {
    body:not(.force-mobile) & {
      background-image: url(/images/public/icons/icon-snow-grey.png);
      background-image: url(/images/public/icons/icon-snow-grey.svg), none;
    }
  }
  .active &{
      background-image: url(/images/public/icons/icon-snow-active.png);
      background-image: url(/images/public/icons/icon-snow-active.svg), none;
  }
}

.icon-trail{
  width: 18px;
  height: 18px;
  background-image: url(/images/public/icons/icon-trail-white.svg), none;
  @include media-breakpoint-up(xl) {
    body:not(.force-mobile) & {
      background-image: url(/images/public/icons/icon-trail-blue.svg), none;
    }
  }
}
 
.icon-fall{
  width: 26px;
  height: 26px;
  background-image: url(/images/public/icons/icon-fall-white.png);
  background-image: url(/images/public/icons/icon-fall-white.svg), none;
  @include media-breakpoint-up(xl) {
    body:not(.force-mobile) & {
      background-image: url(/images/public/icons/icon-fall-grey.png);
      background-image: url(/images/public/icons/icon-fall-grey.svg), none;
    }
  }
  .active &{
      background-image: url(/images/public/icons/icon-fall-active.png);
      background-image: url(/images/public/icons/icon-fall-active.svg), none;
  }
}

.icon-events {
	background-image: url(/images/public/icons/icon-events.svg);
	width: 24px;
	height: 24px;
}

.icon-things-to-do {
	background-image: url(/images/public/icons/icon-things.svg);
	width: 24px;
	height: 22px;
}

.icon-dining {
	background-image: url(/images/public/icons/icon-dining.svg);
	width: 24px;
	height: 23px;
}

.icon-places-to-stay {
	background-image: url(/images/public/icons/icon-places-to-stay.svg);
	width: 35px;//24px;
	height: 22px;//23px;
}





/**
NOTE: 
THE FOLLOWING CLASSES ARE USING CAPITALIZATION BECAUSE OF THOSE STRINGS BEING APPENDED IN THE MARKUP. Some of the markup has been updated to force lowercase. Other items have not yet been updated. I added both versions hoping to migrate fully to lowercase.
*/

.icon-map,
.icon-Map {
	background-image: url(/images/public/icons/icon-map.svg);
	width: 23px;
	height: 29px;
}

.icon-play,
.icon-Play {
	background-image: url(/images/public/icons/icon-play.svg);
	vertical-align: middle;
	width: 30px;
	height: 30px;
}

.icon-play--large {
  width: 65px;
  height: 65px;
  background-size: cover;
}

.icon-stay,
.icon-Stay {
	background-image: url(/images/public/icons/icon-stay.svg);
	vertical-align: middle;
	width: 34px;
	height: 26px;
}

.icon-eat,
.icon-Eat {
	background-image: url(/images/public/icons/icon-eat.svg);
	vertical-align: middle;
	width: 32px;
	height: 26px;
}

.icon-event,
.icon-Event {
	background-image: url(/images/public/icons/icon-event.svg);
	vertical-align: middle;
	width: 26px;
	height: 29px;
}


