.accessibility-landing-page{
    h1,h2,h3,h4,h5,h6{
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1;
    }

    .truncatedStory{
        a.button{
            padding: .5rem 1.5rem;
            //font-family: $accentFont;
            font-size: 1rem;
            text-align: center;
            display: block;
            @media screen and (min-width: map-get($grid-breakpoints, md)) {
                display: inline-block;
            }
        }
    }


    .hero-wrapper{
        .hero-content{
            .hero-info{
                width: 100%;
                box-sizing: border-box;
                //border: 1px solid red;

                @media screen and (min-width: map-get($grid-breakpoints, md)) {
                    max-width: 460px;
                }

                @media screen and (min-width: 1500px) {
                    //border: 1px solid orange;
                    max-width: 420px;
                }
            }
        }
    }

    .featured-wrapper{
        .slick-list{
            //border: 1px solid orange;
			overflow: visible;
		}
    }


    .featured-wrapper,
    .featured-carousel{
        overflow: visible;
        @media screen and (min-width: map-get($grid-breakpoints, xl)) {
            overflow: hidden;
        }
    }

    .featured-carousel{
        &::after{
            content: '';
            display: block;
            width: 50px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 88;
            right: 0;
            background-image: linear-gradient(to right, transparent , white);
        }
    }

    .featured-carousel-nav{
        z-index: 99;
        pointer-events: none;
        top: 45%;
        transform: translateY(-50%);
        width: 100%;

        .carousel-prev,
        .carousel-next{
            &.slick-disabled{
                opacity: .3;
            }

            img{
                width: 40px;
                @media screen and (min-width: map-get($grid-breakpoints, md)) {
                    width: 80px;
                }
            }
        }
        
        .carousel-prev{
            margin-left: 3rem;
            pointer-events: all;
        }
        .carousel-next{
            margin-right: 3rem;
            pointer-events: all;
        }
    }


    .info-slider{
        .info-carousel-nav{
            bottom: 3.5rem;
            // left: 50%;
            // transform: translateX(-50%);
    
            .carousel-prev,
            .carousel-next{
                &.slick-disabled{
                    opacity: .3;
                }
            }

            @media screen and (min-width: map-get($grid-breakpoints, md)) {
                bottom: 3rem;
            }
        }

        .info-slider-slide{
            position: relative;
            &:hover{
                .info-slider-slide-heading{
                    text-decoration: underline;
                }
            }
        }

        .info-slider-slide-heading{
            text-align: center;
            background-color: $primary;
            position: relative;
            line-height: 1;
            padding: 1.5rem 1rem 4rem;
            text-transform: none;

            @media screen and (min-width: map-get($grid-breakpoints, md)) {
                text-align: left;
                background-color: unset;
                position: absolute;
                bottom: 1rem;
                left: 2rem;
                text-shadow: 0 3px 47px rgba(0, 0, 0, .68) !important;
                // padding: unset;
                text-transform: uppercase;
            }
        }

        .info-slider-info{
            box-sizing: border-box;
        }


        .color-bars{
            position: relative;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 10px;
            display: flex;
            .color-bar{
                position: relative;
                &:nth-child(1){
                    width: 15%;
                }
                &:nth-child(2){
                    width: 15%;
                }
                &:nth-child(3){
                    width: 70%;
                }
            }

            @media screen and (min-width: map-get($grid-breakpoints, md)) {
                position: absolute;
                width: 10px;
                height: 100%;
                z-index: 9;
                // right: 0;
                top: 0;
                flex-direction: column;
                
                .color-bar{
                    width: 100% !important;
                    &:nth-child(1){
                        height: 15%;
                        order: 3;
                    }
                    &:nth-child(2){
                        height: 15%;
                        order: 2;
                    }
                    &:nth-child(3){
                        height: 70%;
                        order: 1;
                    }
                }
            }
        }


        &.img-left{
            //border: 1px solid blue;
            .info-slider-info{
                order: 2;
            }
            .info-slider-img{
                order: 1;
            }

            .info-slider-slide-heading{
                @media screen and (min-width: map-get($grid-breakpoints, md)) {
                    padding: 0;
                    width: 60%;
                }
                @media screen and (min-width: map-get($grid-breakpoints, xl)) {
                    width: 70%;
                }
            }

            .info-carousel-nav{
                //right: 48%;
                left: 50%;
                transform: translateX(-50%);
                @media screen and (min-width: map-get($grid-breakpoints, md)) {
                    left: unset;
                    transform: unset;
                    right: 42%;
                }
                @media screen and (min-width: map-get($grid-breakpoints, lg)) {
                    right: 43%;
                }
            }

            .color-bars{
                @media screen and (min-width: map-get($grid-breakpoints, md)) {
                    left: 0;
                }
            }
        }
        &.img-right{
            //border: 1px solid orange;
            .info-slider-info{
                order: 1;
            }
            .info-slider-img{
                order: 2;
            }

            .info-slider-slide-heading{
                @media screen and (min-width: map-get($grid-breakpoints, md)) {
                    padding: 0;
                    width: 60%;
                }
                @media screen and (min-width: map-get($grid-breakpoints, xl)) {
                    width: 70%;
                }
            }

            .info-carousel-nav{
                //right: 2rem;
                left: 50%;
                transform: translateX(-50%);
                @media screen and (min-width: map-get($grid-breakpoints, md)) {
                    left: unset;
                    transform: unset;
                    right: 3rem;
                }
            }

            .color-bars{
                @media screen and (min-width: map-get($grid-breakpoints, md)) {
                    right: 0;
                }
            }

        }

        .slick-track{
            display: flex!important;
        }

    }

}






.accessibility-modal{
    position: fixed;
    display: block;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-bg{
        position: absolute;
        background-color: rgba(0,0,0,.7);
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &-content{
        position: absolute;
        background-color: white;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media screen and (min-width: map-get($grid-breakpoints, sm)) {
            width: 80%;
            height: auto;
            border-radius: $btnBorderRadius;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 800px;
            max-height: 90%;
            min-height: 300px;
        }

        @media screen and (min-width: map-get($grid-breakpoints, md)) {
            width: 60%;
        }
    }


    &-header{
        //border: 1px solid pink;
    }

    &-body{
        //border: 1px solid pink;
        overflow-y: auto;
        //max-height: 200px;
        flex-grow: 1;
    }

    &-footer{
        //border: 1px solid pink;
    }

    &-filter-group{
        position: relative;
        //border: 1px solid blue;
        &::before{
            position: relative;
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $gray-300;
            margin: 1rem 0;
        }
        &:last-of-type::after{
            position: relative;
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $gray-300;
            margin: 1rem 0;
        }
    }

}