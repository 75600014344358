.featureSlider {
	margin-bottom: 0;
	position: relative;
	width: 100%;
	overflow: hidden;

	figure {
		position: relative;
		overflow: hidden;
		width: 100%;

		img {
			width: 100%;
		}

		figcaption {
			display: none;

			@media screen and (min-width: $mq-tablet-portrait) {
				background: url(/images/public/figcaption-tile-bg.png);
				bottom: 0;
				display: block;
				//padding: 10px 3%;
				padding: 20px 3%;
				position: absolute;
				width: 95%;

				p {
					margin: 0;
					padding: 0;
					width: 66%;
					font-size: 0.9em;
				}
			}
		}
	}

}

.feature-slider--free-width {
	width: auto;
	min-width: 100%;
	overflow: visible;
}

.featureSlider--caption-below {
	figure {
		figcaption {
			background: $neutral;
			display: block;
			position: static;
			font-style: italic;
			font-size: .9em;

			p {
				width: auto;
				margin: 0;
				padding: .5em;

				@media screen and (min-width: $mq-tablet-portrait) {
					padding: 0;
				}
			}
		}
	}
}

.slide {
	width: 100%;
	position: relative;
	display: block;
	float: left;

	&.mediaSliderHideImage {
		display: none;

		&:first-child {
			display: block;
		}
	}
}

.carousel-tabs {
	list-style-type: none;
	margin: 5px 0;
	padding: 0;
	text-align: center;

	@media screen and (min-width: $mq-tablet-portrait) {
		bottom: 0;
		position: relative;
		right: 0;
	}

	li {
		display: inline-block;
		margin-right: 7px;

		a,
		button {
			display: block;
			font-size: .9em;
			line-height: 1;
			padding: 5px;
			text-decoration: none;
			background: $primary;
			color: #fff;
			border: none;

			&:link,
			&:visited {
				text-decoration: none;
				background: $primary;
				color: #fff;
			}
		}

		&.carousel-active-tab,
		&.slick-active {
			button {
				background: $quaternary;
			}

			a:link,
			a:visited {
				background: $specialCallout;
			}
		}
	}

	//if there's only one tab don't show the tabs
	li:first-child:last-child {
		display: none;
	}
}

.carousel-squares {
	list-style-type: none;
	margin: 5px 0;
	padding: 0;
	text-align: center;

	li {
		display: inline-block;
		margin-right: 7px;
		font-size: .9em;
		line-height: 1;
		padding: 5px;
		text-decoration: none;
		background: $primary;
		color: #fff;
		border: none;

		button {
			display: none;
		}

		&.slick-active {
			background: $quaternary;
		}
	}
}

.carousel-dots {
	list-style-type: none;
	padding: 10px 0;
	text-align: center;
	clear: both;

	li {
		display: inline-block;
		margin-right: 7px;
		padding: 7px;
		background: transparent;
		border: 2px solid $primary;
		border-radius: 9px;
		cursor: pointer;

		button {
			display: none;
		}

		&.slick-active {
			background: $primary;
		}
	}

	.link-block-container & {
		padding: 0;
	}
}

.slick-nav-dots {
	list-style-type: none;
	padding:0;
	text-align: center;
	clear: both;

	li {
		display: inline-block;
		margin-right: 7px;
		padding: 5px;
		background: $gray-300;
		//border: 2px solid $primary;
		border-radius: 9px;
		cursor: pointer;

		button {
			display: none;
		}

		&.slick-active {
			background: $quaternary;
		}
	}

	.link-block-container & {
		padding: 0;
	}
}

.slick-slider {
	overflow-x: hidden;
	margin-bottom: 0;
}

.largeSlider {
	@media screen and (min-width:$mq-tablet-portrait) {
		.carousel-tabs {
			position: absolute;
			right: 10px;
			bottom: 7px;
		}
	}
}

.places {
	.featureSlider {
		figure {
			figcaption {
				@media screen and (min-width: $mq-tablet-portrait) {
					p {
						width: 90%;
					}
				}
			}
		}
	}
}

.postInfo {
	margin-top: 5px;
	font-size: .8em;
	color: $darkNeutral;
}




.media-room .featureSlider {
	&.largeSlider {
		@media screen and (min-width:$mq-tablet-portrait) {
			.carousel-tabs {
				bottom: 1px;
			}
		}
	}
}

.slide {
	.youtube__container {
		iframe {
			float: left;
		}

		.youTubeSummary {
			float: right;
			padding: 1em 1%;
			width: 31%;
			box-sizing: border-box;
		}
	}
}

/* Camp Wisconsin badge pages */

.badges__detail {
	width: 100%;
}
