.cards-container {
	display: none;
	@media print {
		display: block;
	}
	.button {
		@media print {
			display: none;
		}
	}
}

.cards-container--visible {
	display: block;
}

.cards-container--flex-wrapper {
	//max-width: 1175px;
	margin: auto;
	padding: 0;
	text-align: center; //so it looks okay in unsupporting browsers
	display: flex;
	//justify-content: space-around;
	flex-flow: row wrap;
	// @media screen and (min-width: $mq-tablet-landscape){
	// 	justify-content: space-between;
	// }
	
}

.cards-container--featured {
	text-align: center;
	@media screen and (min-width: $mq-tablet-landscape){
		text-align: left;
	}
}

.cards-container--small-trio {
	max-width: 705px;
	margin: auto;
}

.cards-container--medium-trio {
	max-width: 940px;
	margin: auto;
}

.cards-container--directory {
	margin-top: 50px;
	text-align: center;
	@media screen and (min-width: $mq-tablet-landscape){
		text-align: left;
		margin-top: 100px;
	}
	//.deals-giveaway &,
	.deals-campaign & {
		margin-top: 25px !important;
	}
}

.card-container {
	display: inline-block;
	vertical-align: top;
	//background-color: #fff;
	margin: 0 5px 30px 5px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	// box-shadow: $gray-900 0 4px 2px;
	position: relative;
	text-align: left;
	//width: 269px;
	width: 100%;
	@media print {
		margin-bottom: 0;
		width: auto;
	}
	//for cards that are also sliders--no need for bottom margin as cards won't form grid
	.slide & {
		margin-bottom: 10px;
		overflow-x: hidden;
	}

	@media screen and (min-width: $mq-mobile-landscape){
		//border: 1px solid red !important;
		margin: 0 5px 60px 5px;
		width: calc(33.33% - 15px);
	}
}

.card-container--4up {
	width: 100%;
	margin: 0 0 2rem .5rem;
	//border: 1px solid red !important;
	@media screen and (min-width: $mq-ts){//600
		//border: 1px solid green !important;
		margin: 0 0 3rem .25rem;
		width: 49%;
	}
	@media screen and (min-width: $mq-md){//992
		//border: 1px solid blue !important;
		width: 32.5%;
	}
	@media screen and (min-width: $mq-tablet-landscape){//$mq-tablet-landscape 1023} $mq-desktop-md){//1200
		//border: 1px solid pink !important;
		width: 24.25%;
	}
}

.card-container--square {
	border-radius: 0;
	margin: 0 28px 60px 0px;
	.card {
		border-radius: 0;
	}
}

.card-container--flat {
	box-shadow: none;
	border: none;
	margin-bottom: 30px;
}

.card-container--small {
	width: 215px;
}

.card-container--small-medium {
	width: 250px;
}

.card-container--medium {
	width: 300px;
}

.card-container--large {
	@media screen and (min-width: $mq-tablet-portrait){
		width: 375px;
	}
}

.card-container--empty {
	margin-bottom: 0;
}

.card-container--small-at-medium {
	@media screen and (min-width: $mq-tablet-portrait) and (max-width: $mq-tablet-landscape){
		
		width: 215px;
		.card {
			box-shadow: $gray-900 0 2px 1px;
			max-width: 215px;
		}
		.card__image {
			width: 215px;
			height: 215px;
		}
	}
}

.card-extra-small-grid {
	display: grid !important;
	grid-template-columns: repeat(auto-fill, 149px); /* Small card (145px + margin + border) */
	grid-gap: 10px;
}

.card-container--medium-below-large {
	@media screen and (max-width: $mq-desktop){
		width: 300px;
		height: 500px;
		.card {
			box-shadow: $gray-500 0 2px 1px;
			max-width: 300px;
			height: 100%;
		}
		.card__image {
			width: 300px;
			height: 300px;
		}
	}
}

.card-container--deals-giveaway,
.card-container--deals-campaign{
	//border: 1px solid orange !important;
	width: 93%;
	// .card {
	// 	//box-shadow: $gray-500 0 2px 1px;
	// 	//max-width: 215px;
	// }
	.card__image {
		width: 100%;
		height: auto;
	}

	@media screen and (min-width: $mq-mobile-landscape){
		//border: 1px solid blue !important;
		width: calc(50% - 15px);
	}

	@media screen and (min-width: $mq-tablet-portrait){
		//border: 1px solid red !important;
		width: calc(33% - 15px);
	}

	// .max-3-up & {
	// 	//border: 1px solid green;
	// }

	.max-4-up & {
		//border: 1px solid red;
		@media screen and (min-width: $mq-tablet-landscape){
			//border: 1px solid gray !important;
			width: calc(25% - 15px);
		}
	}


}


$cardBorderRadius: 6px;

.card {
	//background-color: #fff;
	border: 0;
	border-bottom-right-radius: $cardBorderRadius;
	border-bottom-left-radius: $cardBorderRadius;
	position: relative;
	text-align: left;
	.campaign-summer &,
	.deals-giveaway &,
	.deals-campaign &{
		box-shadow: none;
	}

	.campaign-summer &,
	.info-only &{
		//border: 1px solid red !important;
		border-top-right-radius: $cardBorderRadius;
		border-top-left-radius: $cardBorderRadius;
		overflow: hidden;
	}
	.cssanimations & {
		opacity: 0;
		-webkit-animation:fadeIn ease-in 1;
		-webkit-animation-fill-mode:forwards;
		-webkit-animation-duration:1s;
		animation:fadeIn ease-in 1;
		animation-fill-mode:forwards;
		animation-duration:1s;
	}
	@media print {
		display: block !important;
		opacity: 1 !important;
		border-bottom: 1px solid #000;
		box-shadow: none;
		border-radius: 0;
		visibility: visible;
		page-break-inside: avoid;
	}
}

.card--small {
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	box-shadow: $gray-500 0 2px 1px;
	max-width: 215px;
}

.card--small-medium {
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	box-shadow: $gray-500 0 2px 1px;
	max-width: 250px;
}

.card--medium {
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	box-shadow: $gray-500 0 2px 1px;
	max-width: 300px;
}

.card--flat {
	box-shadow: none;
	border: none;
	//border-bottom: 2px solid #fff;
}

.card__title {
	display: block;
	color: $primary;
	font-size: .9em;
	font-weight: bold;
	margin-bottom: 10px;
	padding: 10px 10px 0 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 385px;
	white-space: nowrap;
	text-decoration: none;
	@media print {
		display: none;
	}
}

.card__title--extra-small {
	max-width: 125px;
}

.card__title--larger {
	font-size: 1em;
}

.card__title--long {
	white-space: normal;
	overflow: visible;
	max-width: none;
	//font-size: 1em;
	@include r-font-size(21,24);
	line-height: 1.1;
	margin-bottom: 0;
	//hold space for wrapping text to two lines
	//height: 44px;
	.campaign-summer &,
	.deals-giveaway &,
	.deals-campaign &{
		//height: auto;
		padding: 0;
	}
}

.card__title--free {
	white-space: normal;
	overflow: visible;
	max-width: none;
}

.card__title--overflow {
	overflow: hidden;
}

.card__input {
	.dialog-input {
		margin: 0px;
		input {
			width: 75%;
		}
		padding: 8px 5px 7px 5px;

		.dialog-input__action {
			padding: 0px;
		}
	}
}

.card__title--small {
	max-width: 215px;
}

.card__image {
	width: 100%;
	// width: 269px;
	// height: 269px;
	@media print {
		display: none;
	}
}

.card__image--extra-small {
	width: 125px;
	height: 115px;
	margin: 10px 10px 0 10px;
}

.card__image--small {
	width: 195px;
	height: 145px;
	margin: 10px 10px 0 10px;
}

.card__image--small-medium {
	width: 230px;
	height: 188px;
	margin: 10px 10px 0 10px;
}

.card__image--medium {
	width: 280px;
	height: 213px;
	margin: 10px 10px 0 10px;
}

.card__image--medium-expanded {
	width: 300px;
	height: 223px;
}

.card__image--small-expanded {
	width: 215px;
	height: 165px;
}

.card__image--large {
	@media screen and (min-width: $mq-tablet-portrait){
		width: 375px;
		height: 375px;
	}
}

.card__votes {
	text-align: center;
	height: 32px;
	width: 100%;
}

.card__details {
	padding: 0 10px 10px;
	//position: relative;
	box-sizing: border-box;
	.button {
		@media print {
			display: none;
		}
	}
	.deals-campaign &{
		//border:1px solid red;
		height: 100px;
		padding: 0 10px;
	}

	.campaign-summer &,
	.deals-giveaway &{
		//border:1px solid red;
		height: auto;
		padding: 0 10px;
	}

	.bg-green &{
		padding: 10px;
		a:link,a:visited,a:hover,a:active{
			color: white;
		}
	}

	.info-only &{
		padding: 1rem 10px 0;
	}
	
}

.card__details--short {
	padding-top: 0;
}

.card__details--container {
	position: relative;
}

.card__count {
	font-size: .8em;
	margin-bottom: 10px;
	margin-left: 10px;
	color: #5a5a5a;
}

.card__share {
	margin-bottom: 10px;
	float: right;
	margin-right: 10px;
}

.card__icon {
	border: none;
	display: inline-block;
	background: none;
	width: 19px;
	height: 17px;
	background-repeat: no-repeat;
	float: right;
	margin: 2px 10px;
}

.card__icon--notes {
	background-image: url('/Images/trip-planner/note-unselected.png');
	&.has-note {
		background-image: url('/Images/trip-planner/note-selected.png');
	}
	@media print {
		display: none;
	}
}

.card__icon--favorite {
	background-image: url('/Images/trip-planner/fav-unselected.png');
	&.favorited {
		background-image: url('/Images/trip-planner/fav-selected.png');
	}
	@media print {
		display: none;
	}
}

.card__location-pin {
	// display: block;
	// float: left;
	margin-right: 5px;
	// margin-bottom: 10px;
}

.card__location {
	color: $secondary;
	display: inline-block;
	font-size: .8em;
	@media print {
		display: none;
	}
	a {
		text-decoration: none;
	}
	&:link {
		text-decoration: none;
	}
}

.card__location--block {
	display: block;
	color: $tertiary;
}

.card__location--directory {
	font-size: 1rem;
	margin-bottom: 1rem;
	//min-height: 42px;
	a {
		//display: inline-block;
		//max-width: 90%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: $secondary;
	}

	// .campaign-summer &,
	// .deals-giveaway &,
	// .deals-campaign & {
	// 	min-height: 20px;
	// }
}

.card__city {
	// margin-left: 1rem;
	// &.statewide{
	// 	margin-left: 0;
	// }
	
	min-height: 42px;
	.deals-campaign &,
	.deals-campaign & {
		min-height: 20px;
	}
}

.card__actions {
	margin-top: 5px;
	@media print {
		width: 53px;
		float: left;
	}
	.campaign-summer &,
	.deals-giveaway &,
	.deals-campaign & {
		margin-top: 6px;
	}
}

.card--small-text {
	font-size: .8em;
}

.card__pin-number {
	position: relative;
	display: inline-block;
	background-color: #a51400;
	padding: 1px 7px;
	color: #fff;
	margin-right: 10px;
	font-size: .8em;
	&:after {
		content: '\00A0';
		position: absolute;
		height: 0;
		width: 0;
		border: 5px solid transparent;
		border-top-color: #a51400;
		bottom: -10px;
		right: 5px;
	}
	@media print {
		display: inline-block !important;
		background-color: transparent;
		color: #000;
		border: 1px solid #000;
		margin: 15px;
		&:after {
			content: '';
			border: none;
		}
	}
}

.card-detail {
	position: absolute;
	background-color: #fff;
	background-image: url('/images/trip-planner/card_bg.png');
	background-repeat: repeat;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	z-index: 1;
	@media print {
		display: block !important;
		position: static;
		background-image: none;
	}
}



.card-detail__map {
	width: 98%;
	height: 165px;
	position: relative;
	margin: 3px auto;
	border: 1px solid $gray-900;
	//overflow: hidden;
	@media print {
		display: none;
	}
}

.card-detail__title {
	color: $primary;
	display: block;
	font-weight: bold;
	padding: 0 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 385px;
	white-space: nowrap;
	text-decoration: none;
	@media print {
		font-size: .9em;
	}
}

.card-detail__map-link {
	display: block;
	font-size: .7em;
	text-decoration: underline;
	text-align: right;
	@media print {
		display: none;
	}
}

.card-detail__detail {
	padding: 0 10px 10px 10px;
	@media print {
		width: 80%;
		float: left;
	}
}

.card-detail__address {
	display: block;
	overflow: hidden; 
	font-size: .8em;
	font-weight: bold;
	padding: 0 10px;
	a {
		text-decoration: none;
	}
	@media print {
		font-size: .7em;
	}
}

.card-detail__share {
	display: block;
	padding: 5px 10px;
	@media print {
		display: none;
	}
}

.card-detail__close {
	display: block;
	border: none;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 35px;
	font-size: .9em;
	background-color: #666;
	color: #fff;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	@media print {
		display: none;
	}
}

.card-detail__x {
	border: none;
	position: absolute;
	right: -12px;
	top: -12px;
	background-color: #333;
	color: #fff;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	border: 3px solid #fff;
	z-index: 1;
	font-weight: bold;
	font-size: .8em;
	@media print {
		display: none;
	}
}

.card-note {
	position: absolute;
	width: 269px;
	height: 269px;
	top: 0;
	left: 0;
	background-color: #efefef;
	border: 10px solid #fff;
	box-sizing: border-box;
	padding: 10px;
	@media print {
		display: block !important;
		background-color: transparent;
		position: static;
		width: auto;
		height: auto;
		padding: 0;
		border: none;
	}
}

.card-note__input {
	display: block;
	background-color: #eee;
	border: none;
	width: 225px;
	height: 160px;
	margin-bottom: 10px;
	resize: none;
	outline: 0;
	@media print {
		width: 100%;
		height: auto;
		background-color: transparent;
		margin: 0 0 0 70px;
		overflow: visible;
		font-size: .7em;
	}
}

.card__image-container {
	position: relative;
}

.card__image-container--decoration {
	// &:after {
	// 	content: '\00A0';
	// 	background-image: url('/images/public/jagged_line.png');
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// 	height: 14px;
	// 	width: 100%;
	// }

	.info-only &{
		//border:1px solid green;
		&:after {
			content: '';
			background-image: none;
			position: relative;
			bottom: 0;
			left: 0;
			height: 0;
			width: 0;
		}
	}
}

.card__price {
	display: inline-block;
	position: absolute;
	bottom: .5rem;
	right: .5rem;
	background-color: $senary;
	color: $primary;
	padding: .5rem 1rem;
	font-weight: bold;
	max-width: calc(100% - 1rem);

	.info-only &{
		display: none;
	}
}

.card-options {
	background-color: rgba(0,0,0,0.5);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-decoration: none;
    text-align: center;
	a:link, a:visited {
		color: white;
	}
	.cssanimations & {
		opacity: 0;
		-webkit-animation:fadeIn ease-in 1;
		-webkit-animation-fill-mode:forwards;
		-webkit-animation-duration:1s;
		animation:fadeIn ease-in 1;
		animation-fill-mode:forwards;
		animation-duration:.3s;
	}
	.cards-container--directory & {
		border-radius: 0;
	}
}

.card-options--square {
	border-radius: 0;
}

.card-options__option {
	padding: 0px;
	margin: 35px 0 10px 0;
	display: block;
    text-decoration: none;
}

.card-vote-button {
	position: absolute;
	width: 195px;
	top: 0;
	&:active {
		position: absolute;
	}
}

.card-content-container {
	padding: 2em 15px;
	text-align: center;
	@media screen and (min-width: $mq-tablet-portrait	) {
		text-align: left; 
		padding: 2em 0;
	}
}

.cards-side-container--flex-wrapper {
	max-width: 1175px;
	margin: auto;
	padding: 0;
	text-align: center; //so it looks okay in unsupporting browsers
	@media screen and (min-width: $mq-tablet-portrait){
		display: flex;
		justify-content: space-around;
		flex-flow: row wrap;
	}
	
}

.card-side-container {
	display: inline-block;
	vertical-align: top;
	//background-color: #fff;
	margin: 0 5px 30px 5px;
	position: relative;
	text-align: left;
	@media print {
		margin-bottom: 0;
		width: auto;
	}
}

.card-side-container--medium {
	width: 450px;
	height: 260px;
}

.card-side-container--small-at-small {
	@media screen and (max-width: $mq-tablet-landscape){
		width: 320px;
		height: auto;
		margin-right: 0;
		margin-left: 0;
		.card-side {
			max-width: 320px;
		}
		.card-side__image {
			width: 170px;
			height: 150px;
		}
		.card-side__details {
			width: 145px;
		}
		.card-side__location {
			width: 145px;
		}
	}
}


.card-side {
	background-color: #fff;
	position: relative;
	text-align: left;
	height: 100%;
	.cssanimations & {
		opacity: 0;
		-webkit-animation:fadeIn ease-in 1;
		-webkit-animation-fill-mode:forwards;
		-webkit-animation-duration:1s;
		animation:fadeIn ease-in 1;
		animation-fill-mode:forwards;
		animation-duration:1s;
	}
	@media print {
		display: block !important;
		opacity: 1 !important;
		border-bottom: 1px solid #000;
		box-shadow: none;
		border-radius: 0;
		visibility: visible;
		page-break-inside: avoid;
	}
}

.card-side__image {
	display: inline-block;
	@media print {
		display: none;
	}
}

.card-side__image--medium {
	width: 296px;
	height: auto;
}

.card-side__details {
	display: inline-block;
	vertical-align: middle;
}

.card-side__details--medium {
	width: 149px;
}

.card-side__title {
	display: block;
	color: $primary;
	//font-size: .9em;
	font-weight: bold;
	margin-bottom: 10px;
	padding: 10px 10px 0 10px;
	text-decoration: none;
	@media print {
		display: none;
	}
}

.card-side__title--overflow {
	overflow: hidden;
}

.card-side__title--medium {
	max-width: 385px;
}

.card-side__category {
	box-sizing: border-box;
	font-size: .9em;
	display: block;
	font-weight: bold;
	width: 145px;
	padding: 10px;
	padding-top: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.card-side__location {
	box-sizing: border-box;
	display: block;
	color: $septenary;//#666;
	font-size: .8em;
	position: absolute;
	right: 0;
	bottom: 5px;
	width: 145px;
	padding: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.card-side__location--medium {
	width: 149px;
}

/* If more flags are added, will need to account for positioning here */

.card__flag {
	position: absolute;
	top: 10px;
	right: 25px;

	&--travel-green{
		background-color: #67a31b; 
		width: 26px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.card__flag + .card__flag {
	right: 64px;
}

.card__date {
	font-size: .9em;
}

.card__summary {
	font-size: .9em;
}

//special card for the map pane

.flip-card {
	display: block;
	text-align: center;
	@media screen and (min-width:$mq-tablet-portrait){
		display: flex;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: left;
	}
	&.is-selected {
		background-color: #e6e6e6;
	}
}

.flip-card__image-container {
	position: relative;
	display: inline-block;
}

.flip-card__image { 
	//card__image--medium
	width: 280px;
	height: 213px;
	margin: 10px 0;
	//card__image--small
	@media screen and (min-width:$mq-tablet-portrait){
		width: 195px;
		height: 145px;
	}
	@media print {
		display: none;
	}
}

.flip-card__details {
	text-align: left;
	display: inline-block;
	width: 280px;
	.card__title {
		padding-left: 0;
		padding-top: 0;
	}
	.card-detail__address {
		padding-left: 0;
	}
	@media screen and (min-width:$mq-tablet-portrait){
		padding-left: 20px;
		width: auto;
	}
}