

//$dialogDark: red;
$dialogDarker: purple;
// $dialogLight: $gray-100;
$dialogLighter: $gray-200;//not selected
//$dialogMedium: blue;
$dialogText: $gray-900;
$dialogSelected: $secondary;//selected


.dialog-bubble {
	width: 100%;
	max-width: 350px;
	position: fixed;
	//background-color: $dialogDarker;
	background-color: $white;
	z-index: 20;
    top: 0;
    left: 0;
	font-size: 1rem;
	border-radius: .5rem;
	border: 1px solid $gray-300;
	@extend .shadow;
}
.dialog-bubble__arrow{
    position: absolute;
    &.-down{
		&::after{
			content: '';
			position: relative;
			display: block;
			width: 10px;
			height: 10px;
			border-top: solid 15px $secondary;
			border-left: solid 15px transparent;
			border-right: solid 15px transparent;
		}
        bottom: -15px;
    }
    &.-up{
        top: -15px;
		&::after{
			content: '';
			position: relative;
			display: block;
			width: 10px;
			height: 10px;
			border-top: solid 15px $secondary;
			border-left: solid 15px transparent;
			border-right: solid 15px transparent;
		}
    }
}

.dialog-bubble__header {
	//background-color: $dialogDark;
	//height: 55px;
	//line-height: 55px;
	//padding: 0 20px;
	margin: .5rem 5px 0 5px;
	//font-size: .8em;
	//margin: .5rem
}

.dialog-bubble__heading {
	color: $primary;//$white;
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 0;
	text-align: center;
}

.dialog-bubble__footer {
	//background-color: $dialogDark;
	//padding: 0 1rem;
	//height: 40px;
	//line-height: 40px;
	text-align: right;
	margin: 0 .5rem .5rem;
}


.dialog-bubble__choices {
	height: 142px;
	overflow: auto;
	background-color: $light;
	margin: 0 .5rem;
	padding: .5rem;
    @include respond(md-1175){
        height: 185px;
    }
}

.dialog-bubble__button--delete {
	float: left;
	width: 50%;
	text-align: left;
}

.dialog-choice {
	position: relative;
	background-color: $gray-300;
	color: $dialogText;
	width: auto;
	height: auto;
	display: block;
	padding: .5rem 2rem .5rem 1rem;
	//margin: 1px 0;
	//line-height: 35px;
	//font-size: .8em;
	&:after {
		content: '\00A0';
		width: 14px;
		height: 15px;
		background-image: url(/Images/trip-planner/check-unchecked.png);
		position: absolute;
		top: 50%;
		margin-top: -7px;
		right: .75rem;
	}
}
.dialog-choice--static {
	&:after {
		content: '';
		background: none;
	}
}
.dialog-choice__checkbox:checked ~ .dialog-choice {
	background-color: $dialogSelected;
	color: $white;
	&:after {
		background-image: url(/Images/trip-planner/check-checked.png);
	}
}
.dialog-choice__checkbox:focus ~ .dialog-choice {
	border: 1px dotted #000;
}
.dialog-choice__checkbox {
	display: none;
}

.dialog-input {
	//background-color: $dialogMedium;
	margin: 0 5px;
	padding: 5px;
}

.dialog-input__label {
	position: relative;
	color: $dialogText;
	//height: 35px;
	display: block;
	padding: .5rem 2rem .5rem 1rem;
	//line-height: 35px;
	font-size: .8em;
	&:after {
		content: '\00A0';
		width: 16px;
		height: 15px;
		background-image: url(/Images/trip-planner/button-expand.png);
		position: absolute;
		top: 50%;
		margin-top: -7px;
		right: .75rem;
	}
}

.dialog-input__field {
	width: 74%;
	height: 35px;
	padding: 0 5px;
	background-color: $dialogLighter;
	border: none;
	margin-right: 5px;
	font-size: 16px;
}
::-webkit-input-placeholder {
	font-style: italic;
}

::-moz-placeholder {
	font-style: italic;
}
:-ms-input-placeholder {
	font-style: italic;
}

.dialog-input__action {
	border: none;
	background: none;
    padding: 1px 6px;
}

.dialog__close {
	border: none;
    padding: 0;
	position: absolute;
    right: 15px;
	top: 25px;
    @media only screen and (min-width: $mq-mobile-landscape) {
        right: -12px;
	    top: -12px;
    }
	background-color: $gray-700;
	color: $white;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	border: 3px solid $white;
	z-index: 1;
	font-weight: bold;
	font-size: 1em;
}










// .dialog-bubble {
// 	width: 100%;
// 	max-width: 350px;
// 	position: fixed;
// 	background-color: $dialogDarker;
// 	z-index: 20;
//     top: 0;
//     left: 0;
// 	//@include rem(1rem);
// 	font-size: 1rem;
// }
// .dialog-bubble__arrow{
//     position: absolute;
//     &.-down{
//         //@include arrow-down(15px, $dialogDarker);
// 		&::after{
// 			content: '';
// 			position: relative;
// 			display: block;
// 			width: 10px;
// 			height: 10px;
// 			// background-color: red;
// 			border-top: solid 15px $red;
// 			border-left: solid 15px transparent;
// 			border-right: solid 15px transparent;
// 		}
//         bottom: -15px;
//     }
//     &.-up{
//         //@include arrow-up(15px, $dialogDarker);
//         top: -15px;
// 		&::after{
// 			content: '';
// 			position: relative;
// 			display: block;
// 			width: 10px;
// 			height: 10px;
// 			//background-color: green;
// 			border-top: solid 15px $green;
// 			border-left: solid 15px transparent;
// 			border-right: solid 15px transparent;
// 		}
//     }
// }

// .dialog-bubble__header {
// 	background-color: $dialogDark;
// 	height: 55px;
// 	line-height: 55px;
// 	padding: 0 20px;
// 	margin: 5px 5px 0 5px;
// 	font-size: .8em;
// }

// .dialog-bubble__heading {
// 	color: $white;
// 	font-size: 1.2em;
// 	font-weight: bold;
// }

// .dialog-bubble__footer {
// 	background-color: $dialogDark;
// 	padding: 0 20px;
// 	height: 40px;
// 	line-height: 40px;
// 	text-align: right;
// 	margin: 0 5px 5px 5px;
// }


// .dialog-bubble__choices {
// 	height: 142px;
// 	overflow: auto;
// 	background-color: $dialogLight;
// 	margin: 0 5px;
// 	padding-top: 5px;
// 	padding-right: 5px;
//     @include respond(md-1175){
//         height: 185px;
//     }
// }

// .dialog-bubble__button--delete {
// 	float: left;
// 	width: 50%;
// 	text-align: left;
// }

// .dialog-choice {
// 	position: relative;
// 	background-color: $dialogLighter;
// 	color: $dialogText;
// 	height: 35px;
// 	display: block;
// 	padding: 0 20px;
// 	margin: 1px 0;
// 	line-height: 35px;
// 	font-size: .8em;
// 	&:after {
// 		content: '\00A0';
// 		width: 14px;
// 		height: 15px;
// 		background-image: url(/Images/trip-planner/check-unchecked.png);
// 		position: absolute;
// 		top: 50%;
// 		margin-top: -7px;
// 		right: 20px;
// 	}
// }
// .dialog-choice--static {
// 	&:after {
// 		content: '';
// 		background: none;
// 	}
// }
// .dialog-choice__checkbox:checked ~ .dialog-choice {
// 	background-color: $dialogSelected;
// 	&:after {
// 		background-image: url(/Images/trip-planner/check-checked.png);
// 	}
// }
// .dialog-choice__checkbox:focus ~ .dialog-choice {
// 	border: 1px dotted #000;
// }
// .dialog-choice__checkbox {
// 	display: none;
// }

// .dialog-input {
// 	background-color: $dialogMedium;
// 	margin: 0 5px;
// 	padding: 5px;
// }

// .dialog-input__label {
// 	position: relative;
// 	color: $dialogText;
// 	height: 35px;
// 	display: block;
// 	padding: 0 15px;
// 	line-height: 35px;
// 	font-size: .8em;
// 	&:after {
// 		content: '\00A0';
// 		width: 16px;
// 		height: 15px;
// 		background-image: url(/Images/trip-planner/button-expand.png);
// 		position: absolute;
// 		top: 50%;
// 		margin-top: -7px;
// 		right: 15px;
// 	}
// }

// .dialog-input__field {
// 	width: 74%;
// 	height: 35px;
// 	padding: 0 5px;
// 	background-color: $dialogLighter;
// 	border: none;
// 	margin-right: 5px;
// 	font-size: 16px;
// }
// ::-webkit-input-placeholder {
// 	font-style: italic;
// }

// ::-moz-placeholder {
// 	font-style: italic;
// }
// :-ms-input-placeholder {
// 	font-style: italic;
// }

// .dialog-input__action {
// 	border: none;
// 	background: none;
//     padding: 1px 6px;
// }

// .dialog__close {
// 	border: none;
//     padding: 0;
// 	position: absolute;
//     right: 15px;
// 	top: 25px;
//     @media only screen and (min-width: $mq-mobile-landscape) {
//         right: -12px;
// 	    top: -12px;
//     }
// 	background-color: $gray-700;
// 	color: $white;
// 	width: 30px;
// 	height: 30px;
// 	border-radius: 30px;
// 	border: 3px solid $white;
// 	z-index: 1;
// 	font-weight: bold;
// 	font-size: 1em;
// }