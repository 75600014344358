.article {
	//padding: 1em 0;

	// &::before{
	// 	z-index: 0;
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	width: 100%;
	// 	height: 200px;
	// 	content: " ";
	// 	display: block;
	// 	background-color: $secondary;
	// }

	&.before-senary{
		&::before{
			background-color: $senary;
		}
	}
	&.before-quaternary{
		&::before{
			background-color: $quaternary;
		}
	}
	&.before-tertiary{
		&::before{
			background-color: $tertiary;
		}
	}
}

.article-hero {
	position: relative;
	margin: 10px 0;

	&::before{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 11px;
		height: 100%;
		content: " ";
		display: block;
		background: linear-gradient($primary 85%, $senary 15%);
		z-index: 2;
	}
}

.article-hero__image {
	width: 100%;
	height: auto;
	display: block;
}

.article-title {
	//font-size: 2.3em;
	// text-transform: none;
	// font-weight: bold;
	// margin: 10px 20px;
	// @media screen and (min-width: $mq-tablet-portrait){
	// 	font-size: 2.8em;
	// 	margin: 10px 0;
	// }
}

//have to get to a higher specificity than some legacy styles
.article__intro,
.article__content {
	.truncatedStory {
		@extend .clearfix;
		padding: 0;
		margin: 0;

		/* Dealing with various WYSIWYG content */
		//vv moved below vvv
		// p {
		// 	@include r-font-size(18,24);
		// 	font-weight: 500;
		// }
		// h2 {
		// 	@include r-font-size(34,42);
		// 	margin-top: 2rem;
		// }
		a:link, a:visited {
			font-weight: bold;
		}
		blockquote {
			background-color: $gray-300;//$offneutral;
			line-height: 1.8;
			color: $lightPrimary;
			font-style: italic;
			margin: 1em 0;
			padding: 15px;
			@media screen and (min-width: $mq-tablet-portrait){
				padding: 30px 25%;
				font-size: 1.3em;
				margin: 2em 0;
			}
			p {
				margin: 0;
			}
		}
		img {
			max-width: 100%;
		}
		.floatRight {
			float: none;
			margin: 15px;
			@media screen and (min-width: $mq-tablet-portrait){
				float: right;
				max-width: 50%;
				margin: 1em 0 1em 1em;
			}
			img {
				max-width: 100%;
				display: block;
				margin: auto;
			}
		}
		.floatLeft {
			float: none;
			margin: 15px;
			@media screen and (min-width: $mq-tablet-portrait){
				float: left;
				max-width: 50%;
				margin: 1em 1em 1em 0;
			}
			img {
				max-width: 100%;
				display: block;
				margin: auto;
			}
		}
		/* For some legacy content with wordpress stuff in it */
		.wp-caption{
			display: flex;
			width: auto!important;
			border-radius: 8px;
			overflow: hidden;
			background-color: $light!important;
			padding: 1.5rem;
			margin: 1rem 0 3rem;

			.wp-caption-text{
				text-align: left!important;
			}
		}
		.wp-caption > figure {
			text-align: left;
			margin: 0 2rem 0 0;
			border-radius: 6px;
			overflow: hidden;
			p{
				margin: 0;
			}
		}
		.wp-caption img {
			max-width: 150px;
			display: inline-block;
		}

		.wp-caption.alignright{
			//margin: 0 0 1em 1em;padding: 1em;
			font-size: 11px;line-height: 1.2;
		}
		.wp-caption.aligncenter{
			max-width:100%;
		}
		.wp-caption.aligncenter a img{
			max-width: 150px;
			height: auto;
		}
		.blogger-summary{
			margin: 0 0 1em;padding: 10px;background-color: #D9D7D4;
		}
		.entry-content .gallery img {
			position: relative;top: 2px;display: block;border: 2px solid white;box-shadow: 1px 1px 5px rgba(0,0,0,.35);
		}
		@media (max-width: 515px) {
			.wp-caption.alignright{width:100% !important;float: none;margin: 1em 0 1em;padding: 1em 0 1em;}
			.wp-caption.alignright a{display: block;text-align: center;}
			.wp-caption.alignright a img{max-width: 90%;height: auto;}
			.wp-caption.alignright p{margin: .9em;}
			#gallery-1 .gallery-item {width: 100% !important;margin: 0;float: none!important;}
			.entry-content .gallery img {display: inline-block !important;}
		}

		figure {
			figcaption {
				background: $neutral;
				display: block;
				position: static;
				font-style: italic;
				font-size: .9em;
				padding: .5em;
				@media screen and (min-width: $mq-tablet-portrait){
					padding: 20px 3%;
				}
				p {
					width: auto;
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}


.article__intro{
	.truncatedStory {
		p {
			@include r-font-size(20,24);
			font-weight: 500;
		}
		h2 {
			@include r-font-size(34,42);
			margin-top: 2rem;
		}
	}
}
.article__content{
	.truncatedStory {
		p {
			@include r-font-size(16, 20);
			font-weight: 300;
		}
		h2 {
			@include r-font-size(34,42);
			margin-top: 2rem;
		}
		iframe {
			width: 100%;
		}		
	}
}



.article__quicklinks{
	border-top: 1px dashed $gray-500;
	border-bottom: 1px dashed $gray-500;
	padding: 2rem 0;

	ul{
		list-style: none;
		//border: 1px solid red;
		padding: 0;
		font-weight: 700;
		@include r-font-size(20,24);

		li{
			//border: 1px solid blue;
			@include r-font-size(16,18);
			line-height: 1.2;
			margin: 0 0 .5rem 0;
			padding-left: 1rem;

			&::before {
				content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
				color: $septenary; /* Change the color */
				font-weight: bold; /* If you want it to be bold */
				display: inline-block; /* Needed to add space between the bullet and the text */
				width: .8em; /* Also needed for space (tweak if needed) */
				margin-left: -.8em; /* Also needed for space (tweak if needed) */
				font-family: $font-family-system;
			}
		}
	}
}





.article__byline {
	//font-size: .8em;

}

.article__byline-link {
	&:link, &:visited {
		text-decoration: none;
	}
}

.article-aside {
	background-color: $lightBlueGray;
	text-align: center;
	padding: 20px;
	margin: 1em 0;
	@media screen and (min-width: $mq-tablet-portrait){
		padding: 30px 15%;
		margin: 2em 0;
	}

	a{
		@extend .btn;
		@extend .btn-primary;
	}

}



.article-aside__title {
	margin: 0;
}


.article-author {
	//color: $darkNeutral;
	margin: 1em 15px;
	font-size: .9em;
	@media screen and (min-width: $mq-tablet-portrait){
		margin: 2em 0 0;
		font-size: 1em;
	}
	figure {
		max-width: 100px;
		margin-bottom: 1em;
		@media screen and (min-width: $mq-tablet-portrait){
			float: left;
			margin-right: 1em;
		}
		img {
			max-width: 100%;
		}
	}
	.truncatedStory {
		padding: 0;
	}
	.truncatedStory p {
		margin: 5px 0;
		@media screen and (min-width: $mq-tablet-portrait){
			margin: 0;
		}
	}
}

.article-intro {
	h1 {
		font-size: 3em;
		text-transform: none;
		font-weight: bold;
		@media screen and (min-width: $mq-tablet-portrait){
			font-size: 3.5em;
			margin-bottom: 0;
		}
	}
	h2 {
		margin: .5em 0;
		font-size: 1.2em;
		@media screen and (min-width: $mq-tablet-portrait){
			font-size: 1.5em;
		}
	}
	text-align: center;
	font-size: .9em;
	padding: 1em 0;
}

.fb-comments {
	margin: 1em auto;
}

.article-landing-page{
	.related-stories{
		display: none;
	}
}