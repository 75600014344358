.pagingContainer{
    //border: 1px solid red;
    display: flex;
    list-style-type: none;
    justify-content: center;

    li{
        //border: 1px solid blue;
        margin: 0 .1rem;
        
        a{
            //border: 1px solid orange;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: white;
            color: $primary;
            text-decoration: none;
            @include r-font-size(18,21);
            font-weight: 800;

            &:hover{
                background-color: $tertiaryLighter;
            }

            @include media-breakpoint-up(md) {
                width: 55px;
                height: 55px;
                @include r-font-size(21,24);
            }
        }

        &.carousel-active-tab{
            a{
                color: white;
                background-color: $secondary;
            }
        }

        @include media-breakpoint-up(md) {
            margin: 0 .2rem;
        }
    }
}