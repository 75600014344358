
/* Base Superbox Styles */
#superbox-overlay{position:fixed;top:0;left:0;z-index:9998;width:100%;height:100%;}
#superbox-wrapper{position:fixed;z-index:9999;top:0;display:table;width:100%;height:100%;}
#superbox-container{position:relative;display:table-cell;width:100%;height:100%;margin:0;padding:0;vertical-align:middle;}
#superbox{margin:0 auto;padding:0;}
#superbox-container .loading{margin:0;text-align:center;}

/* IE7 */
*:first-child+html #superbox-container{position:absolute;top:50%;display:block;height:auto;}
*:first-child+html #superbox{position:relative;top:-50%;display:block;}
/* IE6 - Thanks to Thickbox for IE expressions */
* html #superbox-container{position:absolute;top:50%;display:block;height:auto;}
* html #superbox{position:relative;top:-50%;display:block;}
* html #superbox-overlay{position:absolute;height:expression(document.body.scrollHeight > document.body.offsetHeight ? document.body.scrollHeight + 'px' : document.body.offsetHeight + 'px');}
* html #superbox-wrapper{position:absolute;margin-top:expression(0 - parseInt(this.offsetHeight / 2) + (document.documentElement && document.documentElement.scrollTop || document.body.scrollTop) + 'px');}

/* Default Theme */
#superbox-overlay{background:#000;}
#superbox-container .loading{text-align:center;font-size:40px;color:#fff;}
#superbox{padding:10px;background:#fff;}
#superbox-innerbox{padding:10px 0;}
#superbox.image{text-align:center;}
#superbox .close,
#superbox .nextprev{overflow:hidden;margin:0; cursor:pointer;}

#superbox {
  max-width:600px;
  p.close {
    background:$light;
    color:#666;
    //font-family: "Helvetica", "sans-serif";
    font-weight: bold;
    padding:5px;
    text-align:right;
    text-transform:uppercase;
    box-sizing: border-box;
  }
  #superbox-innerbox {
    padding:21px 0; 
    height: auto!important;
    .modal-title { 
      margin-bottom:-4px;
      text-align:center;
      p {
        padding:0;
        margin:0 0 7px 0;
      }
    }
    .modal-social__bar {}
    .modal-social__figure { 
      width:7%;
      display: inline-block;
      img {
        max-width:100%;
        height:auto;
      }
    }
    .modal-col1 {
      margin:0 2%;
      @media screen and (min-width:$mq-tablet-portrait){
        float:left;
        width:46%;
      }
    }
    .modal-col2 {
      margin:0 2%;
      @media screen and (min-width:$mq-tablet-portrait){
        float:right;
        width:46%;
      }
    }
    .modal__button {
      clear:both;
      float:right;
      margin:0 2%;
      width:20%;
	  min-width: 90px;
    }
    div.validation-summary-errors { 
      ul {
        list-style-type:none; 
        color:$red;
        margin:0;
        padding:0;
        text-align:center;
        width:100%;
      }
      li {}
    }
    div {
      margin-bottom:8px;
    }
    a, a:visited, a:link, a:hover {color:$primary; text-decoration:none; text-transform:uppercase; font-family:"Helvetica", "sans-serif";
    font-weight: bold;}
    label {
      color:$secondary;
      display:block;
      font-size:16px;
      font-family: "Helvetica", "sans-serif";
      font-weight: bold;
    }
    input {
      width:100%;
     box-sizing: border-box;
    }
    textarea {
      width:100%;
      height:7em;
      box-sizing: border-box;
      @media screen and (min-width:$mq-tablet-portrait){
        height:14em;
      }
    }
    input[type="button"] {
      // background:$primary;
      // border: double 4px #fff;
      // border-radius: 0;
      // cursor:pointer;
      // color:#fff;
      // font-family: "Helvetica", "sans-serif";
      // font-weight: bold;
      // text-align:center;
      // text-transform:uppercase;
      // box-sizing: border-box;
      @extend .btn;
      @extend .btn-primary;
    }


  }

  .clearboth{
    clear: both;
  }
  
}
#superbox {
    width:90% !important;
    @media screen and (min-width:$mq-tablet-portrait){
        width:50% !important;
    }
}