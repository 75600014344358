

.card-hover{
    .card-img-container{
        overflow: hidden;
        img{
            text-align:center;
            transition: all 0.15s;
            transition-timing-function: ease-out;
        }
    }
    &:hover {
        .card-img-container{
            img{
                transform: scale(1.1);
            }
        }
    }
}

.card{
    overflow: hidden;
}