
.confirm-bubble {
	box-sizing: border-box;
	background-color: $white;
	border-radius: .5rem;
	@extend .shadow;
	z-index: 3;
	position: absolute;
	width: 300px;
	height: auto;
	top: -105px;
	left: 50%;
	text-align: center;
	padding: 7px;

	&:after {
	    content:'';
	    position: absolute;
	    top: 100%;
	    left: 0;
	    right: 0;
	    margin: 0 auto;
	    width: 0;
	    height: 0;
	    border-top: solid 15px $white;
	    border-left: solid 15px transparent;
	    border-right: solid 15px transparent;
	}
	.cssanimations & {
		opacity: 0;
		-webkit-animation:fadeIn ease-in 1;
		-webkit-animation-fill-mode:forwards;
		-webkit-animation-duration:.5s;
		animation:fadeIn ease-in 1;
		animation-fill-mode:forwards;
		animation-duration:.5s;
	}
}



.confirm-bubble--large {
	top: -205px;
	height: auto;
	width: 350px;
	left: 50%;
}







// .confirm-bubble {
// 	box-sizing: border-box;
// 	background-color: #fff;
// 	border-radius: 10px;
// 	box-shadow:  $dialogLight 0 4px 2px;
// 	z-index: 3;
// 	position: absolute;
// 	width: 200px;
// 	height: 90px;
// 	top: -105px;
// 	left: 50%;
// 	text-align: center;
// 	padding: 7px;
// 	&:after {
// 	    content:'';
// 	    position: absolute;
// 	    top: 100%;
// 	    left: 0;
// 	    right: 0;
// 	    margin: 0 auto;
// 	    width: 0;
// 	    height: 0;
// 	    border-top: solid 15px #fff;
// 	    border-left: solid 15px transparent;
// 	    border-right: solid 15px transparent;
// 	}
// 	.cssanimations & {
// 		opacity: 0;
// 		-webkit-animation:fadeIn ease-in 1;
// 		-webkit-animation-fill-mode:forwards;
// 		-webkit-animation-duration:.5s;
// 		animation:fadeIn ease-in 1;
// 		animation-fill-mode:forwards;
// 		animation-duration:.5s;
// 	}
// }

// .confirm-bubble--large {
// 	top: -115px;
// 	height: 90px;
// 	width: 320px;
// 	left: 50%;
// }

// .confirm-bubble--small {
// 	top: -115px;
// 	height: 90px;
// 	width: 180px;
// 	left: 50%;
// }

// .confirm-bubble__text {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	color: $primary;
// 	font-weight: bold;
// 	margin-bottom: 10px;
// }

// .confirm-bubble__button {
// 	margin: 0 5px 10px 5px;
// 	text-align: center;
// 	background-color: $primary;
// 	margin-bottom: 10px;
// }