.attributes-container {
	box-sizing: border-box;
	//margin: 25px 0;
	position: relative;
	@media screen and (min-width:$mq-tablet-portrait){
		//width: 54%;
		//float: left;
		//padding: 0 20px;
		//margin-top: 0;
	}
}

.attribute {
	list-style-type: none;
	margin: 0;
	padding: 0;
	background-color: $lightBlueGray;
}

.attribute__item {
	text-align: left;
	padding: 10px;
}

.attribute__title {
	box-sizing: border-box;
	display: block;
	//float: left;
	//width: 55%;
	color: $primary;
	font-weight: 500;
	//font-size: .9em;
	//margin-top: 5px;
	//padding-right: 5px;
}

.attribute__toggle {
	background: none;
	border: none;
	display: block;
	width: 54px;
    height: 32px;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
	font-size: .55em;
	text-transform: uppercase;
	color: $primary;
	background-color: $gray-300;
	&:before {
       //content: url(/Images/listings/expand_cta.svg);
       display: block;
       font-size: 0;
       line-height: 0;
    }
}

.attribute__toggle--collapse {
	&:before {
       //content: url(/Images/listings/collapse_cta.svg);
    }
}

.attribute-list {
	width: 45%;
	float: left;
	margin: 0;
	padding: 0;
}

.attribute-list__item {
	display: inline-block;
	vertical-align: middle;
	margin: 0 15px 5px 0;
	color: $darkNeutral;
	font-size: .9rem;
}

.attribute-list__image {
	display: inline-block;
	margin-right: 10px;
}

.attribute__item:nth-child(even) {
	background-color: rgba(0,0,0,.05);
}

.attribute-wrapper {
	max-width: 887px;
	margin: 0 auto;
}