﻿/**
    Prefooter section above main footer
*/


.stickyfoot{
    position: fixed;
    z-index: 999999;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    background-color: $sf-color-bkgnd;

    //opacity: .8;

    &:after{
        @extend .clearfix;
    }
}



.stickyfoot__info{
    //border: 1px solid blue;
    position: relative;
    display: block;
    z-index: 1;
    margin: 10px auto 13px;
    width: 75%;
    height: auto;
    text-align: center;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}




.stickyfoot__info__text{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;

    @include r-font-size(18, 24);
    line-height: 1.1;
    font-weight: 700;
    color: $primary;
    text-align: center;

    b{
        font-weight: 900;
    }

    @media only screen and (min-width: $mq-mobile-landscape) {
        display: inline-block;
        //font-size: 16px;
    }

    /*@media only screen and (min-width: $mq-tablet-portrait) {
        font-size: 24px;
    }*/
}

.stickyfoot__info__link{
    position: relative;
    display: block;
    margin: 7px auto 0;
    padding: 0;
    width: auto;

    @media only screen and (min-width: $mq-mobile-landscape) {
        display: inline-block;
        margin: 0 0 0 20px;
    }

    a{
        @extend .btn;
        @extend .btn-secondary;
        color: white;
        @extend .text-nowrap;
    }

    // a:link, a:visited,
    // a:hover, a:active{
    //     display: block;
    //     margin: 0 auto;
    //     padding: 5px 10px;
    //     width: 80px;
    //     background-color: $sf-color-btn-bkgnd;
    //     -webkit-border-radius: 2px;
    //     -moz-border-radius: 2px;
    //     border-radius: 2px;

    //     font-size: 11px;
    //     line-height: 1.35em;
    //     font-weight: 100;
    //     color: white;
    //     text-align: center;
    //     text-decoration: none;

    //     @media only screen and (min-width: $mq-mobile-landscape) {
    //         margin: 0;
    //         font-size: 12px;
    //         padding: 5px 10px;
    //         width: 100px;
    //     }
    // }

}



.stickyfoot__close {
    position: absolute;
    //padding-top: 8px;
    z-index: 8;
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    right: 1rem;
    width: 50px;
    height: 50px;
    background-color: $lightBlueGray;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    // span {
    //     //font-weight: bold;
    //     font-size: 1.5em;
    //     color: white;
    //     text-align: center;
    //     text-transform: lowercase;
    // }
}